import { ActionTypes } from "../constants";
import { checkKeyInObject } from "../../utils/utils";
import { AdminInstructorMiddleware } from "../middlewares";

export default class AdminInstructorActions {
  static getAdminInstructorData = () => {
    return async dispatch => {
      dispatch({ type: ActionTypes.GET_ADMIN_INSTRUCTOR_DATA });
      try {
        const result = await AdminInstructorMiddleware.getAdminInstructorData();
        if (
          checkKeyInObject(result, "status") === 200 &&
          checkKeyInObject(result, "data.result", "bool", false)
        ) {
          dispatch({
            type: ActionTypes.GET_ADMIN_INSTRUCTOR_DATA_SUCCESS,
            payload: result.data.result
          });
        } else {
          throw result;
        }
      } catch (error) {
        dispatch({
          type: ActionTypes.GET_ADMIN_INSTRUCTOR_DATA_FAILURE
        });
        //console.log({ error })
      }
    };
  };

  static getAllInstructors = () => {
    return async dispatch => {
      dispatch({ type: ActionTypes.GET_ALL_INSTRUCTORS });
      try {
        const result = await AdminInstructorMiddleware.getAllInstructors();
        if (checkKeyInObject(result, "data.result", "bool", false)) {
          dispatch({
            type: ActionTypes.GET_ALL_INSTRUCTORS_SUCCESS,
            payload: result.data.result
          });
        } else {
          throw result;
        }
      } catch (error) {
        dispatch({
          type: ActionTypes.GET_ALL_INSTRUCTORS_FAILURE
        });
        //console.log({ error })
      }
    };
  };

  static getAllInstructorSlots = slotResult => {
    return async dispatch => {
      if (slotResult && slotResult.length > 0) {
        console.log(slotResult, "in redux");
        dispatch({
          type: ActionTypes.GET_ALL_INSTRUCTORS_SLOTS_SUCCESS,
          payload: slotResult
        });
      } else {
        throw slotResult;
      }
    };
  };
}
