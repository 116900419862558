import React from 'react';
import Loader from "react-content-loader";

const ContentLoader = ({ height, width, shape, size, x = 0, y = 0, rx = 4, ry = 4, }) => {
    return (
        <div style={{ height: shape === "circle" ? size : height, width: shape === "circle" ? size : width }}>
            <Loader
                height={shape === "circle" ? size + y : ((height + y) > 0 ? (height + y) : 0)}
                width={shape === "circle" ? size + x : ((width + x) > 0 ? (width + x) : 0)}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                ariaLabel={false}
            >
                {shape === "circle" ?
                    <circle cx={`${(size / 2) + x}`} cy={`${(size / 2) + y}`} r={`${size / 2}`} /> :
                    <rect x={x} y={y} rx={rx} ry={ry} width={(width > 0 ? width : 0)} height={(height > 0 ? height : 0)} />
                }
            </Loader>
        </div>
    );
}

export default ContentLoader;