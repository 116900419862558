import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { connect } from 'react-redux';
import AuthActions from "../../../../store/actions/authActions";
import { getText, checkKeyInObject } from "../../../../utils/utils";
import { BASE_URL } from "../../../../config";
import axios from 'axios';
import {getProfileData, EditProfileStatus} from '../../../../store/actions/EditProfile';
import {changePasswordStatus} from '../../../../store/actions/changePasswordAction';
//import { useLocation } from 'react-router-dom'

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      userName: null,
      profile: {},
      userType:0,
      userPic:null
    };
   // console.log('propssss', this.props)
  }

  handleLogout() {
    this.props.history.push("/login");
  }

  toggleUserActions = () => {
    this.setState({
      visible: !this.state.visible
    });
  }

  componentDidMount() {
    if (localStorage.getItem('user-click-drive')) {
      let user = JSON.parse(localStorage.getItem('user-click-drive'));
      if (checkKeyInObject(user, 'name', 'bool', false)) {
        this.setState({ userName: user.name })
      }
      if (checkKeyInObject(user, 'type', 'bool', false)) {
        this.setState({ userType: user.type })
      }
      if (checkKeyInObject(user, 'profile_picture', 'bool', false)) {
        this.setState({ userPic: user.profile_picture })
      }
    }
  }
  getProfileData = () => {
  
    let token = localStorage.getItem('token-click-drive');
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
    axios.get(`${BASE_URL}/profile`)
      .then((response) => {
        
       //console.log('get profile work',response)
        this.props.getProfileData(response.data.result)
        this.props.EditProfileStatus(true)
      })
      .catch((err) => { 
       // console.log('error of profile api', err)
       })
  }

  render() {
    const { userName } = this.state;
   // const location = useLocation();
   // console.log(location.pathname);
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
          style={{objectFit:'cover'}}
            className="user-avatar rounded-circle mr-2"
            src={!this.state.userPic?require("./../../../../images/avatars/1.jpg"):this.state.userPic}
            alt="User Avatar"
            width='40px'
            height='40px'
          />{" "}
          <span className="d-none d-md-inline-block">{userName ? userName : 'User'}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
        {this.state.userType===1?<>
         <DropdownItem onClick={()=>{this.getProfileData()}} tag={Link} to='/instructor-board'>
         <i className="material-icons">&#xE8B8;</i>{getText('Edit Profile', 'Bewerk Profiel')}
        </DropdownItem>
        <DropdownItem divider />
        </>
        :null}
          <DropdownItem onClick={()=>{this.props.changePasswordStatus(true)}} tag={Link} 
          to={this.state.userType===1?'/instructor-board':'/admin-management'}>
          <i className="fa">&#xf023;</i>{getText('Change Password', 'Verander Wachtwoord')}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            className="text-danger"
            onClick={() => {
              localStorage.removeItem('token-click-drive');
              localStorage.removeItem('user-click-drive');
              this.handleLogout();
              this.props.logout();
            }}
          >
            <i className="material-icons text-danger">&#xE879;</i> {getText('Logout', 'Uitloggen')}
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (...args) => dispatch(AuthActions.logout(...args)),
    getProfileData:(data)=>dispatch(getProfileData(data)),
    EditProfileStatus:(open)=>dispatch(EditProfileStatus(open)),
    changePasswordStatus:(open)=>dispatch(changePasswordStatus(open)),
  }
}

export default connect(null, mapDispatchToProps)(UserActions);
