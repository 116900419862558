import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  FormSelect,
  Form,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "shards-react";

import PageTitle from "./../../components/common/PageTitle";
import DateRangePicker from "./../../components/common/DateRangePicker";
import {checkToken} from "../../store/actions/validity";
import LessonsCommissionView from "./super-admin/instructor-view/lessons-commission-view";
import LessonRevenuesView from "./super-admin/instructor-view/lesson-revenues-view";
import InstructorIndividualView from "./super-admin/instructor-view/instructor-individual-view";
import InstructorStudentReview from "./super-admin/instructor-view/instructor-student-reviews";
import { connect } from "react-redux";
import { getText, checkArrayLength, formatDate, checkKeyInObject, JSONToCSVConvertor } from "../../utils/utils";
import AdminInstructorActions from "../../store/actions/adminInstructorActions";
import ContentLoader from '../../components/common/ContentLoader';
import { BASE_URL } from "../../config";

const letters = /^[A-Za-z]+$/;
const lessonCommissionOptions = {
  series: [
    {
      name: "Clickdrive Revenue",
      // type: "column",
      data: []
    },
    {
      name: "Number of lessons completed",
      // type: "column",
      data: []
    },
    {
      name: "Lessons cancelled instructor",
      // type: "column",
      data: []
    },
    {
      name: "Lessons cancelled student",
      // type: "column",
      data: []
    }
  ],
  options: {
    title: {
      text: "Clickdrive Lessons & Revenues",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "grey"
      }
    },
    colors: ["#228B22", "#FEB019", "#DD4132", "#00539C"],
    dataLabels: {
      enabled: false
    },
    chart: {
      type: "column",
      // stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        //added by rija
        columnWidth: '55%',
        endingShape: 'rounded',
        // dataLabels: {
        //   position: 'top', // top, center, bottom
        // },
      },
    },
    // dataLabels: {
    //   enabled: true,
    //   formatter: function (val) {
    //       return val && val.toFixed(1);
    //   },
    //   offsetY: -15,
    //   style: {
    //     fontSize: '10px',
    //     colors: ['#339933','#FEB019','#DD4132','#00539C']
    //   },
    // },
    stroke: {
      width: [4, 1, 1]
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    xaxis: {
      type: "string",
      //categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]
      categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
    },
    yaxis: {
        seriesName: 'Logarithmic',
        logarithmic: true
        },
    // tooltip:{
    //   enabled: true,
    // },
    // yaxis: [
    //   {
    //     seriesName: "Clickdrive Revenue",
      
    //   },
    //   {
    //     seriesName: "Number of lessons completed",
    //     opposite: true,
    //     // show:false
     
    //   },
    //   {
    //     seriesName:"Lessons cancelled instructor",
    //     show:false
      
    //   },
    //   {
    //     seriesName: "Lessons cancelled student",
    //     show:false
      
    //   },
    // ],


    // yaxis: {
    //   seriesName: "Clickdrive Revenue",
    //   labels: {
    //     formatter: function (val) {
    //     return "€" + val.toLocaleString();
    //     }
    //   },
    // },



    // yaxis: [
    //   {
    //     seriesName: "Clickdrive Revenue",
    //     // min: 0,
    //     // max: 8000,
    //     tickAmount: 4,
    //     opposite: true,
    //     axisTicks: {
    //       show: true
    //     },
    //     labels: {
    //       formatter: function (val) {
    //         return "€" + val.toLocaleString();
    //       }
    //     }
    //   },

    //   {
    //     seriesName: "Number of lessons completed",
    //     // min: 0,
    //     // max: 2000,
    //     tickAmount: 4,
    //     axisTicks: {
    //       show: true
    //     },
    //     title: {
    //       text: ""
    //     },
    //     labels: {
    //       formatter: function (val) {
    //         return val;
    //       }
    //     }
    //   },
    //   {
    //     show: false,
    //     seriesName: "Lessons cancelled instructor",
    //     // min: 0,
    //     // max: 2000,
    //     tickAmount: 4,
    //     axisTicks: {
    //       show: true
    //     },
    //     title: {
    //       text: ""
    //     },
    //     labels: {
    //       formatter: function (val) {
    //         return val;
    //       }
    //     }
    //   },
    //   {
    //     show: false,
    //     seriesName: "Lessons cancelled student",
    //     // min: 0,
    //     // max: 2000,
    //     tickAmount: 4,
    //     axisTicks: {
    //       show: true
    //     },
    //     title: {
    //       text: ""
    //     },
    //     labels: {
    //       formatter: function (val) {
    //         return val;
    //       }
    //     }
    //   }
    // ],
    legend: {
      position: "top",
      offsetY: 0
    },
    fill: {
      opacity: 1
    }
  }
};


const instructorIndividualData = {
  series: [
    {
      name: "Instructor Revenue",
      data: []
    }
  ],

  options: {
    title: {
      align: "center",
      style: {
        fontSize: "14px", 
        fontWeight: "bold",
        color: "grey"
      }
    },
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: false,
      width: 2,
      colors: ["transparent"]
    },
    colors: ["#00539C"],
    xaxis: {
      type: "string",
      labels: {
        rotateAlways: true,
        show: true,
        rotate: -90
      },
      categories: []
    },
    
    yaxis: {
      title: {
        text: "Instructor Revenue"
      },
      seriesName: "Instructor Revenue",
      // min: 0,
      // max: 1000,
      tickAmount: 4,
      axisTicks: {
        show: true
      },
      labels: {
        formatter: function (val) {
          return "€" + val.toLocaleString();
        }
      }
    },
    fill: {
      opacity: 1
    }
  }
};

const AdminInstructorView = props => {
  const [lesCommPeriod, setLesCommPeriod] = useState("week");
  const [lesCommPeriodStartDate, setLesCommPeriodStartDate] = useState("");
  const [lesCommPeriodEndDate, setLesCommPeriodEndDate] = useState("");

  const [instructorViewPeriod, setInstructorViewPeriod] = useState("week");
  const [instructorViewStartDate, setInstructorViewStartDate] = useState("");
  const [instructorViewEndDate, setInstructorViewEndDate] = useState("");

  const [avgLessonPeriod, setAvgLessonPeriod] = useState("week");
  const [avgLessonStartDate, setAvgLessonStartDate] = useState("");
  const [avgLessonEndDate, setAvgLessonEndDate] = useState("");

  const [studentReviewsPeriod, setStudentReviewsPeriod] = useState("week");
  const [studentReviewsStartDate, setStudentReviewsStartDate] = useState("");
  const [studentReviewsEndDate, setStudentReviewsEndDate] = useState("");

  const [lessonsAndCommission, setLessonsAndCommission] = useState([]);
  const [instructorView, setInstructorView] = useState([]);
  const [instructorCategories,setInstructorCategories]= useState([]);
  const [avg_lessons_revenues, setAvg_lessons_revenues] = useState([]);

  const [hisStudents, setHisStudents] = useState([]);
  const [hisReviews, setHisReviews] = useState({});

  const [apiCall, triggerAPICall] = useState(false);

  const [pickedInstructor, setInstructor] = useState(null);
  const [instructorNameState, updateInstructorName] = useState("");
  const [filteredList, updateFilterList] = useState([]);
  const [instructorsList, updateInstructorList] = useState([]);

  const { isEnglish, isLoading } = props;
  useEffect(()=>{
    checkToken(props.history);
  },[])
  const onSearchClick = () => {
    triggerAPICall(!apiCall);
  }

  // search instructor selected function
  const selectInstructor = (instructorOBJ) => {
    updateInstructorName(instructorOBJ.instructor_name);
    updateFilterList([]);
    setInstructor(instructorOBJ);
  }

  useEffect(() => {
    if (checkArrayLength(props.allInstructors)) {
      updateInstructorList([...props.allInstructors]);
    }
  }, [props.allInstructors])

  useEffect(() => {
    //  console.log('less n comm',props.lessonsAndCommission)
    if (checkArrayLength(props.lessonsAndCommission) && lesCommPeriod != 'selection') {
      let reversedArray=props.lessonsAndCommission.map((record,idx)=>{
    
          let updatedData=record.data.reverse()
          return{...record, data: updatedData}
        
      })
      // let filtered=reversedArray.filter((el,i)=>{
      //   return i!==0
      // })
      console.log('less n comm updated',reversedArray)
      setLessonsAndCommission([...reversedArray]);
    }
  }, [props.lessonsAndCommission, lesCommPeriod])

  useEffect(() => {
      if(instructorView.length){
        setInstructorCategories([...instructorView[0].categories])
        instructorIndividualData.options.xaxis.categories=[...instructorView[0].categories];
      }
    
    
  }, [instructorView, instructorViewPeriod])

  useEffect(() => {
    if (checkArrayLength(props.instructorIndividualData) && instructorViewPeriod != 'selection') {

      setInstructorView([...props.instructorIndividualData]);
      if(instructorView.length){
        setInstructorCategories([...instructorView[0].categories])
        instructorIndividualData.options.xaxis.categories=[...instructorView[0].categories];
                // console.log(instructorView[0].categories)

        // console.log('checking data', instructorIndividualData)
      }
    
    }
  }, [props.instructorIndividualData, instructorViewPeriod])

  useEffect(() => {
    if (checkArrayLength(props.avg_lessons_revenues) && avgLessonPeriod != 'selection') {
      setAvg_lessons_revenues([...props.avg_lessons_revenues]);
    }
  }, [props.avg_lessons_revenues, avgLessonPeriod])

  useEffect(() => {
    if (checkArrayLength(Object.keys(props.hisReviews)) && studentReviewsPeriod != 'selection') {
      setHisReviews({ ...props.hisReviews });
    }
  }, [props.hisReviews, studentReviewsPeriod])

  useEffect(() => {
    if (checkArrayLength(props.hisStudents) && studentReviewsPeriod != 'selection') {
      setHisStudents([...props.hisStudents]);
    }
  }, [props.hisStudents, studentReviewsPeriod])

  useEffect(() => {
    if (studentReviewsPeriod && ((studentReviewsPeriod != 'selection') || (studentReviewsPeriod == 'selection' && studentReviewsStartDate && studentReviewsEndDate))) {
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: studentReviewsPeriod ? studentReviewsPeriod : 'week', name: instructorNameState ? instructorNameState : null };
      if (studentReviewsPeriod == 'selection') {
        apiBody = {
          ...apiBody,
          from_date: `${formatDate(studentReviewsStartDate)}`,
          to_date: `${formatDate(studentReviewsEndDate)}`,
        }
      }
      axios.post(`${BASE_URL}/admin/instructor/his-students`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.his_students', 'bool', false) && checkArrayLength(response.data.result.his_students)) {
            setHisStudents([...response.data.result.his_students]);
          }
          else {
            setHisStudents([]);
          }
        })
        .catch((err) => {
          //console.log(err)
        })

      axios.post(`${BASE_URL}/admin/instructor/his-reviews`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.his_reviews', 'bool', false) && checkArrayLength(Object.keys(response.data.result.his_reviews))) {
            setHisReviews({ ...response.data.result.his_reviews });
          }
          else {
            setHisReviews({});
          }
        })
        .catch((err) => {
          //console.log(err)
        })
    }
  }, [studentReviewsPeriod, studentReviewsStartDate, studentReviewsEndDate, apiCall])

  useEffect(() => {
    if (avgLessonPeriod && ((avgLessonPeriod != 'selection') || (avgLessonPeriod == 'selection' && avgLessonStartDate && avgLessonEndDate))) {
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: avgLessonPeriod ? avgLessonPeriod : 'week', name: instructorNameState ? instructorNameState : null };
      if (avgLessonPeriod == 'selection') {
        apiBody = {
          ...apiBody,
          from_date: `${formatDate(avgLessonStartDate)}`,
          to_date: `${formatDate(avgLessonEndDate)}`,
        }
      }
      axios.post(`${BASE_URL}/admin/instructor/avg-lessons-revenues`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.avg_lessons_revenues', 'bool', false) && checkArrayLength(response.data.result.avg_lessons_revenues)) {
            setAvg_lessons_revenues([...response.data.result.avg_lessons_revenues]);
          }
          else {
            setAvg_lessons_revenues([]);
          }
        })
        .catch((err) => {
          //console.log(err)
        })
    }
  }, [avgLessonPeriod, avgLessonStartDate, avgLessonEndDate, apiCall])

  useEffect(() => {
    if (instructorViewPeriod && ((instructorViewPeriod != 'selection') || (instructorViewPeriod == 'selection' && instructorViewStartDate && instructorViewEndDate))) {
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: instructorViewPeriod ? instructorViewPeriod : 'week', name: instructorNameState ? instructorNameState : null };
      if (instructorViewPeriod == 'selection') {
        apiBody = {
          ...apiBody,
          from_date: `${formatDate(instructorViewStartDate)}`,
          to_date: `${formatDate(instructorViewEndDate)}`,
        }
      }
      axios.post(`${BASE_URL}/admin/instructor/instructor-revenue`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.instructor_revenue', 'bool', false) && checkArrayLength(response.data.result.instructor_revenue)) {
            setInstructorView([...response.data.result.instructor_revenue]);
          }
          else {
            setInstructorView([]);
          }
        })
        .catch((err) => {
         // console.log(err)
        })
    }
  }, [instructorViewPeriod, instructorViewStartDate, instructorViewEndDate, apiCall])


  useEffect(() => {
    if (lesCommPeriod && ((lesCommPeriod != 'selection') || (lesCommPeriod == 'selection' && lesCommPeriodStartDate && lesCommPeriodEndDate))) {
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: lesCommPeriod ? lesCommPeriod : 'week' };
      if (lesCommPeriod == 'selection') {
        apiBody = {
          ...apiBody,
          from_date: `${formatDate(lesCommPeriodStartDate)}`,
          to_date: `${formatDate(lesCommPeriodEndDate)}`,
        }
      }
      //console.log('api dataaaa', apiBody)
      axios.post(`${BASE_URL}/admin/instructor/lessons-commission`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.lessons_commission', 'bool', false) && checkArrayLength(response.data.result.lessons_commission)) {
            let reversedArray=response.data.result.lessons_commission.map((record)=>{
              let updatedData=record.data.reverse()
              return{...record, data: updatedData}
            })
          //  console.log('new',reversedArray)
      
            setLessonsAndCommission([...reversedArray]);
          }
          else {
            setLessonsAndCommission([]);
          }
        })
        .catch((err) => {
         // console.log(err)
        })
    }
  }, [lesCommPeriod, lesCommPeriodStartDate, lesCommPeriodEndDate, apiCall])

  useEffect(() => {
    props.getAdminInstructorData();
    props.getAllInstructors();
  }, [])

  const getLessonReviewsData = (reviewData) => {
    let result = [{
      1: "Instructor Name",
      2: "Phone",
      3: "Email",
      4: "# Lessons",
      5: "ClickDrive Commission",
      6: "Instructor Revenue",
    }]
    let revenueSum = 0;
    let lessonsSum = 0;
    let clickdriveCommissionSum = 0;

    if (checkArrayLength(reviewData)) {
      reviewData.map((record) => {

        revenueSum += Number(checkKeyInObject(record, 'Instructorrevenue', 'value', 0));
        lessonsSum += Number(checkKeyInObject(record, 'Lessons', 'value', 0));
        clickdriveCommissionSum += Number(checkKeyInObject(record, 'Clickdrivecommission', 'value', 0));

        result.push({
          1: checkKeyInObject(record, 'NAME', 'value', '-'),
          2: checkKeyInObject(record, 'Phone', 'value', '-'),
          3: checkKeyInObject(record, 'Email', 'value', '-'),
          4: checkKeyInObject(record, 'Lessons', 'value', '-'),
          5: checkKeyInObject(record, 'Clickdrivecommission', 'value', '-'),
          6: checkKeyInObject(record, 'Instructorrevenue', 'value', '-'),
        });
      })
      result.push({ 1: "Average", 2: "-", 3: "-", 4: `${Math.round(lessonsSum / reviewData.length)}`, 5: `${Math.round(clickdriveCommissionSum / reviewData.length)}`, 6: `${Number(revenueSum / reviewData.length).toFixed(2)}` });
      return result;
    } else {
      return [
        {
          1: "Instructor Name",
          2: "Phone",
          3: "Email",
          4: "# Lessons",
          5: "ClickDrive Commission",
          6: "Instructor Revenue",
        },
        { 1: "-", 2: "-", 3: "-", 4: "-", 5: "-", 6: "-" },
        { 1: "Average", 2: "-", 3: "-", 4: "-", 5: "-", 6: "-" },
      ]
    }
  }

  // searching intructor by there name for updating credits
  const searchIntructors = (instructorName) => {
    if (pickedInstructor && typeof pickedInstructor != "undefined") {
      setInstructor(null);
      updateInstructorName("");
      updateFilterList([]);
    } else if (instructorName == "" || instructorName.match(letters)) {
      updateInstructorName(instructorName);
      setTimeout(() => {
        if (instructorName.match(letters) && instructorsList && instructorsList.length > 0) {
          const filteredOptions = instructorsList.filter(
            (option) => option.instructor_name.toLowerCase().indexOf(instructorName.toLowerCase()) > -1
          );
          updateFilterList(filteredOptions);
        }
      }, 100);
    }
  }
// const reverseLessCommData=(lessonsAndCommission)=>{
// console.log(lessonsAndCommission)
// if (checkArrayLength(lessonsAndCommission)) {
//   let reversedArray=lessonsAndCommission.map((record)=>{
//     let updatedData=record.data.reverse()
//     return{...record, data: updatedData}
//   })
//   console.log('less n comm updated',reversedArray)
//   setLessonsAndCommission([...reversedArray]);
// }

// }
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={getText('Instructor View', 'Instructeur bekijken', isEnglish)}
          subtitle="Super Admin"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <Col lg="6" className="mb-2">
          <Card small className="mb-2">
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">
                {getText('Clickdrive Lessons & Commission', 'Clickdrive-lessen en commissie', isEnglish)}
              </h3>
            </CardHeader>
            {/* {reverseLessCommData(lessonsAndCommission)} */}
            {/* {console.log('less n comm2',lessonsAndCommission)} */}
            <CardBody style={{ height: "450px" }}>
              {isLoading && !checkArrayLength(avg_lessons_revenues) ? 
              <>
              <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
              <ContentLoader height={420} width={560} />
              </>
                :<>
                
                <LessonsCommissionView
                  height="100%"
                  options={lesCommPeriod == 'week' ? {
                    ...lessonCommissionOptions.options,
                    xaxis: {
                      type: "string",
                      categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
                    },
                    
                  } : lesCommPeriod == 'month' ?
                      {
                        ...lessonCommissionOptions.options,
                        xaxis: {
                          type: "string",
                          categories: ["Month -4", "Month -3", "Month -2", "Month -1", "Current Month"]
                        },
                      } : lesCommPeriod == 'year' ?
                        {
                          ...lessonCommissionOptions.options,
                          xaxis: {
                            type: "string",
                            categories: ["Year -4", "Year -3", "Year -2", "Year -1", "Current Year"]
                          },
                        }
                        : lesCommPeriod == 'selection' ?
                          {
                            ...lessonCommissionOptions.options,
                            xaxis: {
                              type: "string",
                              categories: ["", "", "", "", ""],
                            }
                          }
                          :
                          {
                            ...lessonCommissionOptions.options,
                          }
                  }
                  // series={[
                  //   {
                  //     name: "Clickdrive Revenue",
                  //     type: "column",
                  //     data: []
                  //   },
                  //   {
                  //     name: "Number of lessons completed",
                  //     type: "column",
                  //     data: []
                  //   },
                  //   {
                  //     name: "Lessons cancelled instructor",
                  //     type: "column",
                  //     data: []
                  //   },
                  //   {
                  //     name: "Lessons cancelled student",
                  //     type: "column",
                  //     data: []
                  //   }
                  // ]}
                  
                  series={checkArrayLength(lessonsAndCommission) ? [...lessonsAndCommission] : [
                    {
                      name: "Clickdrive Revenue",
                      type: "column",
                      data: []
                    },
                    {
                      name: "Number of lessons completed",
                      type: "column",
                      data: []
                    },
                    {
                      name: "Lessons cancelled instructor",
                      type: "column",
                      data: []
                    },
                    {
                      name: "Lessons cancelled student",
                      type: "column",
                      data: []
                    }
                  ]}
                  
                  // series={[
                  //   {name: "Clickdrive Revenue", type: "column", data: [null, "20", "125", "170", "5"].reverse()},
                  //   {name: "Number of lessons completed", type: "column", data: [null, "4", "25", "34", "1"].reverse()},
                  //   {name: "Lessons cancelled instructor", type: "column", data: [null, null, "2", "3", "1"].reverse()},
                  //   {name: "Lessons cancelled student", type: "column", data: [null, null, "1", null, "1"].reverse()}
                  // ]}
                  type="bar"
                />
                
                </>
}
              
                  { (lesCommPeriod == 'month') ? <p className="text-center">{getText('Month', 'Maand', isEnglish)}</p>: null}
                 { (lesCommPeriod == 'week') ? <p className="text-center">Week</p>: null}
                  { (lesCommPeriod == 'year') ? <p className="text-center">{getText('Year', 'Jaar', isEnglish)}</p>: null}
                   {(lesCommPeriod == 'selection') ? <p className="text-center">{getText('Date', 'Datum', isEnglish)}</p>: null}
                
            </CardBody>
            <CardFooter style={{marginTop:30}} className="border-top pl-2">
              <Row>
                <Col lg="3">
                  <FormSelect
                    size="sm"
                    value={lesCommPeriod}
                    style={{ maxWidth: "150px" }}
                    onChange={val => {
                      setLesCommPeriod(val.currentTarget.value);
                    }}
                  >
                    <option value="week">Week</option>
                    <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                    <option value="year">{getText('Year', 'Jaar', isEnglish)}</option>
                    <option value="selection">{getText('Selection', 'Selectie', isEnglish)}</option>
                  </FormSelect>
                </Col>
                {lesCommPeriod === "selection" && (
                  <Col lg="9">
                    <DateRangePicker
                      startDate={lesCommPeriodStartDate}
                      endDate={lesCommPeriodEndDate}
                      handleStartDateChange={value =>
                        setLesCommPeriodStartDate(new Date(value))
                      }
                      handleEndDateChange={value =>
                        setLesCommPeriodEndDate(new Date(value))
                      }
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
          <Card small>
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">
                {getText('Avg Lessons & Revenues per Instructor', 'Gem. Lessen en inkomsten per cursusleider', isEnglish)}
              </h3>
            </CardHeader>
            <CardBody className="overflow-auto" style={{height:'350px'}}>
              {/* {console.log('table data', avg_lessons_revenues)} */}
              {
                isLoading && !checkArrayLength(avg_lessons_revenues) ? 
                <>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                <ContentLoader height={200} width={560} />
                </>
                  :
                  <LessonRevenuesView data={checkArrayLength(avg_lessons_revenues) ? getLessonReviewsData(avg_lessons_revenues) : [
                    {
                      1: "Instructor Name",
                      2: "Phone",
                      3: "Email",
                      4: "# Lessons",
                      5: "ClickDrive Commission",
                      6: "Instructor Revenue",
                    },
                    { 1: "-", 2: "-", 3: "-", 4: "-", 5: "-", 6: "-" },
                    { 1: "Average", 2: "-", 3: "-", 4: "-", 5: "-", 6: "-" },
                  ]} />
              }
        
            </CardBody>
            <CardFooter className="border-top pl-2">
              <Button theme="primary" className="mr-1" style={{ marginBottom: 10 }} onClick={() => { JSONToCSVConvertor(avg_lessons_revenues, 'Lessons & Revenues', 'Lessons & Revenues', false, ';') }} >
                {getText('Download', 'Downloaden', props.isEnglish)}
              </Button>
              <Row>
                <Col lg="3">
                  <FormSelect
                    size="sm"
                    value={avgLessonPeriod}
                    style={{ maxWidth: "150px" }}
                    onChange={val => {
                      setAvgLessonPeriod(val.currentTarget.value);
                    }}
                  >
                    <option value="week">Week</option>
                    <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                    <option value="year">{getText('Year', 'Jaar', isEnglish)}</option>
                    <option value="selection">{getText('Selection', 'Selectie', isEnglish)}</option>
                  </FormSelect>
                </Col>
                {avgLessonPeriod === "selection" && (
                  <Col lg="9">
                    <DateRangePicker
                      startDate={avgLessonStartDate}
                      endDate={avgLessonEndDate}
                      handleStartDateChange={value =>
                        setAvgLessonStartDate(new Date(value))
                      }
                      handleEndDateChange={value =>
                        setAvgLessonEndDate(new Date(value))
                      }
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" className="mb-2">
          <Card small className="mb-2">
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Instructor View (Individual)', 'Instructeurweergave (Individueel)', isEnglish)}</h3>
            </CardHeader>
            <CardBody style={{height:'480px'}}>
         
              {/* {instructorIndividualData.options.xaxis.categories=[...instructorView.categories]
              
              } */}
              {/* {console.log('checking data', instructorView[0])} */}
              {isLoading && !checkArrayLength(instructorView) ? 
              <>
              <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
              <ContentLoader height={350} width={560} />
              </>
                :
                <>
                 
                  <Col lg="12">
                    <Form className="shadow m-2">
                      <InputGroup seamless className="ml-0">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>
                            <i className="material-icons">search</i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          className="form-control"
                          placeholder="Type/find name of instructor..."
                          value={instructorNameState}
                          onChange={(e) => searchIntructors(e.target.value)}
                        />
                        <Button theme="primary" disabled={false} className="mr-1" onClick={() => { onSearchClick() }} >
                          Search
                        </Button>
                      </InputGroup>
                      {filteredList && filteredList.length > 0 && instructorNameState != "" && (
                        <ul className="options" style={{ position: 'absolute', zIndex: 99, left: -10 }}>
                          {
                            filteredList.map((item, index) => {
                              return (
                                <div key={item.instructor_id} className="option-active"
                                  style={{ backgroundColor: index % 2 == 1 ? '#fff' : '#f8f8ff', padding: 10, width: window.innerWidth / 2 }}
                                  onClick={() => selectInstructor(item)}>
                                  {item.instructor_name}
                                </div>
                              )
                            })
                          }
                        </ul>
                      )}
                    </Form>
                  </Col>
                  {/* {console.log('new instructor data', instructorIndividualData)} */}
                  <InstructorIndividualView
                    options={{...instructorIndividualData.options,...instructorIndividualData.options.xaxis, }}
                    series={checkArrayLength(instructorView) ? [...instructorView] : [
                      {
                        name: "Instructor Revenue",
                        data: []
                      }
                    ]}
                    type="bar"
                    height={350}
                  />
                </>
              }
                  {(instructorViewPeriod == 'month') ? <p className="text-center">{getText('Month', 'Maand', isEnglish)}</p>: null}
                  {(instructorViewPeriod == 'week') ? <p className="text-center">Week</p>: null}
                  {(instructorViewPeriod == 'year') ? <p className="text-center">{getText('Year', 'Jaar', isEnglish)}</p>: null}
                  {(instructorViewPeriod == 'selection') ? <p className="text-center">{getText('Date', 'Datum', isEnglish)}</p>: null}
            </CardBody>
            <CardFooter className="border-top pl-2">
              <Row>
                <Col lg="3">
                  <FormSelect
                    size="sm"
                    value={instructorViewPeriod}
                    style={{ maxWidth: "150px" }}
                    onChange={val => {
                      setInstructorViewPeriod(val.currentTarget.value);
                    }}
                  >
                    <option value="week">Week</option>
                    <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                    <option value="year">{getText('Year', 'Jaar', isEnglish)}</option>
                    <option value="selection">{getText('Selection', 'Selectie', isEnglish)}</option>
                  </FormSelect>
                </Col>
                {instructorViewPeriod === "selection" && (
                  <Col lg="9">
                    <DateRangePicker
                      startDate={instructorViewStartDate}
                      endDate={instructorViewEndDate}
                      handleStartDateChange={value =>
                        setInstructorViewStartDate(new Date(value))
                      }
                      handleEndDateChange={value =>
                        setInstructorViewEndDate(new Date(value))
                      }
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
          <Card small>
            <CardBody >
              {/* {console.log('review', hisReviews)} */}
              {
                isLoading && !checkArrayLength(hisStudents) && !checkArrayLength(hisReviews) ? 
                <>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                <ContentLoader height={600} width={560} />
                </>
                  :
                  <InstructorStudentReview
                    studentHeading={getText('His Students', 'Zijn studenten', isEnglish)}
                    revHeading={getText('His Reviews', 'Zijn recensies', isEnglish)}
                    hisStudents={hisStudents}
                    hisReviews={hisReviews}
                    isLoading={isLoading}
                  />
              }
            </CardBody>
            <CardFooter className="border-top pl-2">
              <Row>
                <Col lg="3">
                  {/* <FormSelect
                    size="sm"
                    value={studentReviewsPeriod}
                    style={{ maxWidth: "150px" }}
                    onChange={val => {
                      setStudentReviewsPeriod(val.currentTarget.value);
                    }}
                  >
                    <option value="week">Week</option>
                    <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                    <option value="year">{getText('Year', 'Jaar', isEnglish)}</option>
                    <option value="selection">{getText('Selection', 'Selectie', isEnglish)}</option>
                  </FormSelect> */}
                </Col>
                {studentReviewsPeriod === "selection" && (
                  <Col lg="9">
                    <DateRangePicker
                      startDate={studentReviewsStartDate}
                      endDate={studentReviewsEndDate}
                      handleStartDateChange={value =>
                        setStudentReviewsStartDate(new Date(value))
                      }
                      handleEndDateChange={value =>
                        setStudentReviewsEndDate(new Date(value))
                      }
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

AdminInstructorView.propTypes = {};

AdminInstructorView.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminInstructorData: (...args) => dispatch(AdminInstructorActions.getAdminInstructorData(...args)),
    getAllInstructors: () => dispatch(AdminInstructorActions.getAllInstructors()),
  }
}
const mapStateToProps = (state) => {
  return ({
    isEnglish: state.authReducer.isEnglish,
    allInstructors: state.adminInstructorReducer.allInstructors,
    isAdminInstructorReducerEmpty: state.adminInstructorReducer.isAdminInstructorReducerEmpty,
    lessonsAndCommission: state.adminInstructorReducer.lessonsAndCommission,
    avg_lessons_revenues: state.adminInstructorReducer.avg_lessons_revenues,
    instructorIndividualData: state.adminInstructorReducer.instructorIndividualData,
    hisReviews: state.adminInstructorReducer.hisReviews,
    hisStudents: state.adminInstructorReducer.hisStudents,
    isLoading: state.adminInstructorReducer.isLoading,
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminInstructorView);
