import React from "react";
import { Container, Button ,FormCheckbox } from "shards-react";

const Table = props => {
    // console.log("listtt",props.data)
    const getTableData = () => {
        return props.data.map((row, rowIndex) => {
            //  console.log('map, m', row)
            return (
                <tr key={rowIndex}>
                    {Object.values(row).map((value, index) => {
                        return (
                            <td className="border" key={index}>
                                {
                                    value === "Action" ? value : index === 4 ? (
                                        <FormCheckbox
                                            toggle
                                            small
                                            disabled={props.loading}
                                            checked={value==="active" ? true : false }
                                            onChange={()=>props._handleActive(value==="active" ? "inactive":"active", row[1])}
                                        ></FormCheckbox>
                                    ) : value === "" ? "-" : value
                                }
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    return (
        <Container fluid className="main-content-container px-2">
            <table className="table mb-1 border small">
                <tbody>
                    {getTableData()}
                </tbody>
            </table>
        </Container>
    );
};

export default Table;
