import React from "react";
import { ListGroup, ListGroupItem, Row, Col, Progress } from "shards-react";
import "./../../../../../styles/click-drive-custom.css";

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const StudentProgress = props => {
  //console.log('StudentProgress', props.data)

    return (
      <Row>
        <Col lg="12">
          <h4 className="mb-2 text-center">{props.title}</h4>
          <ListGroup small flush className="list-group-small overflow-auto" style={{height:'405px'}}>
            {props.data!==undefined? props.data.map((item, idx) => (
              <ListGroupItem key={idx} className="d-flex px-3">
                <span className="text-semibold text-fiord-blue bg-whitesmoke br-progress-bar width-130">
                  <h5 className="text-uppercase mt-3 mb-0 text-bold pl-2 pr-2 text-center">
                    {item && item.slot_date && new Date(item.slot_date).getDate()}
                    <br />
                  </h5>
                  <h6 className="text-uppercase mt-0 text-bold pl-2 pr-2 text-center">
                    {item && item.slot_date && `${months[new Date(item.slot_date).getMonth()]} ${new Date(item.slot_date).getFullYear()}`}
                  </h6>
                </span>
  
                <span className="ml-2 pt-2 pl-4 pr-4 text-center text-semibold br-progress-bar w-100 bg-whitesmoke">
                  <span className="text-reagent-gray">
                    Class {((idx + 1))} :{item.studentName}
                  </span>
                  <h6 className="page-subtitle text-bold-larger">
                    Progress Status
                  </h6>
  
                  <Progress
                    theme="success"
                    style={{ height: "5px" }}
                    className="mb-3"
                    value={item.avg_progess}
                  />
                </span>
              </ListGroupItem>
            )): <p className="m-0 text-center">No Data Available</p>}
          </ListGroup>
        </Col>
      </Row>
    );
  
 
};

export default StudentProgress;
