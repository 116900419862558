import { ActionTypes } from '../constants';
import { checkKeyInObject, checkArrayLength } from '../../utils/utils';
const initialState = {
    activeStudentPopulation: [],
    studentPopulationBuild: [],
    studentViewLessonsIndividual: [],
    studentViewProgressIndividual: [],
    instructorRatingGiven: [],
    isAdminStudentReducerEmpty: true,
    allStudents: [],
    isLoading: false,
};

export const adminStudentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_ADMIN_STUDENT_DATA:
            return { ...state, isLoading: true }

        case ActionTypes.GET_ADMIN_STUDENT_DATA_SUCCESS:

            let _activeStudentPopulation = [];
            let _studentPopulationBuild = [];
            let _studentViewLessonsIndividual = [];
            let _studentViewProgressIndividual = [];
            let _instructorRatingGiven = [];

            if ((checkKeyInObject(action.payload.result, 'total_student', 'bool', false) && checkArrayLength(action.payload.result.total_student)) && checkKeyInObject(action.payload.result.total_student[0], 'data', 'bool', false) && checkArrayLength(action.payload.result.total_student[0].data)) {
                _activeStudentPopulation = [...action.payload.result.total_student[0].data]
            }
            if ((checkKeyInObject(action.payload.result, 'student_population', 'bool', false) && checkArrayLength(action.payload.result.student_population))) {
                _studentPopulationBuild = [...action.payload.result.student_population]
            }
            if ((checkKeyInObject(action.payload.result, 'student_view_lessons_individual', 'bool', false) && checkArrayLength(action.payload.result.student_view_lessons_individual))) {
                _studentViewLessonsIndividual = [...action.payload.result.student_view_lessons_individual]
            }
            if ((checkKeyInObject(action.payload.result, 'instructor_ratings', 'bool', false) && checkArrayLength(action.payload.result.instructor_ratings))) {
                _instructorRatingGiven = [...action.payload.result.instructor_ratings]
            }
            if ((checkKeyInObject(action.payload.result, 'student_view_progress_individual', 'bool', false) && checkArrayLength(action.payload.result.student_view_progress_individual))) {
                _studentViewProgressIndividual = [...action.payload.result.student_view_progress_individual]
            }
                //    console.log('rating data',_instructorRatingGiven)
            return {
                ...state,
                activeStudentPopulation: _activeStudentPopulation,
                studentPopulationBuild: _studentPopulationBuild,
                studentViewLessonsIndividual: _studentViewLessonsIndividual,
                studentViewProgressIndividual: _studentViewProgressIndividual,
                instructorRatingGiven: _instructorRatingGiven,
                isLoading: false,
            };

        case 'SEARCHED':
            if ((checkKeyInObject(action.payload.result, 'student_view_progress_individual', 'bool', false) && checkArrayLength(action.payload.result.student_view_progress_individual))) {
                _studentViewProgressIndividual = [...action.payload.result.student_view_progress_individual]
            }
            if ((checkKeyInObject(action.payload.result, 'student_view_lessons_individual', 'bool', false) && checkArrayLength(action.payload.result.student_view_lessons_individual))) {
                _studentViewLessonsIndividual = [...action.payload.result.student_view_lessons_individual]
            }
            if ((checkKeyInObject(action.payload.result, 'instructor_ratings', 'bool', false) )) {
                _instructorRatingGiven = [...action.payload.result.instructor_ratings]
                
            }
        //    console.log('rating data',_instructorRatingGiven) 
            return{
                ...state,
                studentViewLessonsIndividual: _studentViewLessonsIndividual,
                studentViewProgressIndividual: _studentViewProgressIndividual,
                instructorRatingGiven: _instructorRatingGiven,
            }

        case ActionTypes.GET_ADMIN_STUDENT_DATA_FAILURE:
            return { ...state, isLoading: false }

        case ActionTypes.GET_ALL_STUDENTS_SUCCESS:
            let _students = [];
            if (checkArrayLength(action.payload)) {
                _students = [...action.payload];
            }
            return {
                ...state,
                allStudents: _students,
            }
        default:
            return state;
    }
}
