import React from "react";
import ColumnChart from "./../../../../components/common/ColumnChart";

const InstructorIndividualView = props => {
// console.log('graph',props)
  return (
    <>
    
  {props.series[0].data.length===0?
  <h4 className="m-0 text-center">No Data Available</h4>
  :
  null
  }
    <ColumnChart {...props} />
    </>
  );
};
export default InstructorIndividualView;
