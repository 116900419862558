import React, { useState } from "react";
import { Nav, NavItem, FormCheckbox } from "shards-react";
import Notifications from "./Notifications";
import UserActions from "./UserActions";
import { connect } from "react-redux";
import AuthActions from "../../../../store/actions/authActions";

const NavbarNav = (props) => {
  return (<Nav navbar className="border-left flex-row">
    <NavItem className="border-right" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20, paddingTop: 25 }}>
      <FormCheckbox
        toggle
        small
        checked={!props.isEnglish}
        onChange={() => { props.toggleLanguage() }}>
        <span style={{ fontSize: '.8125rem', fontWeight: 400 }}>Dutch</span>
      </FormCheckbox>
    </NavItem>
    {/* <Notifications /> */}
    <UserActions {...props} />
  </Nav>)
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLanguage: (...args) => dispatch(AuthActions.toggleLanguage(...args)),
  }
}

const mapStateToProps = (state) => ({
  isEnglish: state.authReducer.isEnglish,
})

export default connect(mapStateToProps, mapDispatchToProps)(NavbarNav);