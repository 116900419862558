import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { connect } from "react-redux";
import { checkKeyInObject } from "../../../utils/utils";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  updateSidebarTitles = () => {

  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    if (!this.props.isEnglish) {
      this.setState({
        navItems: this.state.navItems.map(item => {
          let temp = { ...item };
          if (temp.title == 'Management View') {
            temp.title = 'Beheerweergave'
          }
          else if (temp.title == 'Instructor View') {
            temp.title = 'Instructeur bekijken'
          }
          else if (temp.title == 'Student View') {
            temp.title = 'Student uitzicht'
          }
          else if (temp.title == 'Instructor Dashboard') {
            temp.title = 'Dashboard voor instructeurs'
          }
          else if (temp.title == 'Wallet') {
            temp.title = 'Portemonnee'
          }
          else if (temp.title == 'Referral') {
            temp.title = 'Doorverwijzing'
          }
          else if (temp.title == 'Notification') {
            temp.title = 'Meldingen'
          }
          return temp;
        })
      })
    }
    else {
      this.setState({
        navItems: this.state.navItems.map(item => {
          let temp = { ...item };
          if (temp.title == 'Beheerweergave') {
            temp.title = 'Management View'
          }
          else if (temp.title == 'Instructeur bekijken') {
            temp.title = 'Instructor View'
          }
          else if (temp.title == 'Student uitzicht') {
            temp.title = 'Student View'
          }
          else if (temp.title == 'Dashboard voor instructeurs') {
            temp.title = 'Instructor Dashboard'
          }
          else if (temp.title == 'Wallet') {
            temp.title = 'Wallet'
          }
          else if (temp.title == 'Doorverwijzing') {
            temp.title = 'Referral'
          }
          else if (temp.title == 'Meldingen') {
            temp.title = 'Notification'
          }
          return temp;
        })
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isEnglish) {
      this.setState({
        navItems: this.state.navItems.map(item => {
          let temp = { ...item };
          if (temp.title == 'Management View') {
            temp.title = 'Beheerweergave'
          }
          else if (temp.title == 'Instructor View') {
            temp.title = 'Instructeur bekijken'
          }
          else if (temp.title == 'Student View') {
            temp.title = 'Student uitzicht'
          }
          else if (temp.title == 'Instructor Dashboard') {
            temp.title = 'Dashboard voor instructeurs'
          }
          else if (temp.title == 'Wallet') {
            temp.title = 'Portemonnee'
          }
          else if (temp.title == 'Referral') {
            temp.title = 'Doorverwijzing'
          }
          else if (temp.title == 'Notification') {
            temp.title = 'Meldingen'
          }
          return temp;
        })
      })
    }
    else {
      this.setState({
        navItems: this.state.navItems.map(item => {
          let temp = { ...item };
          if (temp.title == 'Beheerweergave') {
            temp.title = 'Management View'
          }
          else if (temp.title == 'Instructeur bekijken') {
            temp.title = 'Instructor View'
          }
          else if (temp.title == 'Student uitzicht') {
            temp.title = 'Student View'
          }
          else if (temp.title == 'Dashboard voor instructeurs') {
            temp.title = 'Instructor Dashboard'
          }
          else if (temp.title == 'Portemonnee') {
            temp.title = 'Wallet'
          }
          else if (temp.title == 'Doorverwijzing') {
            temp.title = 'Referral'
          }
          else if (temp.title == 'Meldingen') {
            temp.title = 'Notification'
          }
          return temp;
        })
      })
    }
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    const { currentUser, isEnglish } = this.props;
    let sideBarMenuItems;
    if (checkKeyInObject(currentUser, 'type', 'bool', false)) {
      sideBarMenuItems = items.filter(item => ((item.viewOnly == currentUser.type) || item.viewOnly == '01'));
    } else {
      sideBarMenuItems = [...items];
    }

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {sideBarMenuItems.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.authReducer.user,
  isEnglish: state.authReducer.isEnglish,
})

export default connect(mapStateToProps, null)(SidebarNavItems);