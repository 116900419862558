import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    FormSelect,
    Button,
    FormInput,
} from "shards-react";
import axios from 'axios';
import PageTitle from "./../../components/common/PageTitle";
import StudentReferList from "./super-admin/management-view/student-referral-list-view";
import { getText, checkArrayLength, checkKeyInObject } from '../../utils/utils';
import { connect } from "react-redux";
import AdminManageActions from "../../store/actions/adminManageActions";
import ContentLoader from "../../components/common/ContentLoader";
import { BASE_URL } from "../../config";


const letters = /^[A-Za-z]+$/;
const token = localStorage.getItem('token-click-drive');
axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }


export class AdminReferralView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            studentNumber: "",
            instructorNameState: "",
            rewards: null,
            numOfActivity: null,
            selectedEvent: null,
            eventsList: [],
            showActivityNumber: false,
            rewardsErr:false,
            numErr:false,
            loading: false,
            selectedId: null,
            selectedEventIdentifier: null
        }
    }

    componentDidMount() {
        this.getReferralEvents();
    }

    getReferralEvents() {
        axios.get(`${BASE_URL}/admin/management/referral-events`)
            .then((response) => {
                console.log(response)
                if (checkKeyInObject(response, 'data', 'bool', false) && checkArrayLength(response.data.result)) {
                    this.setState({ eventsList: response.data.result })
                }
            })
            .catch((err) => {
            })
    }

    _assignReferralReward() {
        const { rewards, numOfActivity, selectedEvent, showActivityNumber, selectedId } = this.state;
        const payload = {
            referral_id: selectedId,
            reward_credits: rewards,
            count: numOfActivity
        }
        if( showActivityNumber && numOfActivity <= 0 ) {
            alert('Please enter number of activity')
            return;
        } else if( rewards <= 0 ) {
            alert('Please enter rewards')
            return;
        } else {
            this.setState({ loading: true })
            axios.put(`${BASE_URL}/admin/management/update-referral-event`, { ...payload })
            .then((response) => {
                if (checkKeyInObject(response, 'data', 'bool', false)) {
                    this.setState({ eventsList: [...this.state.eventsList] })
                }
                this.getReferralEvents();
                this.setState({ loading: false })
                
            })
            .catch((err) => {
                this.setState({ loading: false })
            })
            // if(rewards>=0 && numOfActivity>=0){
            //     this.setState({
            //         rewardsErr:false,
            //         numErr:false
            //     })
            // }
            // else{
            //     if(rewards<0 || numOfActivity<0 ){
            //         if(rewards<0 )
            //             this.setState({
            //                 rewardsErr:true
            //             })
            //         else{
            //             this.setState({
            //                 rewardsErr:true
            //             })
            //         }
            //         if(numOfActivity<0){
            //             this.setState({
            //                 numErr:true
            //             })
            //         }
            //         else{
            //             this.setState({
            //                 numErr:false
            //             })
            //         }
            //     }   
            // }
        }
    }

    _selectEventType(e) {
        
        let index = e.target.selectedIndex;
	    let el = e.target.childNodes[index]
	    let option =  el.getAttribute('event_identifier');  

        if (option === "app_installation" || option === "complete_account_registration") {
            this.setState({ selectedId: e.currentTarget.value, showActivityNumber: false });
        } else {
            this.setState({ selectedId: e.currentTarget.value, showActivityNumber: true });
            
        }
    }

    _handleActive(status,id){
        this.setState({ loading: true })
        const payload = {
            id,
            status
        }
        axios.post(`${BASE_URL}/admin/management/change-referral-status`, { ...payload })
        .then((response) => {
            if (checkKeyInObject(response, 'data', 'bool', false)) {
                this.getReferralEvents();
                // this.setState({ eventsList: [...this.state.eventsList,response.data.result] })
            }
            this.setState({ loading: false })
        })
        .catch((err) => {
        })
        //   status=="active"?this.setState({status:"active"}):this.setState({status:"inactive"})
    }

    _EventName(event_name) {

        switch(event_name) {
            case "app_installation":
                return 'App installatie';
            case "launch_app":
                return 'App-lancering';
            case "complete_account_registration":
                return 'Voltooi de accountregistratie';
            case "search_instructors":
                return 'Zoek instructeur';
            case "view_instructor_detailed_information_page":
                return 'Bekijk de gedetailleerde informatiepagina voor de instructeur';
            case "book_and_pay_lesson":
                return 'Boek en betaal les';
            case "book_but_cancel_booking_before_payment ":
                return 'Boek maar annuleer boeking voor betaling';
            case "book_but_cancel_booking_after_payment_initiation":
                return 'Boek maar annuleer de boeking nadat de betaling is geïnitieerd';
            default:
                return 'App installatie';
        }
    }

    render() {
        const { isEnglish, isLoading } = this.props;
        const { rewards, selectedEvent, eventsList, numOfActivity, showActivityNumber, rewardsErr, numErr, loading } = this.state;
        let modifiedInstructorList = [];
        if (checkArrayLength(eventsList)) {
            modifiedInstructorList = eventsList.map((value, index) => {
                if (value && value !== undefined && value !== null) {
                    return {
                        1: value.id,//value.instructor_id,
                        2: value.event_name,
                        3: value.count,
                        4: value.reward_credits, 
                        5: value.status,
                    }
                }
            })
            modifiedInstructorList = modifiedInstructorList.filter(value => value != null || value != undefined);
            modifiedInstructorList = [{
                1: "Index",
                2: "Event Name",
                3: "Number Of Activities",
                4: "Rewards",
                5: "Action"
            }, ...modifiedInstructorList]
        }
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        title={getText('Referral', 'Doorverwijzing', isEnglish)}
                        subtitle="Super Admin"
                        className="text-sm-left mb-3"
                    />
                </Row>
                <Row>
                    <Col lg="12" className="mb-2">
                        <Card small className="mb-2">
                            <CardHeader className="border-bottom">
                                <h3 className="m-0  text-center">{getText('Reward List', 'Reward lijst', isEnglish)}</h3>
                            </CardHeader>
                            <CardBody className="overflow-auto" style={{ height: (window.innerHeight * 0.6) }}>
                                <Row style={{ marginBottom: 20 }} noGutters>
                                    <Col lg="4" sm='6' className="m-2">
                                        <FormSelect
                                            value={this.state.selectedId}
                                            onChange={e => this._selectEventType(e)}
                                        >
                                            {eventsList && (eventsList.map((value) => (
                                                
                                                <option event_identifier={value.event_identifier} value={value.id}>{getText(value.event_name, this._EventName(value.event_identifier), isEnglish)}</option>

                                            )))}
                                            {/* <option value="appInstall">{getText('App installation', 'App installatie', isEnglish)}</option>
                                            <option value="appLaunch">{getText('App Launch', 'App-lancering', isEnglish)}</option>
                                            <option value="registeration">{getText('Complete account registration', 'Voltooi de accountregistratie', isEnglish)}</option>
                                            <option value="searchInstructor">{getText('Search Instructor', 'Zoek instructeur', isEnglish)}</option>
                                            <option value="viewInstructor">{getText('View instructor etailed information page', 'Bekijk de gedetailleerde informatiepagina voor de instructeur', isEnglish)}</option>
                                            <option value="bookLesson">{getText('Book and pay lesson', 'Boek en betaal les', isEnglish)}</option>
                                            <option value="cancelBeforePayment">{getText('Book but cancel booking before payment', 'Boek maar annuleer boeking voor betaling', isEnglish)}</option>
                                            <option value="cancelAfterPayment">{getText('Book but cancel booking after payment initition', 'Boek maar annuleer de boeking nadat de betaling is geïnitieerd', isEnglish)}</option> */}
                                        </FormSelect>
                                    </Col>
                                    {
                                        showActivityNumber && (
                                            <Col lg="2" sm='6' className="m-2">
                                                <FormInput
                                                    className="form-control"
                                                    placeholder="Number of activity"
                                                    type="number"
                                                    value={numOfActivity}
                                                    onChange={(e) => this.setState({ numOfActivity: e.target.value })}
                                                />
                                                {numErr&&<span style={{color:'red'}}>Invalid Value</span>}
                                            </Col>
                                        )
                                    }
                                    <Col lg="2" sm='6' className="m-2">
                                        <FormInput
                                            className="form-control"
                                            placeholder="Rewards"
                                            value={rewards}
                                            type="number"
                                            onChange={(e) => this.setState({ rewards: e.target.value })}
                                        />
                                        {rewardsErr&&<span style={{color:'red'}}>Invalid Value</span>}
                                    </Col>
                                    <Col lg='2' sm='6'>
                                        <Button style={{ marginTop: 10, height: 33, marginLeft: 10 }} theme="primary" disabled={isLoading} className="mr-1"
                                            onClick={() => this._assignReferralReward()}
                                        >
                                            {getText('Update', 'Bijwerken', isEnglish)}
                                        </Button>
                                        
                                    </Col>
                                </Row>
                                {
                                    isLoading ?
                                        <div style={{ marginTop: 20 }}>
                                            <h4 className="m-0 text-center">{getText('Fetching Data', 'Data ophalen', isEnglish)}</h4>
                                            <ContentLoader height={window.innerHeight / 2} width={window.innerWidth / 1.3} />
                                        </div>
                                        :
                                        eventsList.length < 1 ?
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <h4>{getText('No records found', 'Geen verslagen gevonden', isEnglish)}</h4>
                                            </div>
                                            :
                                            <StudentReferList data={checkArrayLength(modifiedInstructorList) ? modifiedInstructorList : []}
                                                _handleActive={(status, id)=>this._handleActive(status, id)}
                                                loading={loading}
                                            />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAdminManageData: (...args) => dispatch(AdminManageActions.getAdminManageData(...args)),
    }
}

const mapStateToProps = (state) => ({
    isEnglish: state.authReducer.isEnglish,
    isLoading: state.adminManageReducer.isLoading,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminReferralView);