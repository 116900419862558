import React from "react";
import ColumnChart from "./../../../../components/common/ColumnChart";
// import {getText} from '../../../../utils';
// import { connect } from "react-redux";

const InstructorUsageView = props => {
  return (
    <>
    {(props.series[0].data.length===0 && props.series[1].data.length===0 && props.series[2].data.length===0)?
    <h4 className="m-0 text-center">No Data Available</h4>
    :
    null
    }
    <ColumnChart {...props} />
    </>
    );
};
export default InstructorUsageView;
