import React from "react";
import { ListGroup, ListGroupItem, Row, Col } from "shards-react";
import { convertToDate } from "../../../../../utils/utils";

const StudentLessons = props => {
  //console.log('StudentEssons', props.data)
    return (
      <Row>
        <Col lg="12" >
          <h4 className="mb-2 text-center">{props.title}</h4>
          <ListGroup small flush className="list-group-small overflow-auto" style={{height:'405px'}}>
            {props.data!==undefined? props.data.map((item, idx) => (
              <ListGroupItem key={idx} className="d-flex px-3 border">
                <span className="text-semibold text-fiord-blue">
                  <span className="text-uppercase page-subtitle">
                    Lesson: {(idx + 1)}
                  </span>
                  <h6 className="page-title text-reagent-gray">
                    {item.name}
                  </h6>
                </span>
                <span className="ml-auto text-right text-semibold text-reagent-gray">
                  <span className="text-uppercase page-subtitle">
                    {convertToDate(new Date(item.slot_date))}
                  </span>
                  <h6 className="page-title">{item.amount}</h6>
                </span>
              </ListGroupItem>
            )) :  <p className="m-0 text-center">No Data Available</p>}
          </ListGroup>
        </Col>
      </Row>
    );
  
 
};

export default StudentLessons;
