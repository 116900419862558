import { ActionTypes } from '../constants';

export const getProfileData=(data)=>{
    return {
        type: ActionTypes.EDIT_PROFILE,
        payload: data
    }    
}
export const EditProfileStatus=(open)=>{
    return {
        type: ActionTypes.EDIT_PROFILE_STATUS,
        payload: open
    }    
}