import React from "react";
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

const DateRangePicker = props => {
  const handleStartDateChange = value => {
    props.handleStartDateChange(value);
  };

  const handleEndDateChange = value => {
    props.handleEndDateChange(value);
  };

  const { className, startDate, endDate, placeholderStart, placeholderEnd, } = props;
  const classes = classNames(className, "d-flex", "my-auto", "date-range");

  return (
    <InputGroup className={classes}>
      <DatePicker
        size="sm"
        selected={startDate}
        onChange={handleStartDateChange}
        placeholderText={placeholderStart ? placeholderStart : 'Start Date'}
        dropdownMode="select"
        className="text-center"
      />
      <DatePicker
        size="sm"
        selected={endDate}
        onChange={handleEndDateChange}
        placeholderText={placeholderEnd ? placeholderEnd : 'End Date'}
        dropdownMode="select"
        className="text-center"
      />
      <InputGroupAddon type="append">
        <InputGroupText>
          <i className="material-icons">&#xE916;</i>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default DateRangePicker;
