import { ActionTypes } from '../constants';
const initialState = {
    editStatus:false,
    profileData:{}
};
export const EditProfileReducer = (state = initialState, action) =>{
    //console.log('worked reducer')
    switch(action.type){
        case ActionTypes.EDIT_PROFILE:
            return {
                ...state,
                profileData:{...action.payload}
            }
        case ActionTypes.EDIT_PROFILE_STATUS:
            return {
                ...state,
                editStatus:action.payload
            }
        default:
            return state
    }
}
