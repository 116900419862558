import React from "react";
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Form,
  Button
} from "shards-react";
import StudentLessons from "./student-view-cards/student-lessons";
import StudentProgress from "./student-view-cards/student-progress";
import ContentLoader from '../../../../components/common/ContentLoader';
import { checkArrayLength } from "../../../../utils/utils";

// const setudentLessonData = [
//   {
//     lesson: "Lesson 1",
//     studentName: "John Samuel",
//     date: "10 Aug 19 09:00 AM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 2",
//     studentName: "John Samuel",
//     date: "11 Aug 19 10:00 AM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 3",
//     studentName: "John Samuel",
//     date: "12 Aug 19 11:00 AM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 4",
//     studentName: "John Samuel",
//     date: "13 Aug 19 12:00PM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 5",
//     studentName: "John Samuel",
//     date: "14 Aug 19 01:00 PM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 6",
//     studentName: "John Samuel",
//     date: "15 Aug 19 02:00 PM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 7",
//     studentName: "John Samuel",
//     date: "16 Aug 19 03:00 PM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 8",
//     studentName: "John Samuel",
//     date: "17 Aug 19 04:00 PM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 9",
//     studentName: "John Samuel",
//     date: "17 Aug 19 04:00 PM",
//     amount: "EUR 25"
//   },
//   {
//     lesson: "Lesson 10",
//     studentName: "John Samuel",
//     date: "17 Aug 19 04:00 PM",
//     amount: "EUR 25"
//   }
// ];

// const setudentProgressData = [
//   {
//     class: "1",
//     studentName: "John Samuel",
//     date: "10 Aug 19",
//     progress: 40
//   },
//   {
//     class: "2",
//     studentName: "John Samuel",
//     date: "11 Aug 19",
//     progress: 50
//   },
//   {
//     class: "3",
//     studentName: "John Samuel",
//     date: "12 Aug 19",
//     progress: 60
//   },
//   {
//     class: "4",
//     studentName: "John Samuel",
//     date: "13 Aug 19",
//     progress: 80
//   },
//   {
//     class: "4",
//     studentName: "John Samuel",
//     date: "13 Aug 19",
//     progress: 80
//   },
//   {
//     class: "5",
//     studentName: "John Samuel",
//     date: "18 Aug 19",
//     progress: 90
//   },
//   {
//     class: "6",
//     studentName: "John Samuel",
//     date: "19 Aug 19",
//     progress: 60
//   },
//   {
//     class: "7",
//     studentName: "John Samuel",
//     date: "12 Aug 19",
//     progress: 20
//   }
// ];

const StudentViewIndividuals = props => {
  const { studentProgressData, studentLessonData, isLoading, onClickList, onSearchClick, filteredList, instructorNameState, searchIntructors } = props;
  return (
    <Row>
      <Col lg="12">
        <Form className="shadow m-2">
          <InputGroup seamless className="ml-0">
            <InputGroupAddon type="prepend">
              <InputGroupText>
                <i className="material-icons">search</i>
              </InputGroupText>
            </InputGroupAddon>
            <FormInput
              className="form-control"
              placeholder="Type/find name of student..."
              value={instructorNameState}
              onChange={(e) => searchIntructors(e.target.value)}
            />
            <Button theme="primary" disabled={false} className="mr-1" onClick={() => { onSearchClick() }} >
              Search
              </Button>
          </InputGroup>
          {filteredList && filteredList.length > 0 && instructorNameState != "" && (
            <ul className="options" style={{ position: 'absolute', zIndex: 99, left: -10 }}>
              {
                filteredList.map((item, index) => {
                  return (
                    <div key={item.id} className="option-active"
                      style={{ backgroundColor: index % 2 == 1 ? '#fff' : '#f8f8ff', padding: 10, width: window.innerWidth / 2 }}
                      onClick={() => { onClickList(item) }}>
                      {item.name}
                    </div>
                  )
                })
              }
            </ul>
          )}
        </Form>
      </Col>
      <Col lg="6">
        {isLoading && checkArrayLength(!studentLessonData)
          ?
          <ContentLoader height={480} width={250} />
          :
          <StudentLessons  title={props.lessonsTitle} data={studentLessonData} />
        }
      </Col>
      <Col lg="6">
        {isLoading && checkArrayLength(!studentProgressData)
          ?
          <ContentLoader height={480} width={250} />
          :
          <StudentProgress title={props.progressTitle} data={studentProgressData} />
        }
      </Col>
    </Row>
  );
};

export default StudentViewIndividuals;
