import React, { Component } from "react";
import TimePicker from "../common/TimePicker";
import "./InstructorSlotModal.css";
import axios from "axios";
import { Modal, ModalBody, ModalHeader, Button } from "shards-react";
import { BASE_URL } from "../../config";
import { connect } from "react-redux";
import AdminInstructorActions from "../../store/actions/adminInstructorActions";

class InstructorSlotsModal extends Component {
  state = {
    response: [],
    selectedTimeStart: `${new Date().getHours()}:${new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()}`,
    selectedTimeEnd: `${new Date().getHours()}:${new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()}`,
    currentDate: new Date()
  };

  //call on button
  postSlotAPI = async date => {
    const { selectedTimeEnd, selectedTimeStart } = this.state;
    if( selectedTimeStart >= selectedTimeEnd ) {
      alert('Slot end time is not valid')
      return;
    }
    await this.getSlotDurationApicall(date);
    // console.log(this.state.selectedTimeStart);
    // console.log(this.state.selectedTimeEnd);
    this.hideInstructorModal();
  };

  //post api code
  getSlotDurationApicall = async date => {
    let token = localStorage.getItem("token-click-drive");
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    };
    const payload = {
      slot_start_time: this.state.selectedTimeStart,
      slot_end_time: this.state.selectedTimeEnd,
      slot_date: this.props.datetosend_format
    };
    await axios
      .post(`${BASE_URL}/admin/instructor/make-slots `, payload)
      .then(response => {
        // console.log("response", response);
        // console.log(response.data.result);
        this.setState({
          result: response.data.result
          // AdminInstructorActions.getAllInstructorSlots(result)
        });
        this.props.result(response.data.result);
      })
      .catch(err => {
        // console.log(err, "1");
      });
  };

  //hide modal
  hideInstructorModal = () => {
    //pass close modal state to parent instructordashbaord
    this.props.closeModal();
  };

  updatedDateStart = date => {
    let d1 = date + " ";
    d1 = d1.split(" ");
    d1 = d1[4];
    this.setState({
      selectedTimeStart: d1.slice(0, -3)
    });
  };

  updatedDateEnd = date => {
    let d2 = date + " ";
    d2 = d2.split(" ");
    d2 = d2[4];
    this.setState({
      selectedTimeEnd: d2.slice(0, -3)
    });
  };

  render() {
    console.log(this.props.datetosend_format, "props date", this.state);
    return (
      <div>
        <Modal style={{ zIndex: 1090 }} open={this.props.open} fade={true}>
          <ModalHeader className="InstructorModal_header">
            <span className="InstructorModal_heading"> Set your slots</span>

            <Button
              theme="light"
              onClick={this.hideInstructorModal}
              className="InstructorModal_button"
            >
              Close
            </Button>
          </ModalHeader>
          <ModalBody className="InstructorModal">
            <div className="Time_manage">
              <p className="InstructorModal_text">Start time</p>
              <TimePicker
                updateDate={date => this.updatedDateStart(date)}
                //updatedEndTime={date => this.updatedDateEnd(date)}
                time={this.state.currentDate}
              />
            </div>
            <div className="Time_manage">
              <p className="InstructorModal_text">End time</p>
              <TimePicker
                updateDate={date => this.updatedDateEnd(date)}
                // updateDate={date => this.sendEndTimetoParentComp(date)}
                time={this.state.currentDate}
              />
            </div>
          </ModalBody>
          <div className="footer">
            <Button
              theme="primary"
              onClick={() => this.postSlotAPI(this.props.datetosend_format)}
              className="InstructorModal_button"
            >
              Set Slots
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    result: (...arg) =>
      dispatch(AdminInstructorActions.getAllInstructorSlots(...arg))
  };
};
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstructorSlotsModal);
