import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormSelect,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput,
    Form
} from "shards-react";
import {checkToken} from "../../store/actions/validity"
import axios from 'axios';
import PageTitle from "./../../components/common/PageTitle";
import IntructorListTable from "./super-admin/management-view/instructor-list-view";
import { getText, checkArrayLength, JSONToCSVConvertor, formatDate, checkKeyInObject } from '../../utils/utils';
import { connect } from "react-redux";
import AdminManageActions from "../../store/actions/adminManageActions";
import ContentLoader from "../../components/common/ContentLoader";
import { BASE_URL } from "../../config";

const letters = /^[A-Za-z]+$/;
const token = localStorage.getItem('token-click-drive');
axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

const AdminWalletView = props => {

    // initializing state to add new instructors
    const [studentNumber, updateStudNumber] = useState("");
    const [instructorNameState, updateInstructorName] = useState("");
    const [credits, updateCredits] = useState("");
    const [loading, setLoading] = useState(false);

    const [pickedInstructor, setInstructor] = useState(null);

    const [walletList, updateWalletList] = useState([]);
    const [filteredList, updateFilterList] = useState([]);
    const [instructorsList, updateInstructorList] = useState([]);
    useEffect(()=>{
        checkToken(props.history);
      },[])
    // get list of wallets
    const getWalletList = () => {
        axios.get(`${BASE_URL}/admin/management/student-wallet/wallet/id=`)
            .then((response) => {
                if (response && response.data && response.data.Response) {
                    if (response.data.Result && checkArrayLength(response.data.Result)) {
                        updateWalletList(response.data.Result);
                    }
                    else updateWalletList([])
                }
            })
            .catch((err) => {
                // console.log('instructor errorr', err)
            })
    }

    // fetching all instructors
    const getRegistration = () => {
        axios.get(`${BASE_URL}/get-all-instructors?web=1`)
            .then((response) => {
                if (response && response.data && response.data.response) {
                    if (response.data.result && checkArrayLength(response.data.result)) {
                        updateInstructorList(response.data.result);
                    }
                } else updateInstructorList([])
            })
            .catch((err) => {
                // console.log('instructor errorr', err)
            })
    }

    // searching intructor by there name for updating credits
    const searchIntructors = (instructorName) => {
        if (pickedInstructor && typeof pickedInstructor != "undefined") {
            setInstructor(null);
            updateInstructorName("");
            updateFilterList([]);
        } else if (instructorName == "" || instructorName.match(letters)) {
            updateInstructorName(instructorName);
            setTimeout(() => {
                if (instructorName.match(letters) && instructorsList && instructorsList.length > 0) {
                    const filteredOptions = instructorsList.filter(
                        (option) => option.instructor_name.toLowerCase().indexOf(instructorName.toLowerCase()) > -1
                    );
                    updateFilterList(filteredOptions);
                }
            }, 100);
        }
    }

    // deleting instructor from wallet and update list
    const deleteIntructorById = (id) => {
        if (id && id != undefined) {
            axios.delete(`${BASE_URL}/admin/management/student-wallet/delete-wallet/${id}`)
                .then((response) => {
                    if (response && response.status === 200) {
                        if (response.data && response.data.Response) {
                            getWalletList();
                        }
                    }
                })
                .catch((err) => {
                    // console.log('instructor errorr', err)
                })
        }
    }

    // save instructor credits function
    const addInstructorCredits = () => {
        if (!studentNumber && studentNumber == "") {
            alert('Please enter student number')
        } else if (studentNumber.length < 10) {
            alert('Student number is invalid')
        } else if (!pickedInstructor || typeof pickedInstructor === "undefined") {
            alert('Please select instructor name');
        } else if (!credits) {
            alert('Please enter credits');
        } else {
            setLoading(true);
            let apiBody = {
                instructor_id: pickedInstructor.instructor_id,
                student_phone: studentNumber,
                credits: credits
            };
            axios.post(`${BASE_URL}/admin/management/student-wallet/create-wallet/`, { ...apiBody })
                .then((response) => {
                    setLoading(false);
                    if (response && response.status === 200) {
                        updateStudNumber("");
                        updateCredits("");
                        updateInstructorName("");
                        setInstructor(null);
                        if (response.data.StatusCode === 200 && response.data.Result) {
                            getWalletList();
                        }
                    } else {
                        alert(response.data.message.toString());
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    // console.log('instructor errorr', err)
                })
        }
    }

    // search instructor selected function
    const selectInstructor = (instructorOBJ) => {
        updateInstructorName(instructorOBJ.instructor_name);
        updateFilterList([]);
        setInstructor(instructorOBJ);
    }

    useEffect(() => {
        if (props.isAdminManageReducerEmpty) {
            props.getAdminManageData()
        }
    }, [])

    useEffect(() => {
        getWalletList();
        getRegistration();
    }, [])

    let modifiedInstructorList = [];
    if (checkArrayLength(walletList)) {
        modifiedInstructorList = walletList.map((value, index) => {
            if (value && value !== undefined && value !== null) {
                return {
                    1: index + 1,//value.instructor_id,
                    2: value.student_phone,
                    3: value.instructor_name,
                    4: value.credits,
                    5: value.id,
                }
            }
        })
        modifiedInstructorList = modifiedInstructorList.filter(value => value != null || value != undefined);
        modifiedInstructorList = [{
            1: "Index",
            2: "Phone",
            3: "Instructor Name",
            4: "Credits",
            5: "Action"
        }, ...modifiedInstructorList]
    }

    const validateStudentNumber = (number, type) => {
      //  console.log('credit', number, type)
        if (number != ' ' && isNaN(number) == false) {
            if (number.length <= 10 && type === "phone") {
                updateStudNumber(number);
            } else if (type === "credits") {
                updateCredits(number);
            }
        }
    }

    const { isEnglish, isLoading } = props;
    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title={getText('Wallet View', 'Wallet-weergave', isEnglish)}
                    subtitle="Super Admin"
                    className="text-sm-left mb-3"
                />
            </Row>
            <Row>
                <Col lg="12" className="mb-2">
                    <Card small className="mb-2">
                        <CardHeader className="border-bottom">
                            <h3 className="m-0  text-center">{getText('Instructors List', 'Instructeurslijst', isEnglish)}</h3>
                        </CardHeader>
                        <CardBody className="overflow-auto" style={{ height: (window.innerHeight * 0.6) }}>
                            <Row style={{ marginBottom: 20 }} noGutters>
                                <Col lg="4" sm='6'>
                                    <Form className="m-2">
                                        <InputGroup seamless className="ml-0">
                                            <InputGroupAddon type="prepend">
                                                <InputGroupText>
                                                    <i className="material-icons">phone</i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <FormInput
                                                className="form-control"
                                                placeholder="Student Phone"
                                                value={studentNumber}
                                                onChange={(e) => validateStudentNumber(e.target.value, "phone")}
                                            />
                                        </InputGroup>
                                    </Form>
                                </Col>
                                <Col lg="4" sm='6'>
                                    <Form className="m-2">
                                        <InputGroup seamless className="ml-0">
                                            <InputGroupAddon type="prepend">
                                                <InputGroupText>
                                                    <i className="material-icons">person</i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <FormInput
                                                className="form-control"
                                                placeholder="Instructor Name"
                                                value={instructorNameState}
                                                onChange={(e) => searchIntructors(e.target.value)}
                                            />
                                        </InputGroup>
                                        {filteredList && filteredList.length > 0 && instructorNameState != "" && (
                                            <ul className="options" style={{ position: 'absolute', zIndex: 99, left: -10 }}>
                                                {
                                                    filteredList.map((item, index) => {
                                                        return (
                                                            <div key={item.instructor_id} className="option-active"
                                                                style={{ backgroundColor: index % 2 == 1 ? '#fff' : '#f8f8ff', padding: 10, width: window.innerWidth / 2 }}
                                                                onClick={() => selectInstructor(item)}>
                                                                {item.instructor_name}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        )}
                                    </Form>
                                </Col>
                                <Col lg="2" sm='6'>
                                    <Form className="m-2">
                                        <InputGroup seamless className="ml-0">
                                            <InputGroupAddon type="prepend">
                                                <InputGroupText>
                                                    <i className="material-icons">search</i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <FormInput
                                                className="form-control"
                                                placeholder="Credits"
                                                value={credits}
                                                onChange={(e) => validateStudentNumber(e.target.value, "credits")}
                                            />
                                        </InputGroup>
                                    </Form>
                                </Col>
                                <Col lg='2' sm='6'>
                                <Button style={{ marginTop: 10, height: 33, marginLeft: 10 }} theme="primary" disabled={loading} className="mr-1"
                                    onClick={() => addInstructorCredits()}>
                                    {getText('Add', 'toevoegen', props.isEnglish)}
                                </Button>
                                </Col>
                               
                            </Row>
                            {
                                isLoading && !checkArrayLength(modifiedInstructorList) ?
                                    <div style={{ marginTop: 20 }}>
                                         <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                                        <ContentLoader height={window.innerHeight / 2} width={window.innerWidth / 1.3} />
                                    </div>
                                    :
                                    !isLoading && walletList.length < 1 ?
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <h4>
                                                {getText('No records found', 'Geen verslagen gevonden', props.isEnglish)}
                                            </h4>
                                        </div>
                                        : <IntructorListTable data={checkArrayLength(modifiedInstructorList) ? modifiedInstructorList : []}
                                            deleteIntructorWalletRequest={(id) => window.confirm('Are you sure to delete this credits?') && deleteIntructorById(id)}
                                        />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

AdminWalletView.propTypes = {};

AdminWalletView.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
    return {
        getAdminManageData: (...args) => dispatch(AdminManageActions.getAdminManageData(...args)),
    }
}
const mapStateToProps = (state) => ({
    isEnglish: state.authReducer.isEnglish,
    isAdminManageReducerEmpty: state.adminManageReducer.isAdminManageReducerEmpty,
    isLoading: state.adminManageReducer.isLoading,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminWalletView);