import React from "react";
import { ListGroup, ListGroupItem, Row, Col, Progress } from "shards-react";
import "./../../../../../styles/click-drive-custom.css";
import Ratings from "./../../../../../components/common/Ratings";
import { checkKeyInObject } from "../../../../../utils/utils";

const HisReviewsView = props => {

 
  let totalWeigth=((5*props.hisReviews['5Rating'])+(4*props.hisReviews['4Rating'])+(3*props.hisReviews['3Rating'])+(2*props.hisReviews['2Rating'])+(1*props.hisReviews['1Rating']));
//  let actualStars=(totalWeigth / props.hisReviews.total_progress).toFixed(1)
//  let diff=(actualStars-Math.floor(actualStars)).toFixed(1)
// if(diff>0.5){
//   console.log('stars', diff)
// }
let res=Number((totalWeigth / props.hisReviews.total_progress).toFixed(1));
// console.log('rate', Number(res))

  return (
    <Row>
      <Col lg="12" className="text-center mb-3">
        <Ratings
          name="half-rating"
          precision={0.5}
          value={res}
          // value={4.5}
          label={checkKeyInObject(props.hisReviews, 'total_progress', 'value', '-')}
          size="large"
          disabled={true}
        />
      </Col>
      <Col lg="12" className="mb-1 ml-1">
        <Ratings
          name="hover-feedback"
          precision={0.5}
          value={res}
          label={`${res} out of 5`}
          size="small"
          disabled={true}

        />
      </Col>
      <Col lg="12" className="mb-3 ml-1">
        <span className="mr-1">{props.hisReviews.total_progress} customer ratings</span>
      </Col>
      <Col lg="12">
        <ListGroup className="list-group-small m-2">
          {props.data.map((item, idx) => (
            <ListGroupItem key={idx} className="d-flex px-2 pb-3">
              <span
                className="mr-1 ml1"
                style={{ height: "20px", width: "40px" }}
              >
                {item.star} star
              </span>
              <span
                className="ml-1 pt-0 pl-0 pr-0 text-center text-semibold br-progress-bar w-100"
                style={{ height: "20px" }}
              >
                <Progress
                  theme="warning"
                  style={{ height: "8px" }}
                  className="mb-0"
                  value={item.progress}
                />
              </span>
              <span className="ml-1" style={{ height: "20px" }}>
                {item.percent}
              </span>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
    </Row>
  );
};

export default HisReviewsView;
