import { ActionTypes } from "../constants";
import { checkKeyInObject, checkArrayLength } from "../../utils/utils";
const initialState = {
  lessonsAndCommission: [],
  avg_lessons_revenues: [],
  instructorIndividualData: [],
  hisReviews: [],
  hisStudents: [],
  isAdminInstructorReducerEmpty: true,
  allInstructors: [],
  isLoading: false,
  slotResult: []
};

export const adminInstructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADMIN_INSTRUCTOR_DATA:
      return { ...state, isLoading: true };

    case ActionTypes.GET_ADMIN_INSTRUCTOR_DATA_SUCCESS:
      let _lessonsAndCommission = [];
      let _avg_lessons_revenues = [];
      let _instructorIndividualData = [];
      let _hisStudents = [];
      let _hisReviews = [];
      if (
        checkKeyInObject(action.payload, "lessons_commission", "bool", false) &&
        checkArrayLength(action.payload.lessons_commission)
      ) {
        _lessonsAndCommission = [...action.payload.lessons_commission];
      }

      if (
        checkKeyInObject(
          action.payload,
          "avg_lessons_revenues",
          "bool",
          false
        ) &&
        checkArrayLength(action.payload.avg_lessons_revenues)
      ) {
        _avg_lessons_revenues = [...action.payload.avg_lessons_revenues];
      }
      if (
        checkKeyInObject(action.payload, "individual_view", "bool", false) &&
        checkArrayLength(action.payload.individual_view)
      ) {
        _instructorIndividualData = [...action.payload.individual_view];
      }
      if (
        checkKeyInObject(action.payload, "his_students", "bool", false) &&
        checkArrayLength(action.payload.his_students)
      ) {
        _hisStudents = [...action.payload.his_students];
      }
      if (checkKeyInObject(action.payload, "his_reviews", "bool", false)) {
        _hisReviews = { ...action.payload.his_reviews };
      }
      return {
        ...state,
        lessonsAndCommission: _lessonsAndCommission,
        avg_lessons_revenues: _avg_lessons_revenues,
        instructorIndividualData: _instructorIndividualData,
        hisStudents: _hisStudents,
        hisReviews: _hisReviews,
        isLoading: false
      };

    case ActionTypes.GET_ADMIN_INSTRUCTOR_DATA_FAILURE:
      return { ...state, isLoading: false };

    case ActionTypes.GET_ALL_INSTRUCTORS_SUCCESS:
      let _instructors = [];
      if (checkArrayLength(action.payload)) {
        _instructors = [...action.payload];
      }
      return {
        ...state,
        allInstructors: _instructors
      };

    case ActionTypes.GET_ALL_INSTRUCTORS_SLOTS_SUCCESS:
      return {
        ...state,
        slotResult: action.payload
      };

    case ActionTypes.GET_ALL_INSTRUCTORS_SLOTS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case ActionTypes.GET_ALL_INSTRUCTORS_SLOTS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};
