import React from "react";
import { Row, Col } from "shards-react";

import HisStudentViews from "./students-reviews/his-student-view";
import HisReviewsView from "./students-reviews/his-reviews-view";
import { checkKeyInObject } from "../../../../utils/utils";

const hisStudentsData = {
  series: [
    {
      name: "Active",
      //type: "column",
      data: [20]
    },
    {
      name: "Total",
      // type: "column",
      data: [70]
    }
  ],
  colors: ["#00539C", "#DD4132"],
  title: {
    text: "Number of Students",
    align: "center",
    style: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "grey"
    }
  },
  dataLabels: {
    enabled: false
  },
  chart: {
    type: "bar",
    //    stacked: false,
    toolbar: {
      show: false
    }
    // zoom: {
    //   enabled: false
    // }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  yaxis: {
    // min: 0,
    // max: 80,
    tickAmount: 4,
    title: {
      text: "No Of Students"
    }
  },
  xaxis: {
    type: "string",
    show: false,
    categories: ["", ""]
  },
  legend: {
    position: "top",
    offsetY: 0
  },
  fill: {
    opacity: 1
  }
};

const InstructorStudentReview = props => {
 let reviews=props.hisReviews;
 for (let key in reviews) {
  if(reviews[key]===null){
    reviews[key]=0
   }
}


  return (
    <Row>
      <Col lg="6">
        <h3 className="m-3 text-center border-bottom">{props.studentHeading}</h3>
        <HisStudentViews 
          height="400px"
          options={hisStudentsData} // Need to update this later, it should be data instead to date
          series={props.hisStudents.length > 1
            && checkKeyInObject(props.hisStudents[0], 'date', 'bool')
            && typeof props.hisStudents[0].date === 'string'
            && props.hisStudents[0].date.includes('[')
            && props.hisStudents[0].date.includes(']') ? [{
              name: "Active",
              //type: "column",
              data: [Number(props.hisStudents[0].date.replace('[', '').replace(']', ''))]
            },
            {
              name: "Total",
              // type: "column",
              data: [Number(props.hisStudents[1].date.replace('[', '').replace(']', ''))]
            }]
            :
            [
              {
                name: "Active",
                //type: "column",
                data: []
              },
              {
                name: "Total",
                // type: "column",
                data: []
              }
            ]}
          type="bar"
        />
      </Col>
      <Col lg="6">
        <h3 className="m-3 text-center border-bottom">{props.revHeading}</h3>
        {/* {console.log('checking percentage',Number(props.hisReviews['5Rating_per']).toFixed(2))} */}
        <HisReviewsView style={{    height:"350px"}} title="Reviews" hisReviews={reviews} data={[{ star: 5, percent: `${Number(reviews['5Rating_per']).toFixed(2)}%`, progress: `${reviews['5Rating_per']}` }, { star: 4, percent:`${Number(reviews['4Rating_per']).toFixed(2)}%`, progress: `${reviews['4Rating_per']}` }, { star: 3, percent: `${Number(reviews['3Rating_per']).toFixed(2)}%`, progress: `${reviews['3Rating_per']}` }, { star: 2, percent: `${Number(reviews['2Rating_per']).toFixed(2)}%`, progress: `${reviews['2Rating_per']}` }, { star: 1, percent: `${Number(reviews['1Rating_per']).toFixed(2)}%`, progress: `${reviews['1Rating_per']}` }]} />
      </Col>
    </Row>
  );
};
export default InstructorStudentReview;
