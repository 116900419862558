import { BaseURL } from "../../config";

export const EditProfileApi = {
  //   GetProfile: async () => {
  //     const url = `${BaseURL}/profile`;
  //     const params = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     };
  //     try {
  //       const response = await fetch(url, params);
  //       return await response.json();
  //     } catch (error) {
  //       console.log("Error return" + error);
  //     }
  //   },
  //   Profile: async (payload) => {
  //     const url = `${BaseURL}/profile`;
  //     const params = {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: payload,
  //     };
  //     try {
  //       const response = await fetch(url, params);
  //       const responseJson = await response.json();
  //       console.log('responseJson===>', responseJson)
  //       return responseJson;
  //     } catch (error) {
  //       console.log("Error return" + error);
  //     }
  //   },

  //   GetProgressDetail: async (id) => {
  //     const url = `${BaseURL}/class-progress?slot_id=${id}`;
  //     const params = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     };
  //     try {
  //       const response = await fetch(url, params);
  //       return await response.json();
  //     } catch (error) {
  //       console.log("Error return" + error);
  //     }
  //   },

  //   GetProgressCard: async () => {
  //     const url = `${BaseURL}/progress-card`;
  //     const params = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     };
  //     try {
  //       const response = await fetch(url, params);
  //       return await response.json();
  //     } catch (error) {
  //       console.log("Error return" + error);
  //     }
  //   },
  //   setDeviceInfo: async (payload) => {
  //     // console.log('getToken()', getToken())
  //     // console.log('payload', payload)
  //     // const formData = new FormData();
  //     // formData.append('device_token', payload.device_token.data)
  //     // formData.append('device_type', payload.device_type)
  //     console.log('formDataformDataformData, ', payload)
  //     const url = `${BaseURL}/account-device`;
  //     const params = {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         // "Content-Type": "multipart/form-data",
  //         "Content-Type": "application/json; charset=utf-8",
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify(payload),
  //     };
  //     try {
  //       const response = await fetch(url, params);
  //       const responseJson = await response.json();
  //       console.log("setDeviceInfo ===> responseJson", responseJson);
  //       return responseJson;
  //     } catch (error) {
  //       console.log("Error return" + error);
  //     }
  //   },
  //   CreditsNotification: async (payload) => {
  //     const url = `${BaseURL}/credits-notification`;
  //     const params = {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json; charset=utf-8",
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify(payload),
  //     };
  //     try {
  //       const response = await fetch(url, params);
  //       const responseJson = await response.json();
  //       return responseJson;
  //     } catch (error) {
  //     }
  //   },
  GetLicenseDetailsPart1: async licensePlate => {
    if (licensePlate) {
      licensePlate.toUpperCase();
    }
    const url = `https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${licensePlate.toUpperCase()}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: {
        $$app_token: "kcnGE4uMR1498d2EjTfDYOx2C"
      }
    };
    if (licensePlate) {
      try {
        const response = await fetch(url, params);
        return await response.json();
      } catch (error) {
        //console.log("API Error return" + error);
      }
    }
  },
  GetLicenseDetailsPart2: async licensePlate => {
    if (licensePlate) {
      licensePlate.toUpperCase();
    }
    const url = `https://opendata.rdw.nl/resource/8ys7-d773.json?kenteken=${licensePlate.toUpperCase()}`;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
        // Authorization: `Bearer ${getToken()}`,
      },
      data: {
        // "$limit" : 5000,
        $$app_token: "kcnGE4uMR1498d2EjTfDYOx2C"
      }
    };

    try {
      const response = await fetch(url, params);
      return await response.json();
    } catch (error) {
      // console.log ("API Error return" + error);
    }
  }
};
