import React, { Component } from "react";
import { Popup, Button as SButton, Grid } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  FormSelect
} from "shards-react";
import axios from "axios";
import PageTitle from "./../../components/common/PageTitle";
import InstructorSlotsModal from "./../../components/InstructorSlotsModal/InstructorSlotsModal";
import Calendar from "./../../components/common/Calendar";
import StudentList from "./../../components/common/StudentList";
import TotalStudentPopulation from "./instructor/total-student-population";
import { checkArrayLength, checkKeyInObject, getText } from "../../utils/utils";
import ChevronLeftSharpIcon from "@material-ui/icons/ChevronLeftSharp";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import { connect } from "react-redux";
import { BASE_URL } from "../../config";
import { checkToken } from "../../store/actions/validity";
import EditProfileModal from "../../components/EditProfileModal/EditProfileModal";
import ChangePasswordModal from "../../components/ChangePasswordModal/ChangePasswordModal";

const turnoverData = {
  series: [
    {
      name: "Clickdrive Bonus",
      type: "column",
      data: [0, 0, 0, 0, 0]
    },
    {
      name: "Lesson Revenues",
      type: "column",
      data: [40, 20, 30, 10, 50]
    }
  ],
  options: {
    colors: ["#DD4132", "#00539C"],
    dataLabels: {
      enabled: false
    },
    chart: {
      type: "column",
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    stroke: {
      width: [4, 1, 1]
    },
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     options: {
    //       legend: {
    //         position: "bottom",
    //         offsetX: -10,
    //         offsetY: 0
    //       }
    //     }
    //   }
    // ],
    xaxis: {
      type: "string",
      //categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]
      categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
    },
    yaxis: [
      {
        show: true,
        seriesName: "Clickdrive Bonus",
        axisTicks: {
          show: true
        },
        // min: 0,
        // max: 1500,
        tickAmount: 3,
        labels: {
          formatter: val => `€ ${val}`
        }
      },
      {
        seriesName: "Lesson Revenues",
        // min: 0,
        // max: 60,
        tickAmount: 3,
        opposite: true,
        axisTicks: {
          show: true
        }
      }
    ],
    legend: {
      position: "top",
      offsetY: 0
    },
    fill: {
      opacity: 1
    }
  }
};

let YearArray = [];

const DutchMonth = [
  "januari-",
  "februari",
  "maart",
  "april",
  "mei",
  "juni-",
  "juli-",
  "augustus",
  "september",
  "oktober",
  "November",
  "december"
];

const MonthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

class InstructorDashboard extends Component {
  state = {
    turnoverData: [],
    activeStudents: [],
    agendaServices: [],
    period: "week",
    invoiceMonth: "Jan",
    invoiceYear: 0,
    monthCounter: 0,
    yearCounter: 0,
    popupState: false,
    invoiceDate: "",
    validYear: [],
    open: false,
    slot_time: null
    // selectedTimeEnd: new Date(),
    // selectedTimeStart: new Date()
  };

  // updatedDateStart = date => {
  //   this.setState({
  //     selectedTimeStart: date
  //   });
  // };

  // updatedDateEnd = date => {
  //   this.setState({
  //     selectedTimeEnd: date
  //   });
  // };

  closeInstructorSlotModal = () => {
    this.setState({
      open: false
    });
  };

  openModal = dateClicked => {
    const d = new Date(dateClicked.date);
    // 2021-01-20 clicked date
    const d_format = new Date(
      `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    );

    // console.log(d_format, "full format");

    var d3 = new Date(d_format);
    d3 = d3.toISOString();
    d3 = d3.toString().split("T");
    var date = d3[0];
    d3 = d3[1].split(".");
    d3 = date + " " + d3[0];
      console.log('d3', d3)
    // 2021-02-1 current date
    const d2 = new Date();
    const d2_format = new Date(
      `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`
    );
    // console.log(d_format, d2_format);

    d_format > d2_format &&
      this.setState({
        open: true,
        slot_time: d3
      });
  };

  handleOpen = () => {
    this.setState({ popupState: true });
  };

  handleClose = () => {
    this.setState({ popupState: false });
  };

  sendInvoice = () => {
    let token = localStorage.getItem("token-click-drive");
    const PrintInvoiceHeader = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8"
    };
    const payload = {
      date: `${this.state.invoiceYear}-${this.state.monthCounter + 1}`
    };
    //const payload={date:'2020-10'}
    axios
      .post(
        `${BASE_URL}/send-instructor-revenue-letter`,
        JSON.stringify(payload),
        { headers: PrintInvoiceHeader }
      )
      .then(res => {
        // console.log("invoice success", res);
        toast.info(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .catch(err => {
        console.log("invoice err", err);
      });
  };

  handlePrint = () => {
    let month = getText(
      `${MonthsArray[this.state.monthCounter]}`,
      `${DutchMonth[this.state.monthCounter]}`,
      this.props.isEnglish
    );

    //console.log(month);
    this.setState(
      { popupState: false, invoiceDate: `${month}, ${this.state.invoiceYear}` },
      () => this.sendInvoice()
    );
  };

  getInstructorData = () => {
    let token = localStorage.getItem("token-click-drive");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${BASE_URL}/admin/instructor/board`)
      .then(response => {
        if (
          checkKeyInObject(
            response,
            "data.result.turnover_invoices",
            "bool",
            false
          ) &&
          checkKeyInObject(
            response,
            "data.result.agenda_services",
            "bool",
            false
          ) &&
          checkKeyInObject(
            response,
            "data.result.active_students",
            "bool",
            false
          )
        ) {
          this.setState(
            {
              turnoverData: response.data.result.turnover_invoices,
              agendaServices: response.data.result.agenda_services,
              activeStudents: response.data.result.active_students
            }
            // () => {
            //   console.log(this.state, "after set state");
            // }
          );
        }
      })
      .catch(err => {
        console.log(err, "1");
      });
  };

  getInvoiceData = () => {
    let token = localStorage.getItem("token-click-drive");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .post(`${BASE_URL}/admin/instructor/board/turnover-invoices`, {
        type: this.state.period
      })
      .then(response => {
        if (
          checkKeyInObject(response, "data.result", "bool", false) &&
          checkArrayLength(response.data.result)
        ) {
          this.setState({ turnoverData: [...response.data.result] });
        }
      })
      .catch(err => {
        console.log(err, "2");
      });
  };

  componentDidMount() {
    this.getInstructorData();
    checkToken(this.props.history);
    let date = new Date();
    //set date manually to test
    //let date = new Date('2025-12-01')
    let year = date.getFullYear();
    let month = date.getMonth();
    let yearCounter = 0;
    for (let i = 2020; i <= year; i++) {
      YearArray.push(i);
    }
    YearArray.map((record, index) => {
      if (record === year) {
        yearCounter = index;
      }
    });

    this.setState({
      invoiceYear: year,
      invoiceMonth: MonthsArray[month - 1],
      monthCounter: month - 1,
      yearCounter
    });
  }

  shiftToLeft = () => {
    //console.log("date", this.state.monthCounter, this.state.invoiceYear);
    let newMonthCounter;
    let newYearCounter;
    if (this.state.yearCounter === 0 && this.state.monthCounter <= 5) return;
    if (this.state.monthCounter === 0) {
      newMonthCounter = 11;
      newYearCounter = this.state.yearCounter - 1;
    } else {
      newMonthCounter = this.state.monthCounter - 1;
      newYearCounter = this.state.yearCounter;
    }
    this.setState({
      invoiceMonth: MonthsArray[newMonthCounter],
      monthCounter: newMonthCounter,
      invoiceYear: YearArray[newYearCounter],
      yearCounter: newYearCounter
    });
  };

  shiftToRight = () => {
    let newMonthCounter;
    let newYearCounter;
    let date = new Date();
    //let date = new Date('2025-12-01')
    let month = date.getMonth();

    if (
      this.state.yearCounter === YearArray.length - 1 &&
      this.state.monthCounter === month - 1
    )
      return;
    if (this.state.monthCounter === 11) {
      newMonthCounter = 0;
      newYearCounter = this.state.yearCounter + 1;
    } else {
      newMonthCounter = this.state.monthCounter + 1;
      newYearCounter = this.state.yearCounter;
    }
    this.setState({
      invoiceMonth: MonthsArray[newMonthCounter],
      monthCounter: newMonthCounter,
      invoiceYear: YearArray[newYearCounter],
      yearCounter: newYearCounter
    });
  };

  render() {
    // console.log("edit", this.state.agendaServices);

    const { isEnglish, slotResult } = this.props;
    // console.log(this.props.slotResult, "in dashboard");

    const agendaSlots = [...this.state.agendaServices, ...slotResult];
    // console.log(agendaSlots, "combine slots");

    return (
      <Container fluid className="main-content-container px-4">
        {this.props.EditProfileOpen ? <EditProfileModal /> : null}
        {this.props.PasswordChangeOpen ? <ChangePasswordModal /> : null}

        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={getText(
              "Your Clickdrive Dashboard",
              "Jouw Clickdrive Dashboard",
              isEnglish
            )}
            subtitle={getText("Instructor", "Instructeur", isEnglish)}
            className="text-sm-left mb-3"
          />
        </Row>
        <Row>
          <Col lg="6">
            <Card small className="mb-2">
              <CardHeader className="border-bottom">
                <h3 className="m-0  text-center">
                  {getText(
                    "Your turnover & invoices",
                    "Jouw omzet en facturen",
                    isEnglish
                  )}
                </h3>
              </CardHeader>
              <CardBody>
                <TotalStudentPopulation
                  options={
                    this.state.period == "week"
                      ? {
                          ...turnoverData.options,
                          xaxis: {
                            type: "string",
                            categories: [
                              "Week -4",
                              "Week -3",
                              "Week -2",
                              "Week -1",
                              "Current Week"
                            ]
                          }
                        }
                      : {
                          ...turnoverData.options,
                          xaxis: {
                            type: "string",
                            categories: [
                              "Month -4",
                              "Month -3",
                              "Month -2",
                              "Month -1",
                              "Current Month"
                            ]
                          }
                        }
                  }
                  // series={this.state.turnoverData}
                  series={
                    checkArrayLength(this.state.turnoverData)
                      ? [this.state.turnoverData[1]]
                      : []
                  }
                  type="bar"
                  height={350}
                />
              </CardBody>
              <CardFooter className="border-top pl-2">
                <Row>
                  <Col xs="3">
                    <FormSelect
                      size="sm"
                      value={this.state.period}
                      style={{ maxWidth: "150px" }}
                      onChange={e => {
                        this.setState({ period: e.currentTarget.value }, () => {
                          this.getInvoiceData();
                        });
                      }}
                    >
                      <option value="week">Week</option>
                      <option value="month">
                        {getText("Month", "Maand", isEnglish)}
                      </option>
                    </FormSelect>
                  </Col>
                  <Col xs="9">
                    {/* <Button theme="primary" className="mb-2 mr-1" onClick={() => { }}>
                        {getText('Print your invoices', 'Jouw facturen uitdraaien', isEnglish)}
                      </Button> */}
                    <Popup
                      position="top left"
                      size="small"
                      on="click"
                      pinned
                      open={this.state.popupState}
                      onOpen={this.handleOpen}
                      trigger={
                        <Button
                          theme="primary"
                          className="mb-2 mr-1"
                          onClick={this.handleClose}
                        >
                          {getText(
                            "Print your invoices",
                            "Jouw facturen uitdraaien",
                            isEnglish
                          )}
                        </Button>
                      }
                    >
                      <Popup.Content>
                        <Container style={{ width: "350px" }}>
                          <Row noGutters>
                            <Col
                              lg="1"
                              md="1"
                              sm="1"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.shiftToLeft()}
                            >
                              <ChevronLeftSharpIcon />
                            </Col>
                            <Col sm="5" lg="5" md="5">
                              <FormSelect
                                size="sm"
                                value={this.state.invoiceMonth}
                                style={{ width: "100%" }}
                                onChange={e => {
                                  MonthsArray.map((record, index) => {
                                    //let date = new Date('2025-12-01')
                                    let date = new Date();
                                    let month = date.getMonth();
                                    // console.log(
                                    //   "year counter",
                                    //   this.state.yearCounter
                                    // );
                                    // console.log(
                                    //   "year arry max value",
                                    //   YearArray.length - 1
                                    // );
                                    // console.log("selected month", index);
                                    // console.log("current month", month - 1);
                                    if (record === e.currentTarget.value) {
                                      if (
                                        (this.state.yearCounter === 0 &&
                                          index < 5) ||
                                        (this.state.yearCounter ===
                                          YearArray.length - 1 &&
                                          index > month - 1)
                                      ) {
                                        return;
                                      } else {
                                        this.setState({
                                          invoiceMonth: e.currentTarget.value,
                                          monthCounter: index
                                        });
                                      }
                                    }
                                  });
                                }}
                              >
                                {/* {MonthsArray.map((month) => {
                                    return (
                                      <option value={month}>{month}</option>
                                    );
                                  })} */}
                                <option value="January">
                                  {getText("January", "januari-", isEnglish)}
                                </option>
                                <option value="February">
                                  {getText("February", "februari", isEnglish)}
                                </option>
                                <option value="March">
                                  {getText("March", "maart", isEnglish)}
                                </option>
                                <option value="April">
                                  {getText("April", "april", isEnglish)}
                                </option>
                                <option value="May">
                                  {getText("May", "mei", isEnglish)}
                                </option>
                                <option value="June">
                                  {getText("June", "juni-", isEnglish)}
                                </option>
                                <option value="July">
                                  {getText("July", "juli-", isEnglish)}
                                </option>
                                <option value="August">
                                  {getText("August", "augustus", isEnglish)}
                                </option>
                                <option value="September">
                                  {getText("September", "september", isEnglish)}
                                </option>
                                <option value="October">
                                  {getText("October", "oktober", isEnglish)}
                                </option>
                                <option value="November">
                                  {getText("November", "November", isEnglish)}
                                </option>
                                <option value="December">
                                  {getText("December", "december", isEnglish)}
                                </option>
                              </FormSelect>
                            </Col>

                            <Col sm="5" lg="5" md="5">
                              <FormSelect
                                size="sm"
                                value={this.state.invoiceYear}
                                style={{ width: "100%" }}
                                onChange={e => {
                                  YearArray.map((record, index) => {
                                    //let date = new Date('2025-12-01')
                                    let date = new Date();
                                    let month = date.getMonth();
                                    if (
                                      record === Number(e.currentTarget.value)
                                    ) {
                                      if (
                                        this.state.monthCounter < 5 &&
                                        index === 0
                                      ) {
                                        this.setState({
                                          invoiceMonth: MonthsArray[5],
                                          monthCounter: 5,
                                          invoiceYear: Number(
                                            e.currentTarget.value
                                          ),
                                          yearCounter: index
                                        });
                                      } else if (
                                        this.state.monthCounter >= month - 1 &&
                                        index >= YearArray.length - 1
                                      ) {
                                        this.setState({
                                          invoiceMonth: MonthsArray[month - 1],
                                          monthCounter: month - 1,
                                          invoiceYear: Number(
                                            e.currentTarget.value
                                          ),
                                          yearCounter: index
                                        });
                                      } else {
                                        this.setState({
                                          invoiceYear: Number(
                                            e.currentTarget.value
                                          ),
                                          yearCounter: index
                                        });
                                      }
                                    }
                                  });
                                }}
                              >
                                {YearArray.map(year => {
                                  return <option value={year}>{year}</option>;
                                })}
                              </FormSelect>
                            </Col>
                            <Col
                              lg="1"
                              md="1"
                              sm="1"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.shiftToRight()}
                            >
                              <ChevronRightSharpIcon />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12" className="text-center mt-2">
                              <Button
                                theme="secondary"
                                className="mx-2"
                                onClick={() =>
                                  this.setState({
                                    popupState: false,
                                    invoiceDate: ""
                                  })
                                }
                              >
                                {getText("Cancel", "Annuleren", isEnglish)}
                              </Button>
                              <Button
                                className="mx-2"
                                onClick={this.handlePrint}
                              >
                                {getText("Done", "Gedaan", isEnglish)}
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Popup.Content>
                    </Popup>
                    {/* {this.state.invoiceDate ? <span>{this.state.invoiceDate}</span> : null} */}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="6">
            <Card small className="mb-2">
              <CardHeader className="border-bottom">
                <h3 className="m-0  text-center">
                  {getText(
                    "Your active Clickdrive students",
                    "Jouw actieve Clickdrive studenten",
                    isEnglish
                  )}
                </h3>
              </CardHeader>
              {/* {console.log("active data", this.state.activeStudents)} */}
              <CardBody className="h-470 overflow-auto">
                <StudentList data={this.state.activeStudents} />
              </CardBody>
            </Card>
          </Col>

          <Col lg="12" className="mb-2">
            <Card small>
              <CardHeader className="border-bottom">
                <h3 className="m-0  text-center">
                  {getText(
                    "Your agenda and services",
                    "Jouw agenda en diensten",
                    isEnglish
                  )}
                </h3>
              </CardHeader>
              <CardBody>
                {/* {console.log("agenda", this.state.agendaServices)} */}
                {/* {console.log("slots ", slotResult)} */}
                <Calendar
                  defaultView="dayGridMonth"
                  events={agendaSlots}
                  // events={this.state.agendaServices}
                  // events={[
                  //   {
                  //     date: "2021-02-01",
                  //     title: null,
                  //     start: "2021-02-01T19:29:30"
                  //   }
                  // ]}
                  //set slots modal
                  OpenModalClick={this.openModal}
                  eventTimeFormat={{
                    // like '14:30:00'
                    hour: "2-digit",
                    minute: "2-digit",
                    // second: '2-digit',
                    hour12: false
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div>
          <InstructorSlotsModal
            datetosend_format={this.state.slot_time}
            // updatedStartTime={date => this.updatedDateStart(date)}
            // updatedEndTime={date => this.updatedDateEnd(date)}
            closeModal={this.closeInstructorSlotModal}
            //passing state in child
            open={this.state.open}
          />
        </div>
      </Container>
    );
  }
}

InstructorDashboard.propTypes = {};

InstructorDashboard.defaultProps = {};

const mapDispatchToProps = dispatch => {
  return {
    // login: (...args) => dispatch(AuthActions.login(...args)),
  };
};
const mapStateToProps = state => ({
  isEnglish: state.authReducer.isEnglish,
  EditProfileOpen: state.EditProfileReducer.editStatus,
  PasswordChangeOpen: state.changePasswordReducer.modalStatus,
  slotResult: state.adminInstructorReducer.slotResult
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(InstructorDashboard)
);
