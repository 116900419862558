
import has from 'lodash.has';
import store from '../store';

// utility function to check any key in object
export const checkKeyInObject = (obj, key, returnType = 'value', notFoundValue, isDomElement) => {
    try {
        /* eslint-disable no-unused-expressions */
        let isExists = false;
        if (key && (typeof key === 'string' || typeof key === 'number') && obj) {
            if (typeof key === 'string' && (key.includes('current') || isDomElement)) {
                let keys = key.split(".");
                let val = obj;
                isExists = true;
                keys.some(k => {
                    if (isExists && val[k]) {
                        val = val[k];
                    } else {
                        isExists = false;
                        return true;
                    }
                })
            } else {
                isExists = has(obj, key);
            }
        }
        if (returnType === 'value') {
            let valRet = notFoundValue;
            if (isExists) {
                if (obj[key] || typeof key === 'number') {
                    valRet = obj[key];
                } else if (typeof key === 'string') {
                    let keys = key.split(".");
                    let val = obj;
                    keys.forEach(k => {
                        val = val[k];
                    })
                    valRet = val;
                }
            }
            return valRet;
        }
        return isExists;
    } catch (error) {
        console.error(error);
    }
}

export const getText = (english = '', dutch = '', isEnglish = null) => {
    if (isEnglish === null) {
        if (checkKeyInObject(store.getState(), 'authReducer.isEnglish', 'value', false) === true) {
            return english;
        }
        return dutch;
    }
    else {
        if (isEnglish) {
            return english;
        }
        return dutch;
    }
}

export function checkArrayLength(value) {
    return value && Array.isArray(value) && value.length > 0
}

export function formatDate(date = '') {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function JSONToCSVConvertor(JSONData, fileName = '', ReportTitle = '', ShowLabel, delimitter = ',') {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';
    //Set Report title in first row or line

    CSV += ReportTitle + '\r\n\n';

    //This condition will generate the Label/Header
    if (ShowLabel) {
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {

            //Now convert each value to string and comma-seprated
            row += index + ',';
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        var row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
            row += '"' + arrData[i][index] + `"${delimitter}`;
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV == '') {
        // alert("Invalid data");
        return;
    }

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension    

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const convertToDate = (date_time = '') => {
    if (date_time) {
        const dateTime = new Date(date_time);
        return ('0' + dateTime.getDate()).slice(-2) + '.' + ('0' + (dateTime.getMonth() + 1)).slice(-2) + '.' + dateTime.getFullYear();
    }
    return date_time;
};