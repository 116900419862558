import React from "react";
import { Container, Button } from "shards-react";

const Table = props => {
    const getTableData = () => {
        return props.data.map((row, rowIndex) => {
            // console.log('map, m', row)
            return (
                <tr key={rowIndex}>
                    {Object.values(row).map((value, index) => {
                        return (
                            <td className="border" key={index}>
                                { value === "" ? "-" : value }
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    return (
        <Container fluid className="main-content-container px-2">
            <table className="table mb-1 border small">
                <tbody>
                    {getTableData()}
                </tbody>
            </table>
        </Container>
    );
};

export default Table;
