import { ActionTypes } from '../constants';
import { checkKeyInObject } from '../../utils/utils';
const initialState = {
    isAuthenticated: localStorage.getItem('token-click-drive') ? true : false,
    user: localStorage.getItem('user-click-drive') ? JSON.parse(localStorage.getItem('user-click-drive')) : {},
    isEnglish: false,
    isLoading: false,
    loginErrorMessage: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.LOGIN_SUCCESS:
            if (action.payload.token) {
                localStorage.setItem('token-click-drive', action.payload.token);
            }
            if (action.payload.user) {
                localStorage.setItem('user-click-drive', JSON.stringify({ ...action.payload.user, type: checkKeyInObject(action.payload.user, 'type') == 3 ? 0 : 1 }));
            }
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: { ...action.payload.user, type: checkKeyInObject(action.payload.user, 'type') == 3 ? 0 : 1 },
            };
        case ActionTypes.LOGIN_FAILURE:
            let loginErrorMessage = null;
           // console.log('check payload',action.payload.response)
            if (action.payload.response) {
                if (action.payload.response.status === 401) {
                    loginErrorMessage = 'Login failed: Invalid UserId or Password';
                   
                }
                else {
                    loginErrorMessage = 'Login failed: Some error occured';
                }
            }
            // if (action.payload) {
            //     loginErrorMessage = action.payload;
            // }
            else {
                loginErrorMessage = 'Login failed: Check your internet connection';
            }
             //console.log('error login msg check',loginErrorMessage)
            return {
                ...state,
                loginErrorMessage,
                isLoading: false,
            };
        case ActionTypes.CLEAR_LOGIN_ERROR_MESSAGE:
            return {
                ...state,
                loginErrorMessage: null,
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
            };
        case ActionTypes.TOGGLE_LANGUAGE:
            return {
                ...state,
                isEnglish: !state.isEnglish,
            };
        default:
            return state;
    }
}
