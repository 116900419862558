export default function () {
  // viewOnly: 0  (Visible for Admin only)
  // viewOnly: 1  (Visible for Instructor only)
  // viewOnly: 01  (Visible for Both)
  return [
    {
      title: "Management View",
      to: "/admin-management",
      viewOnly: '0',
      htmlBefore: '<i class="material-icons">assessment</i>',
      htmlAfter: ""
    },
    {
      title: "Instructor View",
      to: "/admin-instructor",
      viewOnly: '0',
      htmlBefore: '<i class="material-icons">assignment_ind</i>',
      htmlAfter: ""
    },
    {
      title: "Student View",
      to: "/admin-student",
      viewOnly: '0',
      htmlBefore: '<i class="material-icons">group</i>',
      htmlAfter: ""
    },
    {
      title: "Instructor Dashboard",
      to: "/instructor-board",
      viewOnly: '1',
      htmlBefore: '<i class="material-icons">assessment</i>',
      htmlAfter: ""
    },
    {
      title: "Wallet",
      to: "/admin-wallet",
      viewOnly: '0',
      htmlBefore: '<i class="material-icons">account_balance_wallet</i>',
      htmlAfter: ""
    },
    {
      title: "Referral",
      to: "/admin-referral",
      viewOnly: '0',
      htmlBefore: '<i class="material-icons">send_to_mobile</i>',
      htmlAfter: ""
    },
    {
      title: "Notification",
      to: "/admin-notificaiton",
      viewOnly: '0',
      htmlBefore: '<i class="material-icons">circle_notifications</i>',
      htmlAfter: ""
    }
    // {
    //   title: "Other Page",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/errors"
    // }
  ];
}
