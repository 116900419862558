import React from "react";
import { Row, Col } from "shards-react";
import AdminInstructorChart from "./registration-view/instructor-chart";
import AdminStudentChart from "./registration-view/student-chart";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const chartStyles = {
  legend: {
    position: "right",
    offsetY: 40
  },
  fill: {
    opacity: 1
  },
  chart: {
    type: "bar",
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ["#00539C"],
};

const getInstructorOptions = (duration) => {
  const instructorOptions = {
    title: {
      text: "Instructors",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "grey"
      }
    },
    ...chartStyles,
  }
  switch (duration) {
    case 'week':
      return {
        ...instructorOptions,
        yaxis: {
          // min: 0,
          // max: 20,
          // tickAmount: 5,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
        },
      }
    case 'month':
      return {
        ...instructorOptions,
        yaxis: {
          // min: 0,
          // max: 20,
          // tickAmount: 5,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: ["Month -4", "Month -3", "Month -2", "Month -1", "Current Month"]
        },
      }
    case 'year':
      const d = new Date();
      return {
        ...instructorOptions,
        yaxis: {
          // min: 0,
          // max: 20,
          // tickAmount: 5,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: [`${d.getFullYear() - 4}`, `${d.getFullYear() - 3}`, `${d.getFullYear() - 2}`, `${d.getFullYear() - 1}`, `${d.getFullYear()}`]
        },
      }
    case 'selection':
      return {
        ...instructorOptions,
        yaxis: {
          // min: 0,
          // max: 150,
          // tickAmount: 3,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: ['', '', '', '', '']
        },
      }
    default:
      return {
        ...instructorOptions,
        xaxis: {
          type: "string",
          categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
        },
      }
  }
}

const getStudentOptions = (duration) => {
  const studentOptions = {
    title: {
      text: "Students",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "grey"
      }
    },
    ...chartStyles,
  }
  switch (duration) {
    case 'week':
      return {
        ...studentOptions,
        yaxis: {
          // min: 0,
          // max: 150,
          // tickAmount: 3,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
        },
      }
    case 'month':
      return {
        ...studentOptions,
        yaxis: {
          // min: 0,
          // max: 150,
          // tickAmount: 3,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: ["Month -4", "Month -3", "Month -2", "Month -1", "Current Month"]
        },
      }
    case 'year':
      const d = new Date();
      return {
        ...studentOptions,
        yaxis: {
          // min: 0,
          // max: 150,
          // tickAmount: 3,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: [`${d.getFullYear() - 4}`, `${d.getFullYear() - 3}`, `${d.getFullYear() - 2}`, `${d.getFullYear() - 1}`, `${d.getFullYear()}`]
        },
      }
    case 'selection':
      return {
        ...studentOptions,
        yaxis: {
          // min: 0,
          // max: 150,
          // tickAmount: 3,
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "string",
          categories: ['', '', '', '', '']
        },
      }
    default:
      return {
        ...studentOptions,
        xaxis: {
          type: "string",
          categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
        },
      }
  }
}

const RegistrationView = ({ registrationPeriod, instructorRegistrations, studentRegistrations }) => {
  return (
    <Row>
      <Col lg="6">
        <AdminInstructorChart
          height="200px"
          series={[{
            name: "Instructors",
            data: [...instructorRegistrations]
          }]}
          options={getInstructorOptions(registrationPeriod)}
          // series={getInstructorData(registrationPeriod)}
          type="bar"
        />
      </Col>
      <Col lg="6">
        <AdminStudentChart
          height="200px"
          options={getStudentOptions(registrationPeriod)}
          series={[{
            name: "Students",
            data: [...studentRegistrations]
          }]}
          // series={getStudentData(registrationPeriod)}
          type="bar"
        />
      </Col>
    </Row>
  );
};
export default RegistrationView;
