import axios from 'axios';
import { BASE_URL } from '../../config';
 //Checks token validity
export const checkToken=async(history)=>{
    const token=localStorage.getItem('token-click-drive');
    // const token="abdhwndjenc"
    // console.log('current token',token)
    if(token){
    const response=await axios.post(`${BASE_URL}/check-token-validity`,{token});
    // console.log('token response', response);
    if(!response.data.response){
        localStorage.clear();
        history.push('/login')
    }

    }
    
}

