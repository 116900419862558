import React, { useState, useEffect, Component } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormSelect,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput,
    Form,
    FormTextarea,
    FormCheckbox,
    FormRadio
} from "shards-react";
import { checkToken } from "../../store/actions/validity"
import axios from 'axios';
import PageTitle from "./../../components/common/PageTitle";
import NotificationTable from "./super-admin/management-view/notification-list-table-view";
import { getText, checkArrayLength, JSONToCSVConvertor, formatDate, checkKeyInObject } from '../../utils/utils';
import { connect } from "react-redux";
import AdminManageActions from "../../store/actions/adminManageActions";
import ContentLoader from "../../components/common/ContentLoader";
import { BASE_URL } from "../../config";
import AdminStudentActions from "../../store/actions/adminStudentActions";
import * as _ from "lodash";


const letters = /^[A-Za-z]+$/;
const token = localStorage.getItem('token-click-drive');
axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

const BUTTON_OBJ = {
    INSTRUCTOR: "instructors",
    STUDENT: "students",
    BOTH: "all"
}

export class AdminNotificationView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            studentNumber: "",
            instructorNameState: "",
            notificationMsg: "",
            notificationTitle: "",
            selectedEvent: null,
            eventsList: [],
            activeButton: null,
            pickedInstructor: null,
            instructorsList: [],
            filteredList: [],
            studentNameState: "",
            pickedStudent: null,
            studentList: [],
            filteredStudentList: [],
            loading: false
        }
    }

    componentDidMount() {
        this._fetchSentNotification();
        axios.get(`${BASE_URL}/get-all-instructors?web=1`)
        .then((response) => {
            if (response && response.data && response.data.response) {
                if (response.data.result && checkArrayLength(response.data.result)) {
                    this.setState({ instructorsList: response.data.result })
                }
            } else this.setState({ instructorsList: [] })
        })
        .catch((err) => {})
        this.props.getAllStudents();
    }

    _fetchSentNotification() {
        axios.get(`${BASE_URL}/admin/management/get-sent-notifications`)
        .then((response) => {
            if (checkKeyInObject(response, 'data.result', 'bool', false) && checkArrayLength(response.data.result)) {
                this.setState({ eventsList: response.data.result })
            }
        })
        .catch((err) => {
        })
    }

    _assignReferralReward() {
        const { notificationMsg, notificationTitle, activeButton, pickedStudent, pickedInstructor } = this.state;
        const targetUsers = pickedStudent ? pickedStudent : pickedInstructor;
        if( _.isEmpty(notificationMsg) && _.isEmpty(notificationTitle) ) {
            alert('Please enter notification message and title');
            return;
        } else if( _.isEmpty(targetUsers) && _.isEmpty(activeButton) ) {
            alert('Please select users');
            return;
        } else {
            this.setState({ loading: true })
            const payload = {
                notification_title: notificationTitle,
                notification_message: notificationMsg,
                target_user: activeButton ? activeButton : targetUsers && targetUsers.user_id,
                target_user_name: activeButton ? activeButton : targetUsers && targetUsers.name ? targetUsers.name : targetUsers.instructor_name
            }
            axios.post(`${BASE_URL}/admin/management/send-notification`, {...payload})
            .then((response) => {
                if (response.data && response.data.response ) {
                    this._fetchSentNotification();
                }
                this.setState({ loading: false, notificationMsg: '', notificationTitle: '' })
            })
            .catch((err) => {
                this.setState({ loading: false })
            })
        }
    }

    _onAudeinceSelect(targetAud) {
        if( this.state.activeButton === targetAud ) {
            this.setState({ activeButton: null, pickedInstructor: null, pickedStudent: null })
        } else this.setState({ activeButton: targetAud, pickedInstructor: null, pickedStudent: null })
    }

    searchIntructors(instructorName) {
        const { pickedInstructor, instructorsList } = this.state;
        if (pickedInstructor && typeof pickedInstructor != "undefined") {
            this.setState({ pickedInstructor: null, instructorNameState: "", filteredList: [] })
        } else if (instructorName == "" || instructorName.match(letters)) {
            this.setState({ instructorNameState: instructorName })
            setTimeout(() => {
                if (instructorName.match(letters) && instructorsList && instructorsList.length > 0) {
                    const filteredOptions = instructorsList.filter(
                        (option) => option.instructor_name.toLowerCase().indexOf(instructorName.toLowerCase()) > -1
                    );
                    this.setState({ filteredList: filteredOptions })
                }
            }, 100);
        }
    }

    searchStudent(studentName) {
        const { pickedStudent } = this.state;
        const { allStudents } = this.props;
        if (pickedStudent && typeof pickedStudent != "undefined") {
            this.setState({ pickedStudent: null, studentNameState: "", filteredStudentList: [] })
        } else if (studentName == "" || studentName.match(letters)) {
            this.setState({ studentNameState: studentName })
            setTimeout(() => {
                if (studentName.match(letters) && allStudents && allStudents.length > 0) {
                    const filteredOptions = allStudents.filter(
                        (option) => option.name && option.name.toLowerCase().indexOf(studentName.toLowerCase()) > -1
                    );
                    this.setState({ filteredStudentList: filteredOptions })
                }
            }, 100);
        }
    }

    selectInstructor(instructorOBJ) {
        this.setState({
            instructorNameState: instructorOBJ.instructor_name, filteredList: [], pickedInstructor: instructorOBJ,
            studentNameState: "", filteredStudentList: [], pickedStudent: null, activeButton: null
        })
    }

    selectStudent(studentOBJ) {
        this.setState({
            instructorNameState: "", filteredList: [], pickedInstructor: null,
            studentNameState: studentOBJ.name, filteredStudentList: [], pickedStudent: studentOBJ, activeButton: null
        })
    }

    render() {
        const { isEnglish } = this.props;
        const { eventsList, activeButton, instructorNameState, filteredList, studentNameState, filteredStudentList, notificationMsg, notificationTitle, loading } = this.state;
        let modifiedInstructorList = [];
        if (checkArrayLength(eventsList)) {
            modifiedInstructorList = eventsList.map((value, index) => {
                if (value && value !== undefined && value !== null) {
                    return {
                        1: index + 1,//value.instructor_id,
                        2: value.notification_title,
                        3: value.notification_message,
                        4: value.target_user,
                    }
                }
            })
            modifiedInstructorList = modifiedInstructorList.filter(value => value != null || value != undefined);
            modifiedInstructorList = [{
                1: "Index",
                2: "Notification Title",
                3: "Notification Message",
                4: "Audience",
            }, ...modifiedInstructorList]
        }
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        title={getText('Student Notifications', 'Student Meldingen', isEnglish)}
                        subtitle="Super Admin"
                        className="text-sm-left mb-3"
                    />
                </Row>
                <Row>
                    <Col lg="12" className="mb-2">
                        <Card small className="mb-2">
                            <CardHeader className="border-bottom">
                                <h3 className="m-0  text-center">{getText('Notifications', 'Meldingen', isEnglish)}</h3>
                            </CardHeader>
                            <CardBody className="overflow-auto" style={{ height: (window.innerHeight * 0.6) }}>
                                <Row>
                                    <Col lg="4">
                                        <Row lg="4" className="ml-4 mr-3 mb-2">
                                            <Button style={{ height: 33, flex: 1 }} outline theme="primary" disabled={loading} className="mt-3"
                                                active={activeButton===BUTTON_OBJ.INSTRUCTOR}
                                                onClick={() => this._onAudeinceSelect(BUTTON_OBJ.INSTRUCTOR)}
                                            >
                                                {getText('All Instructors', 'Alle instructeurs', isEnglish)}
                                            </Button>
                                        </Row>
                                        <Row lg="4" className="ml-4 mr-3 mb-2">
                                            <Button style={{ height: 33, flex: 1 }} outline theme="primary" disabled={loading} className="mt-3"
                                                active={activeButton===BUTTON_OBJ.STUDENT}
                                                onClick={() => this._onAudeinceSelect(BUTTON_OBJ.STUDENT)}
                                            >
                                                {getText('All Students', 'Alle studenten', isEnglish)}
                                            </Button>
                                        </Row>
                                        <Row lg="4" className="ml-4 mr-3 mb-2">
                                            <Button style={{ height: 33, flex: 1 }} outline theme="primary" disabled={loading} className="mt-3"
                                                active={activeButton===BUTTON_OBJ.BOTH}
                                                onClick={() => this._onAudeinceSelect(BUTTON_OBJ.BOTH)}
                                            >
                                                {getText('Instructor And Students', 'Instructeur en studenten', isEnglish)}
                                            </Button>
                                        </Row>
                                        <Row lg="4" className="ml-4 mr-3 mb-2 mt-4">
                                            <InputGroup seamless className="ml-0">
                                                <InputGroupAddon type="prepend"><InputGroupText><i className="material-icons">person</i></InputGroupText></InputGroupAddon>
                                                <FormInput
                                                    className="form-control"
                                                    placeholder={getText('Search instructor', 'Zoek instructeur', isEnglish)}
                                                    value={instructorNameState}
                                                    onChange={(e) => this.searchIntructors(e.target.value)}
                                                />
                                            </InputGroup>
                                            {filteredList && filteredList.length > 0 && instructorNameState != "" && (
                                                <ul className="options" style={{ zIndex: 99, marginLeft: -15 }}>
                                                    {
                                                        filteredList.map((item, index) => {
                                                            return (
                                                                <div key={item.instructor_id} className="option-active"
                                                                    style={{ backgroundColor: index % 2 == 1 ? '#fff' : '#f8f8ff', padding: 10, paddingRight: 30 }}
                                                                    onClick={() => this.selectInstructor(item)}>
                                                                    {item.instructor_name}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            )}
                                        </Row>
                                        <Row lg="4" className="ml-4 mr-3 mb-2 mt-4">
                                            <InputGroup seamless className="ml-0">
                                                <InputGroupAddon type="prepend"><InputGroupText><i className="material-icons">person</i></InputGroupText></InputGroupAddon>
                                                <FormInput
                                                    className="form-control"
                                                    placeholder={getText('Search student', 'Zoek student', isEnglish)}
                                                    value={studentNameState}
                                                    onChange={(e) => this.searchStudent(e.target.value)}
                                                />
                                            </InputGroup>
                                            {filteredStudentList && filteredStudentList.length > 0 && studentNameState != "" && (
                                                <ul className="options" style={{ zIndex: 99, marginLeft: -15 }}>
                                                    {
                                                        filteredStudentList.map((item, index) => {
                                                            return (
                                                                <div key={item.id} className="option-active"
                                                                    style={{ backgroundColor: index % 2 == 1 ? '#fff' : '#f8f8ff', padding: 10, paddingRight: 30 }}
                                                                    onClick={() => this.selectStudent(item)}>
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            )}
                                        </Row>
                                    </Col>
                                    <Col lg="8">
                                        <Row style={{ marginBottom: 20 }} noGutters>
                                            <Col lg="6" sm='6' className="mt-3">
                                                <FormInput
                                                    className="form-control"
                                                    placeholder={getText('Title', 'Titel', isEnglish)}
                                                    value={notificationTitle}
                                                    onChange={(e) => this.setState({ notificationTitle: e.target.value }) }
                                                />
                                                <FormTextarea
                                                    className="form-control mt-4"
                                                    placeholder={getText('Message', 'Bericht', isEnglish)}
                                                    value={notificationMsg}
                                                    size="lg"
                                                    onChange={(e) => this.setState({ notificationMsg: e.target.value }) }
                                                />
                                                <Button style={{ height: 33 }} theme="primary" disabled={loading} className="mt-3"
                                                    onClick={() => this._assignReferralReward()}
                                                >
                                                    {getText('Send', 'Sturen', isEnglish)}
                                                </Button>
                                            </Col>
                                        </Row>
                                        {
                                            modifiedInstructorList.length < 1 ?
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <h4>{getText('No records found', 'Geen verslagen gevonden', isEnglish)}</h4>
                                            </div> : <NotificationTable data={checkArrayLength(modifiedInstructorList) ? modifiedInstructorList : []} />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAdminManageData: (...args) => dispatch(AdminManageActions.getAdminManageData(...args)),
        getAllStudents: () => dispatch(AdminStudentActions.getAllStudents()),
    }
}

const mapStateToProps = (state) => ({
    isEnglish: state.authReducer.isEnglish,
    allStudents: state.adminStudentReducer.allStudents,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminNotificationView);