import React from "react";
import Table from "./../../../../components/common/Table";

const InstructorRatings = props => {
  let formatedData=props.data.map((record)=>{
      let newNumber=Number(record.avgrating).toFixed(2)
  return {...record,avgrating:newNumber}
  })
  return <Table data={formatedData} ratingTable={props.ratingTable}/>;
};

export default InstructorRatings;
