import { ActionTypes } from '../constants';
import { checkKeyInObject } from '../../utils/utils';
import { AdminStudentMiddleware } from '../middlewares';

export default class AdminStudentActions {

    static getAdminStudentData = () => {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_ADMIN_MANAGE_DATA });
            try {
                const result = await AdminStudentMiddleware.getAdminStudentData();
                if (checkKeyInObject(result, 'status') === 200 && checkKeyInObject(result, 'data.result', 'bool', false)) {
                    dispatch({ type: ActionTypes.GET_ADMIN_STUDENT_DATA_SUCCESS, payload: result.data });
                } else {
                    throw result;
                }
            } catch (error) {
                dispatch({ type: ActionTypes.GET_ADMIN_STUDENT_DATA_FAILURE });
                //console.log({ error })
            }
        };
    }

    static getAllStudents = () => {
       // console.log('all student action')
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_ALL_STUDENTS });
            try {
                const result = await AdminStudentMiddleware.getAllStudents();
                if (checkKeyInObject(result, 'data.result', 'bool', false)) {
             
                    dispatch({ type: ActionTypes.GET_ALL_STUDENTS_SUCCESS, payload: result.data.result });
                } else {
                    throw result;
                }
            } catch (error) {
                dispatch({ type: ActionTypes.GET_ALL_STUDENTS_FAILURE });
               // console.log({ error })
            }
        };
    }

    static getStudentDataByName = (name) => {
        //console.log('by student name')
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_ADMIN_MANAGE_DATA });
            try {
                const result = await AdminStudentMiddleware.getStudentData(name);
                //  console.log('action data',result)
                if (checkKeyInObject(result, 'data.result', 'bool', false)) {
                    // console.log('rating data',result.data)
                    dispatch({ type: 'SEARCHED', payload: result.data });
                } else {
                    throw result;
                }
            } catch (error) {
                dispatch({ type: ActionTypes.GET_ADMIN_STUDENT_DATA_FAILURE });
                //console.log('student action error',{ error })
            }
        };
    }

}
