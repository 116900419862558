import React from "react";
import { Row, Col } from "shards-react";
// import Rating from "@material-ui/lab/Rating";
import StarRatings from 'react-star-ratings';

const Ratings = props => {
  // console.log('props',props.value)
  let size='0px'
  let space='0px'
  if(props.size==='large'){
    size='33px';
    space="3px"
  }
  if(props.size==='small'){
    size='23px';
    space="2px"
  }
  const customClass =
    props.size === "large" ? "text-xxlarge mr-1" : "mr-1 text-large";
  return (
    <Row>
      <Col lg="12">

      <StarRatings 
          rating={props.value? props.value : 0}
          starDimension={size}
          starSpacing={space}
          starRatedColor='rgb(255,218,128)'
        />
        
        {/* {props.label !== null && (
          <span style={{position:'relative', top:'10px'}}  className={customClass}>{props.label}</span>
        )} */}
         {(props.label !== null && props.size==='small') ?  <span style={{position:'relative', top:'5px'}}  className={customClass}>{props.label}</span> :null}
        {(props.label !== null && props.size==='large') ?  <span style={{position:'relative', top:'10px'}}  className={customClass}>{props.label}</span> :null}
      </Col>
    </Row>
  );
};

export default Ratings;
