import { ActionTypes } from '../constants';
import { checkKeyInObject } from '../../utils/utils';
import { AdminManageMiddleware } from '../middlewares';

export default class AdminManageActions {

    static getAdminManageData = (history) => {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_ADMIN_MANAGE_DATA });
            try {
                const result = await AdminManageMiddleware.getAdminManageData();
                if (checkKeyInObject(result, 'status') === 200 && checkKeyInObject(result, 'data.result', 'bool', false)) {
                    dispatch({ type: ActionTypes.GET_ADMIN_MANAGE_DATA_SUCCESS, payload: result.data.result });
                } else {
                    throw result;
                }
            } catch (error) {
                dispatch({ type: ActionTypes.GET_ADMIN_MANAGE_DATA_FAILURE });
                localStorage.clear()
                history.push('/login')
                // console.log('history object', history)
                //console.log('current',{ error })
            }
        };
    }

}
