import React from "react";
import { Progress } from "shards-react";

const StudentList = props => {
  return (
    <div>
      {props.data.map((item, idx) => (
        <div key={idx} className="blog-comments__item d-flex p-3">
          {/* Avatar */}
          <div className="blog-comments__avatar mr-3">
            <img
              src={item.image}
              alt={item.name}
              style={{ height: "80px", width: "80px" }}
            />
          </div>

          {/* Content */}
          <div className="blog-comments__content w-100">
            <p className="m-0 my-1 mb-0">
              Naame student {idx + 1}: {item.name}
            </p>
            <p className="d-flex mb-1">
              <span className="mr-1 ml1" style={{ width: "220px" }}>
                Aantal lesuren: {item.teachingHours}
              </span>
              <span
                className="ml-1 pt-0 pl-0 pr-0 text-center text-semibold br-progress-bar w-100"
                style={{ height: "20px" }}
              >
                <Progress
                  theme="success"
                  style={{ height: "8px" }}
                  className="mb-0"
                  value={item.avg_progess}
                />
              </span>
            </p>
            <p className="m-0 my-1 mb-0">
              Volgende les: {item.insslot_date}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default StudentList;
