import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect
} from "shards-react";
import PageTitle from "./../../components/common/PageTitle";
import DateRangePicker from "./../../components/common/DateRangePicker";
import StudentPopulationBuildup from "./super-admin/student-view/student-population-buildup";
import TotalStudentPopulation from "./super-admin/student-view/total-student-population";
import StudentViewIndividuals from "./super-admin/student-view/student-view-individual";
import InstructorRatings from "./super-admin/student-view/instructor-ratings-given";
import { connect } from "react-redux";
import { getText, checkArrayLength, checkKeyInObject, formatDate } from "../../utils/utils";
import AdminStudentActions from "../../store/actions/adminStudentActions";
import ContentLoader from '../../components/common/ContentLoader';
import {checkToken} from "../../store/actions/validity"
import axios from 'axios';
import { BASE_URL } from "../../config";
const letters = /^[A-Za-z]+$/;


const populationData = {
  dataLabels: {
    enabled: false
  },
  colors: ["#00539C"],
  title: {
    text: "Active Students",
    align: "center",
    style: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "grey"
    }
  },
  chart: {
    type: "bar",
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  yaxis: {
    // min: 0,
    // max: 250,
    tickAmount: 5,
    title: {
      text: ""
    }
  },
  legend: {
    position: "right",
    offsetY: 40
  },
  fill: {
    opacity: 1
  }
};

const revenuesData = [
  {
    1: "",
    2: "Total",
    3: "0% - 20%",
    4: "20% - 40%",
    5: "40% - 60%",
    6: "60% - 80%",
    7: "80% - 100%"
  },
  { 1: "# students", 2: "-", 3: "-", 4: "-", 5: "-", 6: "-", 7: "-" },
  { 1: "Average # lessons", 2: "-", 3: "-", 4: "-", 5: "-", 6: "-", 7: "-" },
  {
    1: "Average progress",
    2: "-",
    3: "-",
    4: "-",
    5: "-",
    6: "-",
    7: "-"
  },
  {
    1: "Average price per lesson",
    2: "-",
    3: "-",
    4: "-",
    5: "-",
    6: "-",
    7: "-"
  }
];

const instructorRatingData = [
  {
    1: " ",
    2: "Average",
    3: "Lesson 1",
    4: "Lesson 2",
    5: "Lesson 3",
    6: "Lesson 4",
    7: "Lesson 5"
  },
  {
    1: "Instructor 1",
    2: "-",
    3: "-",
    4: "-",
    5: "-",
    6: "-",
    7: "-"
  },
  {
    1: "Instructor 2",
    2: "-",
    3: "-",
    4: "-",
    5: "-",
    6: "-",
    7: "-"
  },
  {
    1: "Instructor 3",
    2: "-",
    3: "-",
    4: "-",
    5: "-",
    6: "-",
    7: "-"
  },
  {
    1: "Instructor 4",
    2: "-",
    3: "-",
    4: "-",
    5: "-",
    6: "-",
    7: "-"
  }
];

const AdminStudentView = (props) => {
  const [actPopPeriod, setActPopPeriod] = useState("");
  const [actPopPeriodStartDate, setActPopPeriodStartDate] = useState("");
  const [actPopPeriodEndDate, setActPopPeriodEndDate] = useState("");
  const [activeStudentPopulation, setActiveStudentPopulation] = useState([]);
  const [pickedInstructor, setInstructor] = useState(null);
  const [instructorNameState, updateInstructorName] = useState("");
  const [filteredList, updateFilterList] = useState([]);
  const [instructorsList, updateInstructorList] = useState([]);
  const [apiCall, triggerAPICall] = useState(false);
  const { isEnglish, isLoading, studentPopulationBuild, studentViewLessonsIndividual, studentViewProgressIndividual, instructorRatingGiven, getAllStudents, getStudentDataByName } = props;

  useEffect(()=>{
    checkToken(props.history);
  },[])
  
  useEffect(() => {
    if (instructorNameState) {

    }
  }, [apiCall])

  // search instructor selected function
  const selectInstructor = (instructorOBJ) => {
    updateInstructorName(instructorOBJ.name);
    updateFilterList([]);
    setInstructor(instructorOBJ);
  }

  useEffect(() => {
    if (checkArrayLength(props.allStudents)) {
      updateInstructorList([...props.allStudents]);
    }
  }, [props.allStudents])

  // searching intructor by there name for updating credits
  const searchIntructors = (instructorName) => {
    if (pickedInstructor && typeof pickedInstructor != "undefined") {
      setInstructor(null);
      updateInstructorName("");
      updateFilterList([]);
    } else if (instructorName == "" || instructorName.match(letters)) {
      updateInstructorName(instructorName);
      setTimeout(() => {
        if (instructorName.match(letters) && instructorsList && instructorsList.length > 0) {
          const filteredOptions = instructorsList.filter(
            (option) => {
              return option.name && option.name.toLowerCase().indexOf(instructorName.toLowerCase()) > -1
            }
          );
          updateFilterList(filteredOptions);
        }
      }, 100);
    }
  }


  useEffect(() => {
    // if (props.isAdminStudentReducerEmpty) {
    props.getAdminStudentData()
    getAllStudents();
    // }
  }, [])

  useEffect(() => {
    // {console.log('student data',props.activeStudentPopulation.reverse())}
    if (checkArrayLength(props.activeStudentPopulation)) {
      setActiveStudentPopulation([...props.activeStudentPopulation]);
    }
  }, [props.activeStudentPopulation])

  useEffect(() => {
    if (actPopPeriod && ((actPopPeriod != 'selection') || (actPopPeriod == 'selection' && actPopPeriodStartDate && actPopPeriodEndDate))) {
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: actPopPeriod ? actPopPeriod : 'week' };
      if (actPopPeriod == 'selection') {
        apiBody = {
          ...apiBody,
          from_date: `${formatDate(actPopPeriodStartDate)}`,
          to_date: `${formatDate(actPopPeriodEndDate)}`,
          name: instructorNameState ? instructorNameState : null
        }
      }
      axios.post(`${BASE_URL}/admin/student/total-student`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.total_student', 'bool', false) && checkArrayLength(response.data.result.total_student) && checkArrayLength(response.data.result.total_student[0].data)) {
            setActiveStudentPopulation([...response.data.result.total_student[0].data.reverse()]);
          }
          else {
            setActiveStudentPopulation([]);
          }
        })
        .catch((err) => {
         // console.log(err)
        })
    }
  }, [actPopPeriod, actPopPeriodStartDate, actPopPeriodEndDate])

  const onSearchClick = () => {
    // console.log('getStudentDataByName', instructorNameState)
    if(instructorNameState!==''){
      getStudentDataByName(instructorNameState);
    }else{
      props.getAdminStudentData()
     
    }
    // console.log('lesson reducer', studentViewLessonsIndividual)
    // console.log('progress reducer', studentViewProgressIndividual)
    triggerAPICall(!apiCall);
  }



  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={getText('Student View', 'Student uitzicht', isEnglish)}
          subtitle="Super Admin"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
        <Col lg="6" className="mb-2">
          <Card small className="mb-2 h-600">
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">
                {getText('Total active student population', 'Totaal actieve studentenpopulatie', isEnglish)}
              </h3>
            </CardHeader>
            <CardBody>
              {isLoading}
              {isLoading && !checkArrayLength(activeStudentPopulation)
                ?<>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                <ContentLoader height={430} width={560} />
                </>
                :
                <TotalStudentPopulation
                  height="100%"
                  options={actPopPeriod == 'month' ?
                    {
                      ...populationData,
                      xaxis: {
                        type: "string",
                        categories: ["Month -4", "Month -3", "Month -2", "Month -1", "Current Month"]
                      },
                    } : actPopPeriod == 'year' ?
                      {
                        ...populationData,
                        xaxis: {
                          type: "string",
                          categories: ["Year -4", "Year -3", "Year -2", "Year -1", "Current Year"]
                        },
                      }
                      : actPopPeriod == 'selection' ?
                        {
                          ...populationData,
                          xaxis: {
                            type: "string",
                            categories: ["", "", "", "", ""],
                          }
                        }
                        :
                        {
                          ...populationData,
                          xaxis: {
                            type: "string",
                            categories: ["Week -4", "Week -3", "Week -2", "Week -1", "Current Week"]
                          },
                        }
                  }
                  series={[
                    {
                      name: "Active Students",
                      data: checkArrayLength(activeStudentPopulation) ? [...activeStudentPopulation] : [],
                    }
                  ]}
                  type="bar"
                />
              }
                {(actPopPeriod == '') ? <p className="text-center">Week</p>: null}
                { (actPopPeriod == 'month') ? <p className="text-center">{getText('Month', 'Maand', isEnglish)}</p>: null}
                 { (actPopPeriod == 'week') ? <p className="text-center">Week</p>: null}
                  { (actPopPeriod == 'year') ? <p className="text-center">{getText('Year', 'Jaar', isEnglish)}</p>: null}
                   {(actPopPeriod == 'selection') ? <p className="text-center">{getText('Date', 'Datum', isEnglish)}</p>: null}
            </CardBody>
            <CardFooter style={{marginTop:30}} className="border-top pl-2">
              <Row>
                <Col lg="3">
                  <FormSelect
                    size="sm"
                    value={actPopPeriod}
                    style={{ maxWidth: "150px" }}
                    onChange={val => {
                      setActPopPeriod(val.currentTarget.value);
                    }}
                  >
                    <option value="week">Week</option>
                    <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                    <option value="year">{getText('Year', 'Jaar', isEnglish)}</option>
                    <option value="selection">{getText('Selection', 'Selectie', isEnglish)}</option>
                  </FormSelect>
                </Col>
                {actPopPeriod === "selection" && (
                  <Col lg="9">
                    <DateRangePicker
                      startDate={actPopPeriodStartDate}
                      endDate={actPopPeriodEndDate}
                      handleStartDateChange={value =>
                        setActPopPeriodStartDate(new Date(value))
                      }
                      handleEndDateChange={value =>
                        setActPopPeriodEndDate(new Date(value))
                      }
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
          <Card small>
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Student Population Build-up', 'Opbouw van studentenpopulatie', isEnglish)}</h3>
            </CardHeader>
            <CardBody className="overflow-auto">
              {isLoading && !checkArrayLength(studentPopulationBuild)
                ?<>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                <ContentLoader height={250} width={560} />
                </>
                :
                <StudentPopulationBuildup data={checkArrayLength(studentPopulationBuild) ? studentPopulationBuild : revenuesData} />
              }
         
            </CardBody>
          </Card>
        </Col>
{/* {console.log('check progress', studentViewProgressIndividual)}
{console.log('check lessons', studentViewLessonsIndividual)} */}
        <Col lg="6" className="mb-2">
          <Card small className="mb-2">
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Student View (Individual)', 'Studentenweergave (individueel)', isEnglish)}</h3>
            </CardHeader>
            <CardBody style={{minHeight:'200px', maxHeight:'auto'}}>
              <StudentViewIndividuals
                progressTitle={getText('Progress', 'Vooruitgang', isEnglish)}
                lessonsTitle={getText('Lesson', 'Les', isEnglish)}
                studentProgressData={studentViewProgressIndividual}
                studentLessonData={studentViewLessonsIndividual}
                isLoading={isLoading}
                onClickList={(item) => { selectInstructor(item) }}
                filteredList={filteredList}
                instructorNameState={instructorNameState}
                searchIntructors={(value) => { searchIntructors(value) }}
                onSearchClick={() => { onSearchClick() }}
              />
            </CardBody>
          </Card>
          <Card small style={{ overflow: 'auto' }}>
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Instructor Ratings Given', 'Gegeven instructeursbeoordelingen', isEnglish)}</h3>
            </CardHeader>
            <CardBody className="overflow-auto">
              {/* {console.log('intructorRating', instructorRatingGiven)} */}
              {isLoading && !checkArrayLength(instructorRatingGiven)
                ?<>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                <ContentLoader height={250} width={560} />
                </>
                :
                <InstructorRatings data={checkArrayLength(instructorRatingGiven) ? instructorRatingGiven : instructorRatingData} ratingTable={true} />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

AdminStudentView.propTypes = {};

AdminStudentView.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminStudentData: (...args) => dispatch(AdminStudentActions.getAdminStudentData(...args)),
    getAllStudents: () => dispatch(AdminStudentActions.getAllStudents()),
    getStudentDataByName: (instructorName) => dispatch(AdminStudentActions.getStudentDataByName(instructorName)),
  }
}
const mapStateToProps = (state) => ({
  isEnglish: state.authReducer.isEnglish,
  isAdminStudentReducerEmpty: state.adminStudentReducer.isAdminStudentReducerEmpty,
  activeStudentPopulation: state.adminStudentReducer.activeStudentPopulation,
  studentPopulationBuild: state.adminStudentReducer.studentPopulationBuild,
  studentViewLessonsIndividual: state.adminStudentReducer.studentViewLessonsIndividual,
  studentViewProgressIndividual: state.adminStudentReducer.studentViewProgressIndividual,
  instructorRatingGiven: state.adminStudentReducer.instructorRatingGiven,
  isAdminStudentReducerEmpty: state.adminStudentReducer.isAdminStudentReducerEmpty,
  allStudents: state.adminStudentReducer.allStudents,
  // isLoading: state.adminStudentReducer.isLoading,
  isLoading: true,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminStudentView);