import { combineReducers } from 'redux';

import { authReducer } from './authReducer.js';
import { adminManageReducer } from './adminManageReducer.js';
import { adminInstructorReducer } from './adminInstructorReducer.js';
import { adminStudentReducer } from './adminStudentReducer.js';
import {EditProfileReducer} from './EditProfileReducer';
import {changePasswordReducer} from './changePasswordReducer';


const RootReducer = combineReducers({
    authReducer,
    adminManageReducer,
    adminInstructorReducer,
    adminStudentReducer,
    EditProfileReducer,
    changePasswordReducer,
});


export {
    authReducer,
    adminManageReducer,
    adminInstructorReducer,
    adminStudentReducer,
    RootReducer,
    EditProfileReducer,
    changePasswordReducer,
}