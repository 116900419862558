import React, { useState } from "react";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./../InstructorSlotsModal/InstructorSlotModal.css";

function TimePicker(props) {
  const [selectedDate, setSelectedDate] = useState(props.time);

  const handleDateChange = date => {
    // console.log(new Date(date));
    setSelectedDate(date);
    //passing and executing props
    props.updateDate(date);
  };
  // console.log("minnnnn",selectedDate)
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          format="HH:mm"
          ampm={false}
          // defaultValue="07:30"
          disablePast={true}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change time"
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default TimePicker;
