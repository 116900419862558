class ActionTypes {
  static LOGIN = "LOGIN";
  static LOGIN_SUCCESS = "LOGIN_SUCCESS";
  static LOGIN_FAILURE = "LOGIN_FAILURE";

  static EDIT_PROFILE = "EDIT_PROFILE";
  static EDIT_PROFILE_STATUS = "EDIT_PROFILE_STATUS";

  static CHANGE_PW_STATUS = "CHANGE_PW_STATUS";

  static LOGOUT = "LOGOUT";

  static TOGGLE_LANGUAGE = "TOGGLE_LANGUAGE";

  static GET_ADMIN_MANAGE_DATA = "GET_ADMIN_MANAGE_DATA";
  static GET_ADMIN_MANAGE_DATA_SUCCESS = "GET_ADMIN_MANAGE_DATA_SUCCESS";
  static GET_ADMIN_MANAGE_DATA_FAILURE = "GET_ADMIN_MANAGE_DATA_FAILURE";

  static GET_ADMIN_INSTRUCTOR_DATA = "GET_ADMIN_INSTRUCTOR_DATA";
  static GET_ADMIN_INSTRUCTOR_DATA_SUCCESS =
    "GET_ADMIN_INSTRUCTOR_DATA_SUCCESS";
  static GET_ADMIN_INSTRUCTOR_DATA_FAILURE =
    "GET_ADMIN_INSTRUCTOR_DATA_FAILURE";

  static GET_ADMIN_STUDENT_DATA = "GET_ADMIN_STUDENT_DATA";
  static GET_ADMIN_STUDENT_DATA_SUCCESS = "GET_ADMIN_STUDENT_DATA_SUCCESS";
  static GET_ADMIN_STUDENT_DATA_FAILURE = "GET_ADMIN_STUDENT_DATA_FAILURE";

  static CLEAR_LOGIN_ERROR_MESSAGE = "CLEAR_LOGIN_ERROR_MESSAGE";

  static GET_ALL_INSTRUCTORS = "GET_ALL_INSTRUCTORS";
  static GET_ALL_INSTRUCTORS_SUCCESS = "GET_ALL_INSTRUCTORS_SUCCESS";
  static GET_ALL_INSTRUCTORS_FAILURE = "GET_ALL_INSTRUCTORS_FAILURE";

  static GET_ALL_STUDENTS = "GET_ALL_STUDENTS";
  static GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
  static GET_ALL_STUDENTS_FAILURE = "GET_ALL_STUDENTS_FAILURE";

  static GET_ALL_INSTRUCTORS_SLOTS_SUCCESS =
    "GET_ALL_INSTRUCTORS_SLOTS_SUCCESS";
  static GET_ALL_INSTRUCTORS_SLOTS = "GET_ALL_INSTRUCTORS_SLOTS";
  static GET_ALL_INSTRUCTORS_SLOTS_FAILURE =
    "GET_ALL_INSTRUCTORS_SLOTS_FAILURE";
}

export { ActionTypes };
