import axios from 'axios';
import { BASE_URL } from '../../config';

export default class AdminManageMiddleware {
    constructor() {
    };

    static getAdminManageData = async () => {
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        return await axios.get(`${BASE_URL}/admin/management`);
    };
}