import React from "react";
import { Container } from "shards-react";

const Table = props => {
  const getTableData = () => {
    return props.data.map((row, rowIndex) => {
      return (
        <tr key={rowIndex}>
          {Object.values(row).map((value, index) => {
            let bg=''
            if(index>1 && props.ratingTable){
              bg='whitesmoke '
            }
            return (
              <td className="border" key={index} style={{backgroundColor:bg}}>
                {value === "" ? "#" : value}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <Container fluid className="main-content-container px-2">
      <table className="table mb-1 border small">
        <tbody>
          {getTableData()}
        </tbody>
      </table>
    </Container>
  );
};

export default Table;
