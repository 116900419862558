import { ActionTypes } from '../constants';
const initialState = {
    modalStatus:false,
};
export const changePasswordReducer = (state = initialState, action) =>{
 
    switch(action.type){
        case ActionTypes.CHANGE_PW_STATUS:
            return {
                ...state,
                modalStatus:action.payload
            }
        default:
            return state
    }
}
