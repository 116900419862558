import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormSelect,
  Button
} from "shards-react";

import axios from 'axios';
import PageTitle from "./../../components/common/PageTitle";
import DateRangePicker from "./../../components/common/DateRangePicker";
import {checkToken} from "../../store/actions/validity"
import DownloadNewRegistration from "./super-admin/management-view/download-new-registration";
import RegistrationView from "./super-admin/management-view/registration-view";
import InstructorUsageView from "./super-admin/management-view/instructor-usage-view";
import StudentUsageView from "./super-admin/management-view/student-usage-view";
import { getText, checkArrayLength, JSONToCSVConvertor, formatDate, checkKeyInObject } from '../../utils/utils';
import { connect } from "react-redux";
import AdminManageActions from "../../store/actions/adminManageActions";
import ContentLoader from "../../components/common/ContentLoader";
import { BASE_URL } from "../../config";
import ChangePasswordModal from '../../components/ChangePasswordModal/ChangePasswordModal';

const instructorOptions = {
  series: [
    {
      name: "Average per Instructor",
      // type: "column",
      data: [440, 550, 410, 670, 622]
    },
    {
      name: "Total Logins",
      // type: "column",
      data: [130, 530, 600, 700, 130]
    },
    {
      name: "Unique Logins",
      // type: "column",
      data: [1.1, 7.7, 5.1, 3.5, 2.2]
    }
  ],
  options: {
    title: {
      text: "Instructor log ins",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "grey"
      }
    },
    colors: ["#FEB019", "#DD4132", "#00539C"],
    dataLabels: {
      enabled: false,
      color: "black",
      style: {
        fontSize: "8px",
        fontWeight: "bold",
        color: "black"
      }
    },
    chart: {
      type: "column",
      // stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        //added by rija
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    // stroke: {
    //   width: [4, 1, 1]
    // },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    xaxis: {
      type: "string",
      //categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]
      categories: ["Current Week", "Week -2", "Week -3", "Week -4", "Week -5"]
    },
    // yaxis: [
    //   {
    //     position: "top",
    //     show: false,
    //     seriesName: "Average per instructor",
    //     axisTicks: {
    //       show: true
    //     },
    //     labels: {
    //       formatter: val => val,
    //       color: "gold"
    //     }
    //   },
    //   {
    //     seriesName: "Total Logins",
    //     // min: 0,
    //     // max: 1000,
    //     tickAmount: 4,
    //     axisTicks: {
    //       show: true
    //     },
    //     title: {
    //       text: ""
    //     },
    //     labels: {
    //       formatter: function (val) {
    //         return val;
    //       }
    //     }
    //   },
    //   {
    //     seriesName: "Unique Logins",
    //     // min: 0,
    //     // max: 30,
    //     tickAmount: 3,
    //     opposite: true,
    //     axisTicks: {
    //       show: true
    //     }
    //   }
    // ],
    legend: {
      position: "top",
      offsetY: 0
    },
    fill: {
      opacity: 1
    }
  }
};

const studentOptions = {
  series: [
    {
      name: "Average per Student",
      // type: "column",
      data: [0, 0, 0, 0, 0]
    },
    {
      name: "Total Logins",
      // type: "column",
      data: [1, 1, 1, 1, 1]
    },
    {
      name: "Unique Logins",
      // type: "column",
      data: [1, 1, 1, 1, 1]
    }
  ],

  options: {
    title: {
      text: "Student log ins",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "grey"
      }
    },
    colors: ["#FEB019", "#DD4132", "#00539C"],
    dataLabels: {
      enabled: false
    },
    chart: {
      type: "bar",
      // stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        //added by rija
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    // stroke: {
    //   width: [4, 1, 1]
    // },
     stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    xaxis: {
      type: "string",
      //categories: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]
      categories: ["Current Week", "Week -2", "Week -3", "Week -4", "Week -5"]
    },
    // yaxis: [
    //   {
    //     show: false,
    //     seriesName: "Average per student",
    //     axisTicks: {
    //       show: true
    //     }
    //   },
    //   {
    //     seriesName: "Total Logins",
    //     // min: 0,
    //     // max: 500,
    //     tickAmount: 5,
    //     axisTicks: {
    //       show: true
    //     },
    //     title: {
    //       text: ""
    //     },
    //     labels: {
    //       formatter: function (val) {
    //         return val;
    //       }
    //     }
    //   },
    //   {
    //     seriesName: "Unique Logins",
    //     // min: 0,
    //     // max: 2.5,
    //     tickAmount: 5,
    //     opposite: true,
    //     axisTicks: {
    //       show: true
    //     }
    //   }
    // ],
    legend: {
      position: "top",
      offsetY: 0
    },
    fill: {
      opacity: 1
    }
  }
}
;

const AdminManagementView = props => {
  const [registrationPeriod, setRegistrationPeriod] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");

  const [downloadPeriod, setDownloadPeriod] = useState("week");
  const [downloadStartDate, setDownloadStartDate] = useState("");
  const [downloadEndDate, setDownloadEndDate] = useState("");

  const [downloadRegistrationData, setDownloadRegistrationData] = useState([]);
  const [DownloadNewRegistrationStatus, setStatus] = useState('active');
  const [type, setType] = useState('student');

  const [studentRegistrations, setStudentRegistrations] = useState([]);
  const [instructorRegistrations, setInstructorRegistrations] = useState([]);

  const [instructorUsage, setInstructorUsage] = useState([]);
  const [studentUsage, setStudentUsage] = useState([]);

  const [instructorUsagePeriod, setinstructorUsagePeriod] = useState('');
  const [studentUsagePeriod, setstudentUsagePeriod] = useState('');

useEffect(()=>{
  checkToken(props.history);
},[])

  useEffect(() => {
    if (studentUsagePeriod) {
      let _studentUsage = [
        {
          name: "Average per Student",
          // type: "column",
          data: []
        },
        {
          name: "Total Logins",
          // type: "column",
          data: []
        },
        {
          name: "Unique Logins",
          // type: "column",
          data: []
        }
      ]
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: studentUsagePeriod ? studentUsagePeriod : 'week' };
      axios.post(`${BASE_URL}/admin/management/student-usage`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.student_usage', 'bool', false) && checkArrayLength(response.data.result.student_usage)) {
            response.data.result.student_usage.map((record) => {
              _studentUsage[0].data.push(record.AvgLogin);
              _studentUsage[1].data.push(record.totalLogin);
              _studentUsage[2].data.push(record.uniquelogins);

              // console.log('Checking data', record);
            })
            // console.log('Checking data', _studentUsage);
            setStudentUsage(_studentUsage);
          }
          else {
            setStudentUsage(_studentUsage);
            // console.log('Checking data2' );
          }
        })
        .catch((err) => {
          //console.log(err)
        })
    }
  }, [studentUsagePeriod])

  useEffect(() => {
    if (instructorUsagePeriod) {
      let _instructorUsage = [
        {
          name: "Average per Instructor",
          // type: "column",
          data: []
        },
        {
          name: "Total Logins",
          // type: "column",
          data: []
        },
        {
          name: "Unique Logins",
          // type: "column",
          data: []
        }
      ]
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: instructorUsagePeriod ? instructorUsagePeriod : 'week' };
      axios.post(`${BASE_URL}/admin/management/instructor-usage`, { ...apiBody })
        .then((response) => {
          if (checkKeyInObject(response, 'data.result.instructor_usage', 'bool', false) && checkArrayLength(response.data.result.instructor_usage)) {
            response.data.result.instructor_usage.map((record) => {
              _instructorUsage[2].data.push(record.uniquelogins);
              _instructorUsage[1].data.push(record.totalLogin);
              _instructorUsage[0].data.push(record.AvgLogin);
            })
            setInstructorUsage(_instructorUsage);
          }
          else {
            setInstructorUsage(_instructorUsage);
          }
        })
        .catch((err) => {
         // console.log(err)
        })
    }
  }, [instructorUsagePeriod])


  useEffect(() => {
    if (checkArrayLength(props.instructorUsage)) {

      let _instructorUsage = [
        {
          name: "Average per Instructor",
          // type: "column",
          data: []
        },
        {
          name: "Total Logins",
          // type: "column",
          data: []
        },
        {
          name: "Unique Logins",
          // type: "column",
          data: []
        }
      ];
      props.instructorUsage.reverse().map((record) => {
        _instructorUsage[2].data.push(record.uniquelogins);
        _instructorUsage[1].data.push(record.totalLogin);
        _instructorUsage[0].data.push(record.AvgLogin);
      })
      setInstructorUsage(_instructorUsage);
    }
    if (checkArrayLength(props.studentUsage)) {
      let _studentUsage = [
        {
          name: "Average per Student",
          // type: "column",
          data: []
        },
        {
          name: "Total Logins",
          // type: "column",
          data: []
        },
        {
          name: "Unique Logins",
          // type: "column",
          data: []
        }
      ];
      props.studentUsage.reverse().map((record) => {
        _studentUsage[2].data.push(record.uniquelogins);
        _studentUsage[1].data.push(record.totalLogin);
        _studentUsage[0].data.push(record.AvgLogin);
      })
      setStudentUsage(_studentUsage);
    }
  }, [props.instructorUsage, props.studentUsage])

  const getRegistration = () => {
    let token = localStorage.getItem('token-click-drive');
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
    let apiBody = {
      type: downloadPeriod ? downloadPeriod : 'week',
      user_type: type,
      status: DownloadNewRegistrationStatus,
    };
  
    if (downloadPeriod == 'selection' && downloadStartDate && downloadEndDate) {
      apiBody = {
        ...apiBody,
        from_date: `${formatDate(downloadStartDate)}`,
        to_date: `${formatDate(downloadEndDate)}`,
      }
      // console.log('apiBody',apiBody)
     
    }
    axios.post(`${BASE_URL}/admin/management/registration-downlaod`, { ...apiBody })
      .then((response) => {
        if (checkKeyInObject(response, 'data.result.registration_download', 'bool', false) && checkArrayLength(response.data.result.registration_download)) {
          setDownloadRegistrationData([...response.data.result.registration_download]);
        }
        else {
          setDownloadRegistrationData([]);
        }
      })
      .catch((err) => {
       // console.log(err)
      })

  }

  useEffect(() => {
    if (props.isAdminManageReducerEmpty) {
      props.getAdminManageData(props.history)
    }
  }, [])

  useEffect(() => {
    getRegistration();
  }, [DownloadNewRegistrationStatus, type, downloadStartDate, downloadEndDate, downloadPeriod])

  useEffect(() => {
    if (checkArrayLength(props.studentRegistrations)) {
      setStudentRegistrations([...props.studentRegistrations]);
    }
    if (checkArrayLength(props.instructorRegistrations)) {
      setInstructorRegistrations([...props.instructorRegistrations]);
    }
  }, [props.studentRegistrations, props.instructorRegistrations])

  useEffect(() => {

    setStudentRegistrations([]);
    setInstructorRegistrations([]);

    if (registrationPeriod && ((registrationPeriod != 'selection') || (registrationPeriod == 'selection' && registrationStartDate && registrationEndDate))) {
      let token = localStorage.getItem('token-click-drive');
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
      let apiBody = { type: registrationPeriod ? registrationPeriod : 'week' };
      if (registrationPeriod == 'selection') {
        apiBody = {
          ...apiBody,
          from_date: `${formatDate(registrationStartDate)}`,
          to_date: `${formatDate(registrationEndDate)}`,
        }
        
      }
      axios.post(`${BASE_URL}/admin/management/registration`, { ...apiBody })
        .then((response) => {
          // console.log('api response', response)
          if (checkKeyInObject(response, 'data.result.registrations', 'bool', false) && checkArrayLength(response.data.result.registrations)) {
            if (response.data.result.registrations[0] && response.data.result.registrations[0].data) {
              setInstructorRegistrations([...response.data.result.registrations[0].data]);
            }
            if (response.data.result.registrations[1] && response.data.result.registrations[1].data) {
              setStudentRegistrations([...response.data.result.registrations[1].data]);
            }
          }
          else {
            setStudentRegistrations([]);
            setInstructorRegistrations([]);
          }
        })
        .catch((err) => {
        //  console.log(err)
        })
    }
  }, [registrationPeriod, registrationStartDate, registrationEndDate])

  let newRegistrationData = [];
  if (checkArrayLength(downloadRegistrationData)) {
    newRegistrationData = downloadRegistrationData.map(value => {
      if (value !== undefined  && value !== null) {
        return {
          1: value.Name,
          2: value.Email,
          3: value.Phone,
          4: value.Address,
          5: value.Status,
        }
      }
    })

    newRegistrationData = newRegistrationData.filter(value => value != null || value != undefined);

    newRegistrationData = [{
      1: "Name",
      2: "Email",
      3: "Phone",
      4: "Address",
      5: "Status"
    }, ...newRegistrationData]
  }

  const { isEnglish, isLoading } = props;

// console.log('newww',props.history);
  return (
    <Container fluid className="main-content-container px-4">
          {props.PasswordChangeOpen? <ChangePasswordModal/>:null}
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={getText('Management View', 'Beheerweergave', isEnglish)}
          subtitle="Super Admin"
          className="text-sm-left mb-3"
        />
      </Row>
      <Row>
      {/* { console.log('new',instructorRegistrations)} */}
        <Col lg="6" md='12' className="mb-2">
          <Card className="mb-2">
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Registrations', 'Inschrijvingen', isEnglish)}</h3>
            </CardHeader>
            <CardBody style={{ minHeight: "248px", maxHeigth: 'auto' }}>
              {
                isLoading && !checkArrayLength(studentRegistrations) && !checkArrayLength(instructorRegistrations) ?
                <>
                  <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                  <ContentLoader height={248} width={530} />
                  </>
                  :
                  <RegistrationView
                    registrationPeriod={registrationPeriod}
                    studentRegistrations={studentRegistrations}
                    instructorRegistrations={instructorRegistrations}
                  />
              }

                { ((registrationPeriod == '')) ? <p className="text-center">Week</p>: null}
                { (registrationPeriod == 'month') ? <p className="text-center">{getText('Month', 'Maand', isEnglish)}</p>: null}
                 { (registrationPeriod == 'week') ? <p className="text-center">Week</p>: null}
                  { (registrationPeriod == 'year') ? <p className="text-center">{getText('Year', 'Jaar', isEnglish)}</p>: null}
                   {(registrationPeriod == 'selection') ? <p className="text-center">{getText('Date', 'Datum', isEnglish)}</p>: null}
            </CardBody>
            <CardFooter className="border-top pl-2">
              <Row>
                <Col lg="3">
                  <FormSelect
                    size="sm"
                    value={registrationPeriod}
                    style={{ maxWidth: "150px" }}
                    onChange={e => {
                      setRegistrationPeriod(e.currentTarget.value);
                    }}
                  >
                    <option value="week">Week</option>
                    <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                    <option value="year">{getText('Year', 'Jaar', isEnglish)}</option>
                    <option value="selection">{getText('Selection', 'Selectie', isEnglish)}</option>
                  </FormSelect>
                </Col>
                {registrationPeriod === "selection" && (
                  <Col lg="9">
                    <DateRangePicker
                      startDate={registrationStartDate}
                      endDate={registrationEndDate}
                      handleStartDateChange={value =>
                        setRegistrationStartDate(new Date(value))
                      }
                      handleEndDateChange={value =>
                        setRegistrationEndDate(new Date(value))
                      }
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>
          <Card small>
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Instructor Usage', 'Gebruik door instructeur', isEnglish)}</h3>
            </CardHeader>
            <CardBody style={{ height: "445px" }}>
              {
                isLoading && !checkArrayLength(instructorUsage) ?
                <>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                  <ContentLoader height={350} width={530} />
                  </>
                  :
                  <InstructorUsageView
                    options={instructorUsagePeriod == 'month' ?
                      {
                        ...instructorOptions.options,
                        xaxis: {
                          type: "string",
                         
                          categories: ["Month -5", "Month -4", "Month -3", "Month -2", "Current Month"]
                        },
                      } :
                      {
                        ...instructorOptions.options,
                        xaxis: {
                          type: "string",
                          categories: ["week -5", "week -4", "week -3", "week -2", "Current week"]
                        }
                      }
                    }
                    series={
                      instructorUsage.length ? [...instructorUsage]
                        :
                        [
                          {
                            name: "Average per Instructor",
                            // type: "column",
                            data: []
                          },
                          {
                            name: "Total Logins",
                            // type: "column",
                            data: []
                          },
                          {
                            name: "Unique Logins",
                            // type: "column",
                            data: []
                          }
                        ]}
                    type="bar"
                    height={350}
                  />
          
              }
             
                { (instructorUsagePeriod == '') ? <p className="text-center">Week</p>: null}
                { (instructorUsagePeriod == 'month') ? <p className="text-center">{getText('Month', 'Maand', isEnglish)}</p>: null}
                 { (instructorUsagePeriod == 'week') ? <p className="text-center">Week</p>: null}
                  { (instructorUsagePeriod == 'year') ? <p className="text-center">{getText('Year', 'Jaar', isEnglish)}</p>: null}
                   {(instructorUsagePeriod == 'selection') ? <p className="text-center">{getText('Date', 'Datum', isEnglish)}</p>: null}
            </CardBody>

            <CardFooter className="border-top pl-2">
              <Col>
                <FormSelect
                  size="sm"
                  value={instructorUsagePeriod}
                  style={{ maxWidth: "150px" }}
                  onChange={val => {
                    setinstructorUsagePeriod(val.currentTarget.value);
                  }}
                >
                  <option value="week">Week</option>
                  <option value="month">{getText('Month', 'Maand', isEnglish)}</option>
                </FormSelect>
              </Col>
            </CardFooter>
          </Card>
        </Col>

        <Col lg="6" md='12' className="mb-2">
          <Card small className="mb-2">
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Download (New) Registration', 'Registratie (nieuw) downloaden', isEnglish)}</h3>
            </CardHeader>
            <CardBody className="overflow-auto" style={{ height: "300px" }}>
            {/* {console.log('check2',newRegistrationData)} */}
              {
                isLoading && !checkArrayLength(newRegistrationData) ?
                <>
                 <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                  <div style={{ marginTop: 20 }}>
                   
                    <ContentLoader height={248} width={530} />
                  </div>
                  </>
                  :
                  <DownloadNewRegistration data={newRegistrationData} />
              }
            </CardBody>
            <CardFooter className="border-top pl-2">
              <Col>
                <Button theme="primary" disabled={isLoading && !checkArrayLength(newRegistrationData)} className="mr-1" onClick={() => { JSONToCSVConvertor(newRegistrationData, 'New_Registration_Data', 'Registration Record', false, ';') }}>
                  {getText('Download', 'Downloaden', props.isEnglish)}
                </Button>

                <FormSelect
                  size="sm"
                  value="student"
                  style={{ maxWidth: "120px" }}
                  onChange={val => {
                    setType(val.currentTarget.value);
                  }}
                  value={type}
                >
                  <option value="student">{getText('Students', 'Studenten', props.isEnglish)}</option>
                  <option value="instructor">{getText('Instructors', 'Instructeurs', props.isEnglish)}</option>
                </FormSelect>
                <FormSelect
                  className="ml-2"
                  size="sm"
                  value={DownloadNewRegistrationStatus}
                  style={{ maxWidth: "120px" }}
                  onChange={(e) => { setStatus(e.currentTarget.value) }}
                >
                  <option value="active">{getText('Active', 'Actief', props.isEnglish)}</option>
                  <option value="inactive">{getText('In-Active', 'Inactief', props.isEnglish)}</option>
                </FormSelect>

                <FormSelect
                  className="ml-2"
                  size="sm"
                  style={{ maxWidth: "120px" }}
                  value={downloadPeriod}
                  onChange={val => {
                    setDownloadPeriod(val.currentTarget.value);
                  }}
                >
                  <option value="week">Week</option>
                  <option value="month">{getText('Month', 'Maand', props.isEnglish)}</option>
                  <option value="year">{getText('Year', 'Jaar', props.isEnglish)}</option>
                  <option value="selection">{getText('Selection', 'Selectie', props.isEnglish)}</option>
                </FormSelect>
              </Col>
              <Row>
                {downloadPeriod === "selection" && (
                  <Col lg="12 m-3">
                    <DateRangePicker
                      startDate={downloadStartDate}
                      endDate={downloadEndDate}
                      handleStartDateChange={value =>{
                        setDownloadStartDate(new Date(value))
                        // console.log('new start date', value)
                      }
                      
                      }
                      handleEndDateChange={value =>
                        setDownloadEndDate(new Date(value))
                      }
                      placeholderStart={getText('Start Date', 'Startdatum', props.isEnglish)}
                      placeholderEnd={getText('End Date', 'Einddatum', props.isEnglish)}
                    />
                  </Col>
                )}
              </Row>
            </CardFooter>
          </Card>

          <Card small>
            <CardHeader className="border-bottom">
              <h3 className="m-0  text-center">{getText('Student Usage', 'Gebruik door studenten', props.isEnglish)}</h3>
            </CardHeader>
            <CardBody style={{ height: "445px" }}>
              {
                isLoading && !checkArrayLength(studentUsage) ?
                <>
                <h4 className="m-0 text-center">{getText('No Data Available', 'Geen gegevens beschikbaar', isEnglish)}</h4>
                  <ContentLoader height={350} width={530} />
                  </>
                  :
                  <StudentUsageView
                    options={studentUsagePeriod == 'month' ?
                      {
                        ...studentOptions.options,
                        xaxis: {
                          type: "string",
                          categories: ["Month -5", "Month -4", "Month -3", "Month -2", "Current Month"]
                        },
                      } :
                      {
                        ...studentOptions.options,
                        xaxis: {
                          type: "string",
                          categories: ["week -5", "week -4", "week -3", "week -2", "Current week"]
                        }
                      }
                    }
                    series={
                      studentUsage.length ? [...studentUsage]
                        :
                        [
                        {
                          name: "Average per Student",
                          // type: "column",
                          data: []
                        },
                        {
                          name: "Total Logins",
                          // type: "column",
                          data: []
                        },
                        {
                          name: "Unique Logins",
                          // type: "column",
                          data: []
                        }
                        ]}
 
                    type="bar"
                    height={350}
                  />
              }
          
                { ((studentUsagePeriod == '')&&checkArrayLength(studentUsage)) ? <p className="text-center">Week</p>: null}
                { (studentUsagePeriod == 'month') ? <p className="text-center">{getText('Month', 'Maand', isEnglish)}</p>: null}
                 { (studentUsagePeriod == 'week') ? <p className="text-center">Week</p>: null}
                  { (studentUsagePeriod == 'year') ? <p className="text-center">{getText('Year', 'Jaar', isEnglish)}</p>: null}
                   {(studentUsagePeriod == 'selection') ? <p className="text-center">{getText('Date', 'Datum', isEnglish)}</p>: null}
            </CardBody>
            <CardFooter className="border-top pl-2">
              <Col>
                <FormSelect
                  size="sm"
                  value={studentUsagePeriod}
                  style={{ maxWidth: "150px" }}
                  onChange={val => {
                    setstudentUsagePeriod(val.currentTarget.value);
                  }}
                >
                  <option value="week">Week</option>
                  <option value="month">{getText('Month', 'Maand', props.isEnglish)}</option>
                </FormSelect>
              </Col>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

AdminManagementView.propTypes = {};

AdminManagementView.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminManageData: (...args) => dispatch(AdminManageActions.getAdminManageData(...args)),
  }
}
const mapStateToProps = (state) => ({
  isEnglish: state.authReducer.isEnglish,
  isAdminManageReducerEmpty: state.adminManageReducer.isAdminManageReducerEmpty,
  instructorRegistrations: state.adminManageReducer.instructorRegistrations,
  studentRegistrations: state.adminManageReducer.studentRegistrations,
  registrationDownloadData: state.adminManageReducer.registrationDownloadData,
  instructorUsage: state.adminManageReducer.instructorUsage,
  studentUsage: state.adminManageReducer.studentUsage,
  isLoading: state.adminManageReducer.isLoading,
  PasswordChangeOpen:state.changePasswordReducer.modalStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminManagementView);