import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { NavLink as RouteNavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormInput,
  FormGroup,
  NavLink
} from "shards-react";
import AuthActions from "../../store/actions/authActions";
import { checkKeyInObject } from "../../utils/utils";

const Login = ({ login, history, isLoading, loginErrorMessage, clearLoginErrorMessage }) => {
  const [username, setUserName] = useState(''); // 1111111 for instructor
  const [password, setPassword] = useState('');
  const ref = useRef(false);

  if (!ref.current) {  // prevent user to visit Login page if user is already logged in
    ref.current = true;
    if (Boolean(localStorage.getItem('token-click-drive')) && Boolean(localStorage.getItem('user-click-drive'))) {
      let user = JSON.parse(localStorage.getItem('user-click-drive'));
      if (checkKeyInObject(user, 'type', 'bool', false)) {
        switch (user.type) {
          case 0:
            history.push('/admin-management')
            break;
          case 1:
            history.push('/instructor-board')
            break;
        }
      }
    }
  }

  useEffect(() => {
    const cbFunction = (e) => {
      if (e.keyCode === 13 && Boolean(username && password)) {
        e.preventDefault();
        login({ phone: username, password }, history);
      }
    };

    document.addEventListener('keyup', cbFunction)
    return () => {
      document.removeEventListener('keyup', cbFunction);
    }
  }, [])

  useEffect(() => {
    if (loginErrorMessage != null) {
      toast.error(loginErrorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      clearLoginErrorMessage();
    }
  }, [loginErrorMessage])


  return (<Container fluid className="main-content-container px-4">
    {/* Page Header */}

    <Row noGutters className="page-header py-5">
      <Col xs="12" sm="6">
        <h2>Click Drive</h2>
      </Col>
    </Row>
    <Row noGutters className="page-header">
      <Col>
        <Card className="mt-5">
          <CardBody>
            <CardTitle className="mb-4">Login</CardTitle>
            <Form>
              <FormGroup>
                <label htmlFor="#username">Username</label>
                <FormInput id="#username" placeholder="Username" value={username} onChange={(e) => { setUserName(e.target.value) }} />
              </FormGroup>
              <FormGroup>
                <label htmlFor="#password">Password</label>
                <FormInput
                  type="password"
                  id="#password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                />
              </FormGroup>
              <Row>
                <Col>
                  <Button
                    onClick={() => {
                  
                      login({ phone: username, password }, history);
                    }}
                    disabled={!Boolean(username && password)}
                  >
                    {isLoading ?
                      <Loader
                        type="ThreeDots"
                        color={'white'}
                        height={10}
                        width={28}
                      />
                      : 'Login'}
                  </Button>
                </Col>
                {/* <Col>
                  <NavLink
                    tag={RouteNavLink}
                    className="float-right"
                    to={"register"}
                  >
                    Register
                  </NavLink>
                </Col> */}
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
  </Container>)
};

Login.propTypes = {};

Login.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (...args) => dispatch(AuthActions.login(...args)),
    clearLoginErrorMessage: () => dispatch(AuthActions.clearLoginErrorMessage()),
  }
}

const mapStateToProps = (state) =>{
  //console.log('state',state)
return (
    {
    isLoading: state.authReducer.isLoading,
    loginErrorMessage: state.authReducer.loginErrorMessage,
  }
  );
} 

export default connect(mapStateToProps, mapDispatchToProps)(Login);