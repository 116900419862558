import { ActionTypes } from "../constants";
import { checkKeyInObject } from "../../utils/utils";
const initialState = {
  instructorRegistrations: [],
  studentRegistrations: [],
  registrationDownloadData: [],
  instructorUsage: [],
  studentUsage: [],
  isLoading: false,
  isAdminManageReducerEmpty: true
};

export const adminManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADMIN_MANAGE_DATA:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.GET_ADMIN_MANAGE_DATA_SUCCESS:
      let _instructorRegistrations = [];
      let _studentRegistrations = [];
      let _registrationDownloadData = [];
      let _instructorUsage = [];
      let _studentUsage = [];
      if (
        checkKeyInObject(action.payload, "registrations", "bool", false) &&
        action.payload.registrations.length >= 2
      ) {
        if (
          checkKeyInObject(
            action.payload.registrations[0],
            "data",
            "bool",
            false
          ) &&
          checkKeyInObject(
            action.payload.registrations[1],
            "data",
            "bool",
            false
          )
        ) {
          _instructorRegistrations = [...action.payload.registrations[0].data];
          _studentRegistrations = [...action.payload.registrations[1].data];
        }
      }
      if (
        checkKeyInObject(
          action.payload,
          "registration_download",
          "bool",
          false
        ) &&
        action.payload.registration_download.length
      ) {
        _registrationDownloadData = [...action.payload.registration_download];
      }
      if (
        checkKeyInObject(action.payload, "instructor_usage", "bool", false) &&
        action.payload.instructor_usage.length
      ) {
        _instructorUsage = [...action.payload.instructor_usage];
      }
      if (
        checkKeyInObject(action.payload, "student_usage", "bool", false) &&
        action.payload.student_usage.length
      ) {
        _studentUsage = [...action.payload.student_usage];
      }
      // console.log(_studentUsage);
      return {
        ...state,
        // isAdminManageReducerEmpty: false,
        instructorRegistrations: _instructorRegistrations,
        studentRegistrations: _studentRegistrations,
        registrationDownloadData: _registrationDownloadData,
        instructorUsage: _instructorUsage,
        studentUsage: _studentUsage,
        isLoading: false
      };

    case ActionTypes.GET_ADMIN_MANAGE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
