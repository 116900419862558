import React from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {changePasswordStatus } from '../../store/actions/changePasswordAction';
import { BASE_URL } from '../../config';
import axios from 'axios';
import {getText} from '../../../src/utils/utils';

class ChangePasswordModal extends React.Component {
    constructor(props){
        super(props)
        this.state={
            newPassword:'',
            confirmPassword:'',
            passwordMatchErr:false,
            newPassErr:false,
            confirmPassErr:false,
            matchErrMsg:'',
        }
    }
    handleChangePWClose=()=>{
        this.props.changePasswordStatus(false)
    }
    onInputChange = (field, e) => {
        this.setState({ [field]: e.target.value })
    }
    PasswordFormValidation=()=>{
        let passwordMatchErr=false;
        let newPassErr=false;
        let confirmPassErr=false;
        let matchErrMsg=''

        if(!this.state.newPassword){
            newPassErr=true
        }
        if(!this.state.confirmPassword){
            confirmPassErr=true
        }
        if(!(this.state.newPassword===this.state.confirmPassword)){
            passwordMatchErr=true;
            matchErrMsg='Password not match'
        }
        if(passwordMatchErr||newPassErr||confirmPassErr||matchErrMsg){
            this.setState({passwordMatchErr,newPassErr,confirmPassErr,matchErrMsg})
            return false
        }
            this.setState({passwordMatchErr,newPassErr,confirmPassErr,matchErrMsg})
            return true
        

    }
    resetPassword=()=>{
        let token = localStorage.getItem('token-click-drive');
        const ChangePasswordHeader = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`
        }
        const payload = { password: this.state.newPassword }
        axios.put(`${BASE_URL}/update-password`, JSON.stringify(payload), { headers: ChangePasswordHeader })
        .then(res=>{
            this.handleChangePWClose()
        })
        .catch(err=>{
            //console.log('change pw err', err)
        })
    }
    handlePasswordFormSubmit=(e)=>{
        e.preventDefault()
        let isValid = this.PasswordFormValidation();
        //console.log('valid', isValid)
        if(isValid){
            this.resetPassword()
        }
    }
    render(){
        return(
            <Modal style={{ zIndex: 9999 }} show={this.props.PasswordChangeOpen} onHide={this.handleChangePWClose}  centered>
            <Modal.Header closeButton style={{paddingBottom:'0px'}} >
              <Modal.Title > 
                  <i style={{marginRight:'15px'}} className="fa">&#xf023;</i>
                  {getText('Reset Password', 'Wachtwoord Opnieuw Instellen', this.props.isEnglish)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form  noValidate onSubmit={this.handlePasswordFormSubmit}>
                    <Form.Group controlId="NewPassword">
                        <Form.Label>{getText('New Password', 'Nieuw Paswoord', this.props.isEnglish)}</Form.Label>
                        <Form.Control 
                            isInvalid={this.state.newPassErr} 
                            type="password" 
                            placeholder={getText('Enter New Password', 'Voer een nieuw wachtwoord in', this.props.isEnglish)}
                            value={this.state.newPassword}
                            onChange={(e)=>this.onInputChange("newPassword", e)} />
                        <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                    </Form.Group>
                
                    <Form.Group controlId="ConfirmPassword" style={{marginBottom:'30px'}}>
                        <Form.Label>{getText('Confirm Password', 'bevestig wachtwoord', this.props.isEnglish)}</Form.Label>
                        <Form.Control 
                            isInvalid={this.state.passwordMatchErr || this.state.confirmPassErr} 
                            type="password" 
                            placeholder={getText('Retype New Password', 'Typ het nieuwe wachtwoord opnieuw', this.props.isEnglish)}
                            value={this.state.confirmPassword}
                            onChange={(e)=>this.onInputChange("confirmPassword", e)}  />
                        <Form.Control.Feedback type="invalid">{this.state.matchErrMsg? `${getText('Password does not match', 'Wachtwoord komt niet overeen', this.props.isEnglish)}`:null}</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="secondary" style={{ marginRight: 2 }} onClick={this.handleChangePWClose}>
                    {getText('Close', 'Dichtbij', this.props.isEnglish)}
                    </Button>
                    <Button variant="primary" type="submit">
                    {getText('Reset', 'Reset', this.props.isEnglish)}
                    </Button>
                </Form>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={this.handleChangePWClose}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleChangePWClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
          </Modal>
        );
    }
}
const mapStateToProps=(state)=>{
    return{
        PasswordChangeOpen:state.changePasswordReducer.modalStatus,
        isEnglish: state.authReducer.isEnglish,
    }
}
export default connect(mapStateToProps,{changePasswordStatus})(ChangePasswordModal);