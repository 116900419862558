import axios from 'axios';
import { BASE_URL } from '../../config';

export default class AdminInstructorMiddleware {
    constructor() {
    };

    static getAdminInstructorData = async () => {
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        return await axios.get(`${BASE_URL}/admin/instructor`);
    };

    static getAllInstructors = async () => {
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        return await axios.get(`${BASE_URL}/get-all-instructors?web=1`);
    };

}