import { ActionTypes } from "../constants";
import { checkKeyInObject } from "../../utils/utils";
import { AuthMiddleware } from "../middlewares";

export default class AuthActions {
  static login = (userData, history) => {
    return async dispatch => {
      dispatch({ type: ActionTypes.LOGIN });
      try {
        const result = await AuthMiddleware.login(userData);
        if (
          checkKeyInObject(result, "status") === 200 &&
          checkKeyInObject(result, "data.result", "bool", false) &&
          checkKeyInObject(result.data.result, "user.type", "bool", false)
        ) {
          if (
            result.data.result.user.type === 3 ||
            result.data.result.user.type === 1
          ) {
            dispatch({
              type: ActionTypes.LOGIN_SUCCESS,
              payload: result.data.result
            });
          } else {
            throw "Login failed: Invalid phone number or password";
          }
          if (result.data.result.user.type === 3) {
            // for admin
            history.push("/admin-management");
          } else if (result.data.result.user.type === 1) {
            history.push("/instructor-board"); // for instructor
          }
        } else {
          throw result;
        }
      } catch (error) {
        dispatch({ type: ActionTypes.LOGIN_FAILURE, payload: error });
        //console.log('login error rija',{error })
      }
    };
  };

  static logout = () => {
    return { type: ActionTypes.LOGOUT };
  };

  static clearLoginErrorMessage = () => {
    return { type: ActionTypes.CLEAR_LOGIN_ERROR_MESSAGE };
  };

  static toggleLanguage = () => {
    return { type: ActionTypes.TOGGLE_LANGUAGE };
  };
}
