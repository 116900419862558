import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import withTracker from "./withTracker";
import { connect } from "react-redux";
import { AppLayout, DefaultLayout } from "./layouts";
import InstructorDashboard from "./views/dashboard/instructor-dashboard";
import AdminStudentView from "./views/dashboard/admin-student-view";
import AdminReferralView from "./views/dashboard/admin-referral-view";
import AdminNotificationView from "./views/dashboard/admin-notification-view";
import AdminManagementView from "./views/dashboard/admin-management-view";
import AdminWalletView from "./views/dashboard/admin-wallet-view";
import AdminInstructorView from "./views/dashboard/admin-instructor-view";
import Login from "./views/auth/Login";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import { checkKeyInObject } from "./utils/utils";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const getRedirectComponent = (isAuthenticated, type) => {
  if (isAuthenticated && type == 0) {
    return <Redirect to={"/admin-management"} />;
  } else if (isAuthenticated && type == 1) {
    return <Redirect to={"/instructor-board"} />;
  }
  return <Redirect to={"/login"} />;
};

const App = ({ isAuthenticated, currentUser }) => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      <Switch>
        <Route
          exact
          path="/login"
          component={withTracker(props => {
            return (
              <DefaultLayout {...props}>
                <Login {...props} />
              </DefaultLayout>
            );
          })}
        />
        <Route
          exact
          path="/register"
          component={withTracker(props => {
            return (
              <DefaultLayout {...props}>
                <Login {...props} />
              </DefaultLayout>
            );
          })}
        />

        <ProtectedRoute
          exact
          path="/instructor-board"
          isAuthenticated={isAuthenticated}
          routeForUserType={1} // 1 for all the routes for instructor
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <InstructorDashboard {...props} />
              </AppLayout>
            );
          }}
        />

        <ProtectedRoute
          exact
          path="/admin-management"
          isAuthenticated={isAuthenticated}
          routeForUserType={0} // 0 for all the routes for instructor
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminManagementView {...props} />
              </AppLayout>
            );
          }}
        />

        <ProtectedRoute
          exact
          path="/admin-wallet"
          isAuthenticated={isAuthenticated}
          routeForUserType={0} // 0 for all the routes for instructor
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminWalletView {...props} />
              </AppLayout>
            );
          }}
        />

        <ProtectedRoute
          exact
          path="/admin-instructor"
          isAuthenticated={isAuthenticated}
          routeForUserType={0}
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminInstructorView {...props} />
              </AppLayout>
            );
          }}
        />

        <ProtectedRoute
          exact
          path="/admin-student"
          isAuthenticated={isAuthenticated}
          routeForUserType={0}
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminStudentView {...props} />
              </AppLayout>
            );
          }}
        />

        <ProtectedRoute
          exact
          path="/admin-referral"
          isAuthenticated={isAuthenticated}
          routeForUserType={0}
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminReferralView {...props} />
              </AppLayout>
            );
          }}
        />

        <ProtectedRoute
          exact
          path="/admin-notificaiton"
          isAuthenticated={isAuthenticated}
          routeForUserType={0}
          currentUserType={checkKeyInObject(
            currentUser,
            "type",
            "value",
            false
          )}
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminNotificationView {...props} />
              </AppLayout>
            );
          }}
        />

        {/* <ProtectedRoute
          exact
          path="/admin-management"
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminManagementView {...props} />
              </AppLayout>
            );
          }}
        /> */}
        {/* <ProtectedRoute
          exact
          path="/admin-instructor"
          component={props => {
            return (
              <AppLayout {...props}>
                <AdminInstructorView {...props} />
              </AppLayout>
            );
          }}
        /> */}
        {getRedirectComponent(
          isAuthenticated,
          checkKeyInObject(currentUser, "type", "value", false)
        )}
      </Switch>
    </div>
  </Router>

  // {routes.map((route, index) => {
  //   return (
  //     <Route
  //       key={index}
  //       path={route.path}
  //       exact={route.exact}
  //       component={withTracker((props) => {
  //         return (
  //           <route.layout {...props}>
  //             <route.component {...props} />
  //           </route.layout>
  //         );
  //       })}
  //     />
  //   );
  // })}
);

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  currentUser: state.authReducer.user
});

export default connect(mapStateToProps, null)(App);
