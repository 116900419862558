import React from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import pencil from '../../images/edit.png'
import { connect } from 'react-redux';
import { EditProfileStatus, getProfileData } from '../../store/actions/EditProfile';
import { BASE_URL } from '../../config';
import axios from 'axios';
import { Multiselect } from 'multiselect-react-dropdown';
import { ToastContainer, toast } from 'react-toastify';
import {getText} from '../../../src/utils/utils';
import DatePicker from "react-datepicker";
import {EditProfileApi} from './EditProfileApi';
 
import "react-datepicker/dist/react-datepicker.css";

class EditProfileModal extends React.Component {
    constructor(props) {
        super(props);
        let { name,
            about,
            address,
            company_name,
            date_of_birth,
            age,
            driving_license,
            email,
            gender,
            hobbies_interests,
            how_do_you_teach,
            licenseplate,
            price_per_hour,
            profile_picture,
            slot_duration,
            location,
            languages } = this.props.profileData;
        this.state = {
            fileToUpload :'',
            locationOptions: [
                // { key: "Option 1", cat: "Group 1" },
                // { key: "Option 2", cat: "Group 1" },
                // { key: "Option 3", cat: "Group 1" },
                // { key: "Option 4", cat: "Group 2" },
                // { key: "Option 5", cat: "Group 2" },
                // { key: "Option 6", cat: "Group 2" },
                // { key: "Option 7", cat: "Group 2" }
            ],
            locationSelectedValue: [],
            name: name ? name : '',
            nameErr: false,
            about: about ? about : '',
            aboutErr: false,
            address: address ? address : '',
            addressErr: false,
            company_name: company_name ? company_name : '',
            companyErr: false,
            date_of_birth: date_of_birth ? date_of_birth.split(' ')[0] : '',
            showDate:date_of_birth ? new Date(date_of_birth.split(' ')[0]) : '',
            showDOB:'',
            dobErr: false,
            age: age ? age : 0,
            ageErr: '',
            driving_license: driving_license ? driving_license : '',
            dlicenseErr: false,
            oldEmail:email ? email : '',
            email: email ? email : '',
            emailErr: false,
            gender: gender ? gender : 'Female',
            hobbies_interests: hobbies_interests ? hobbies_interests : '',
            hobbyErr: false,
            how_do_you_teach: how_do_you_teach ? how_do_you_teach : '',
            teachErr: false,
            licenseplate: licenseplate ? licenseplate : '',
            licensePlateErr: false,
            price_per_hour: price_per_hour ? price_per_hour+'.00' : 0.00,
            priceErr: false,
            profile_picture: profile_picture ? profile_picture : '',
            slotHour: slot_duration ? slot_duration.split(':')[0] : '',
            HourErr: false,
            slotMinute: slot_duration ? slot_duration.split(':')[1] : '',
            minErr: false,
            languages: languages ? languages.map((lan) => ({ key: lan.language, id: lan.id, checked: lan.checked })) : [],
            selectedLang: [],
            location: location ? location : '',
            locToSend: [],
            lanToSend: [],
            file: profile_picture?profile_picture:'',
            LangErr:false,
            locErr:false,
        }
    //  console.log(this.props.profileData)
    }
    onLocationSelect = (selectedList, selectedItem) => {
        //  console.log('selectedList', selectedList)
        let locArr = []
        selectedList.map(loc => {
            locArr.push(loc.key)
        })
        this.setState({ locToSend: [...locArr] })
    }

    onLocationRemove = (selectedList, removedItem) => {
        // console.log('selectedList', selectedList)
        let locArr = []
        selectedList.map(loc => {
            locArr.push(loc.key)
        })
        this.setState({ locToSend: [...locArr] })

    }
    onLanguageSelect = (selectedList, selectedItem) => {
        //console.log('selectedList', selectedList)
        let lanArr = []
        selectedList.map(lan => {
            lanArr.push(lan.id)
        })
        //console.log('lan add', lanArr)
        this.setState({ lanToSend: [...lanArr] })
    }

    onLanguageRemove = (selectedList, removedItem) => {
        //console.log('selectedList', selectedList)
        let lanArr = []
        selectedList.map(lan => {
            lanArr.push(lan.id)
        })
        //  console.log('lan removed', lanArr)
        this.setState({ lanToSend: [...lanArr] })
    }
    formateSelectedLocation = (locations) => {
        let tempArry = []
        let locationArr = locations.split(',')
        let locationObjArr = locationArr.map(loc => {
            tempArry.push(loc)
            return {
                key: loc
            }
        })
        this.setState({ locationSelectedValue: [...locationObjArr], locToSend: tempArry })
    }
    setLocationOptions = (arr) => {
        var locOp = [].concat.apply([], arr.map(cat =>
            cat.item.map(elem => ({ key: elem.name, cat: cat.name })))
        )
        this.setState({ locationOptions: [...locOp] })
    }
    setSelectedLang = (languages) => {

        let selected = languages.filter((lang) => {
            return lang.checked === true
        })


        this.setState({ selectedLang: [...selected] }, () => {
            //console.log('worked')
            this.setLanToSend()
        })
    }
    setLanToSend = () => {
        let temArry = []
        this.state.selectedLang.map(lan => {
            temArry.push(lan.id)
        })
        this.setState({ lanToSend: [...temArry] })
        //console.log('languagessssss', temArry)
    }
    // convertDateToShow=(dateString)=> {
    //     var date = new Date(dateString);
    //     let formatted= date.getDate()+"-"+(date.getMonth() + 1)+"-"+date.getFullYear();
    //     this.setState({showDOB:formatted})
    // }
    convertDateToStore=()=>{
        let date=this.state.showDate
        let formatted= date.getFullYear()+"-"+(date.getMonth() + 1)+"-"+date.getDate();
        this.setState({date_of_birth:formatted},()=>{
            this.setAge()
        })
        
        //let date=this.state.showDOB.split('-')
       // console.log('date arry', date[0],date[1],date[2])
        //this.setState({date_of_birth:`${date[2]}-${date[1]}-${date[0]}`},()=>this.setAge())
    }
    componentDidMount() {
        this.setSelectedLang(this.state.languages)
        this.formateSelectedLocation(this.state.location)
        //this.convertDateToShow(this.state.date_of_birth)
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        axios.get(`${BASE_URL}/instructor-availability`)
            .then((response) => {
                this.setLocationOptions(response.data.result)
            })
            .catch((err) => {
                //console.log('error of location api', err) 
            })
    }
    handleEditClose = () => {
        this.props.EditProfileStatus(false)
    }
    updateProfile = () => {

    }
    onInputDateChange = (field, e) => {
        this.setState({ [field]: e}, () => {
           // console.log('2nd function')
            this.convertDateToStore()
            // this.setAge()
        })

    }
    onInputChange = (field, e) => {
        this.setState({ [field]: e.target.value },()=>console.log(this.state))

    }
    setAge = () => {
        //console.log('set age funtion')
            this.calculateAge(this.state.date_of_birth)
    }
    ageCount(birthDate, ageAtDate) {
        let daysInMonth = 30.436875; // Days in a month on average.
        let dob = new Date(birthDate);
        let aad;
        if (!ageAtDate) aad = new Date();
        else aad = new Date(ageAtDate);
        let yearAad = aad.getFullYear();
        let yearDob = dob.getFullYear();
        let years = yearAad - yearDob; // Get age in years.
        dob.setFullYear(yearAad); // Set birthday for this year.
        let aadMillis = aad.getTime();
        let dobMillis = dob.getTime();
        if (aadMillis < dobMillis) {
            --years;
            dob.setFullYear(yearAad - 1); // Set to previous year's birthday
            dobMillis = dob.getTime();
        }
        let days = (aadMillis - dobMillis) / 86400000;
        let monthsDec = days / daysInMonth; // Months with remainder.
        let months = Math.floor(monthsDec); // Remove fraction from month.
        days = Math.floor(daysInMonth * (monthsDec - months));
       //console.log('dayyyyssss',days)
        return { years: years, months: months };
    }
    calculateAge(date) {
       //console.log('dateeeeee',date)
        let { months, years } = this.ageCount(date, Date.now());
      //console.log('yearrrrrr', years)
        this.setState({ age: years })
        if (years > 18) {
            this.setState({ ageErr: '' })
            return true

        } else {
            this.setState({ ageErr: 'Age must be above 18' })
            return false
        }

    }
    EditFormValidation = () => {
        const validEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const validName = /^[a-zA-Z]+(?:[\s]+[a-zA-Z]+)*$/;
        const validLicense = /^[a-zA-Z0-9]{6}$/;
        const validDate=/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
        const valid2DigitDecimal=/^[0-9]{2}\.[0-9]{2}$/;
        const valid2Digit=/^[0-9]{1,2}$/;
        let nameErr = false
        let emailErr = false
        let dobErr = false
        let aboutErr = false
        let addressErr = false
        let companyErr = false
        let dlicenseErr = false
        let hobbyErr = false
        let teachErr = false
        let licensePlateErr = false
        let priceErr = false
        let HourErr = false
        let minErr = false
        let locErr= false
        let langErr=false

        if (!this.state.name || !validName.test(this.state.name)) {
            nameErr = true
        }
        if(this.state.locToSend.length<=0){
            locErr=true
        }
        if(this.state.lanToSend.length<=0){
            langErr=true
        }

        if (!this.state.email || !validEmail.test(this.state.email)) {
            emailErr = true
        }

        if (!this.state.date_of_birth || !this.calculateAge(this.state.date_of_birth) || !validDate.test(this.state.date_of_birth)) {
            dobErr = true
        }

        if (!this.state.about) {
            aboutErr = true
        }

        if (!this.state.address) {
            addressErr = true
        }

        if (!this.state.company_name) {
            companyErr = true
        }

        if (!this.state.driving_license || !validLicense.test(this.state.driving_license)) {
            dlicenseErr = true
        }

        if (!this.state.hobbies_interests) {
            hobbyErr = true
        }

        if (!this.state.how_do_you_teach) {
            teachErr = true
        }
        if (!this.state.licenseplate || !validLicense.test(this.state.licenseplate)) {
            licensePlateErr = true
        }

        if (!this.state.price_per_hour || !valid2DigitDecimal.test(this.state.price_per_hour)) {
            priceErr = true
        }

        if (!this.state.slotHour|| this.state.slotHour>2 || !valid2Digit.test(this.state.slotHour)) {
            HourErr = true
        }

        if (!this.state.slotMinute || !valid2Digit.test(this.state.slotMinute) || (this.state.slotHour==2&&this.state.slotMinute>0) || (this.state.slotHour==0&&this.state.slotMinute<=29)) {
            minErr = true
        }

        if (nameErr ||
            emailErr ||
            dobErr ||
            companyErr ||
            aboutErr ||
            addressErr ||
            dlicenseErr ||
            hobbyErr ||
            teachErr ||
            licensePlateErr ||
            priceErr ||
            HourErr ||
            minErr || locErr||langErr) {
            this.setState({locErr,langErr, nameErr, emailErr, dobErr, companyErr, aboutErr, addressErr, dlicenseErr, hobbyErr, teachErr, licensePlateErr, priceErr, HourErr, minErr })
            return false
        }
        this.setState({locErr,langErr, nameErr, emailErr, dobErr, companyErr, aboutErr, addressErr, dlicenseErr, hobbyErr, teachErr, licensePlateErr, priceErr, HourErr, minErr })
        return true


    }
    formateSlotDuration = (hr, min) => {
        let hourFormate = hr
        let minFormate = min
        if (hr <= 9 && !hr[1]) {
            hourFormate = `0${hr}`
        }
        if (min <= 9 && !min[1]) {
            minFormate = `0${min}`
        }
        let slot = `${hourFormate}${minFormate}00`
        return slot;
    }
    setProfileData = (data) => {
        let token = localStorage.getItem('token-click-drive');
        const headers = {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`
        }
        axios.post(`${BASE_URL}/profile`, data, { headers: headers })
            .then((response) => {
                const LanguageApiHeader = {
                    "Content-Type": "application/json; charset=utf-8",
                    'Authorization': `Bearer ${token}`
                }
                const payload = { languages: this.state.lanToSend }
                axios.post(`${BASE_URL}/instructor-languages`, JSON.stringify(payload), { headers: LanguageApiHeader })
                    .then((res) => {
                        this.props.EditProfileStatus(false)
                    })
                    .catch(err => {
                        // console.log('err in language api', err)
                    })
                
            })
            .catch((err) => {
               // console.log('error of update api', err.message , err, err.response) 
            })
    }
    handleEditFormSubmit = (e) => {
        e.preventDefault()
        let isValid = this.EditFormValidation();
        
        if (isValid) {
           // console.log('form valid', this.state)
            let slot = this.formateSlotDuration(this.state.slotHour, this.state.slotMinute)
            let brand, make_and_model, color, date_of_registration,total_co2;
            let fuel_type=null
            let updateProfile = new FormData();
            updateProfile.append("name", this.state.name);
            updateProfile.append("about", this.state.about);
            updateProfile.append("address", this.state.address);
            updateProfile.append("company_name", this.state.company_name);
            updateProfile.append("date_of_birth", `${this.state.date_of_birth} 00:00:00`);
            updateProfile.append("age", this.state.age);
            updateProfile.append("driving_license", this.state.driving_license);
            if(this.state.oldEmail!==this.state.email){
                updateProfile.append("email",this.state.email)
            }
            updateProfile.append("gender", this.state.gender);
            updateProfile.append("hobbies_interests", this.state.hobbies_interests);
            updateProfile.append("how_do_you_teach", this.state.how_do_you_teach);
            updateProfile.append("licenseplate", this.state.licenseplate);
            updateProfile.append("price_per_hour", Math.round(this.state.price_per_hour)+'.00');
            updateProfile.append("profile_picture", this.state.fileToUpload);
            updateProfile.append("slot_duration", slot);
            updateProfile.append("location", this.state.locToSend);
            // console.log('form dataaa', updateProfile)
            EditProfileApi.GetLicenseDetailsPart1(this.state.licenseplate)
            .then(res=>{
              // console.log('part1 res', res)
                res.map(obj=>{
                    if(obj.hasOwnProperty('merk')){
                      brand=obj['merk'];
                    }
                    if(obj.hasOwnProperty('handelsbenaming')){
                      make_and_model=obj['handelsbenaming'];
                    }
                    if(obj.hasOwnProperty('eerste_kleur')){
                      color=obj['eerste_kleur'];
                    }
                    if(obj.hasOwnProperty('datum_eerste_afgifte_nederland')){
                      date_of_registration=obj['datum_eerste_afgifte_nederland'];
                    }
                  })
                  updateProfile.append("merk", brand ? brand : "");
                //updateProfile.append("merk","");
                  updateProfile.append("handelsbenaming", make_and_model?make_and_model:"");
                  updateProfile.append("eerste_kleur", color?color:"");
                  updateProfile.append("datum_tenaamstelling", date_of_registration?date_of_registration:"");

                  EditProfileApi.GetLicenseDetailsPart2(this.state.licenseplate)
                  .then(res=>{
                   //('api2 success', res)
                    res.map((obj)=>{
                      if(obj.hasOwnProperty('brandstof_omschrijving')){
                         // console.log('worked')
                        fuel_type=obj['brandstof_omschrijving'];
                      }
                      if(obj.hasOwnProperty('co2_uitstoot_gecombineerd')){
                        total_co2=obj['co2_uitstoot_gecombineerd'];
                      }
                    })
   
                    updateProfile.append("brandstof_omschrijving", fuel_type?fuel_type:"");
                    updateProfile.append("co2", total_co2?Number(total_co2):251)
                    this.setProfileData(updateProfile)
                })
                .catch(err=>{
                  // console.log('api2 err', err)
                    this.setProfileData(updateProfile)
                })
            })
            .catch(err=>{
               // console.log('part1 err', err)
                this.setProfileData(updateProfile)
            })
           // this.setProfileData(updateProfile)
        }else{
           // console.log('form not valid', this.state)
        }
    }
    handleChange(e) {
        e.preventDefault()
        //console.log('button click', e.target.files)

        if (e.target.files[0]) {
            const file=e.target.files[0];
            var pattern = /image-*/;
      
            if (!file.type.match(pattern)) {
                toast.error('Invalid File Selection', {
                    containerId: 'EditProfile',
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
              return;
            }
            this.setState({
                file: URL.createObjectURL(e.target.files[0]),
                fileToUpload :e.target.files[0]
            })
            // here you can do whatever you want with your image. Now you are sure that it is an image
          }

        // this.setState({
        //     file: URL.createObjectURL(e.target.files[0]),
        //     fileToUpload :e.target.files[0]
        // })
    }
    // dateInput=({onChange, placeholder, value, isSecure, id, onClick})=>(
    //     <input
    //     onChange={onChange}
    //     placeholder={placeholder}
    //     value={value}
    //     isSecure={isSecure}
    //     id={id}
    //     onClick={onClick}
    // />
    // )
    render() {
        //console.log(this.props.profileData)
        return (
            <Modal style={{ zIndex: 9999 }} show={this.props.open} onHide={this.handleEditClose} size="lg">
                <Modal.Body>
                    <div>
                        <div>
                            <Form noValidate className="" onSubmit={this.handleEditFormSubmit}>

                                <Form.Row>
                                    <Col xs={4}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            margin: '5px 0px'
                                        }}>
                                            <span style={{
                                                position: 'relative',
                                            }}>
                                                <img style={{
                                                    borderRadius: '50%',
                                                    objectFit: 'cover'
                                                }}
                                                    src={this.state.file ? this.state.file : 'https://cdn.pixabay.com/photo/2018/04/18/18/56/user-3331257__340.png'}
                                                    width='130px'
                                                    height='130px'
                                                    alt='no image' />
                                             

                                                <input name="uploadImage" accept="image/*" type="file" id="uploadImage" onChange={this.handleChange.bind(this)}
                                                    style={{ backgroundColor: 'red', opacity: 0.1, display: "none" }}
                                                />
                                                <label
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                    backgroundColor: "transparent",
                                                    outline: 'none',
                                                    borderColor: 'none',
                                                    border: 0
                                                }}
                                                 htmlFor="uploadImage">
                                                    <img src={pencil} width='30px' height='30px' />
                                                </label>

                                                {/* <div>
                                                <input type="file" onChange={this.handleChange}/>
                                                <img src={this.state.file}/> 
                                            </div> */}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <Form.Row>
                                            <Col xs={12}>
                                                <Form.Group controlId="name">
                                                    <Form.Label>{getText('Name', 'Naam', this.props.isEnglish)}</Form.Label>
                                                    <Form.Control required isInvalid={this.state.nameErr} type="text"
                                                        //placeholder=""
                                                        value={this.state.name}
                                                        onChange={(e) => {
                                                            this.onInputChange("name", e);
                                                        }} />
                                                    <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xm={6}>
                                                <Form.Group controlId="dob">
                                                    <Form.Label>{getText('Date of Birth', 'Geboortedatum', this.props.isEnglish)}</Form.Label>
                                                    {/* <Form.Control required isInvalid={this.state.dobErr} type="text"
                                                        //placeholder="Enter DOB"
                                                        value={this.state.showDOB}
                                                        onChange={(e) => {
                                                            this.onInputDateChange("showDOB", e);
                                                        }} /> */}
                                                        <DatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        customInput={
                                                            <Form.Control required isInvalid={this.state.dobErr} type="text"
                                                             /> 
                                                        }
                                                        style={{display:'block !important'}}
                                                        selected={this.state.showDate}
                                                        onChange={(e) => {
                                                           this.onInputDateChange("showDate", e);
                                                           //this.setState({showDate:e},()=>console.log('state', this.state))
                                                        }}
                                                        />
                                                    <Form.Control.Feedback type="invalid" >{this.state.ageErr}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            {/* <Col xm={6}>
                                            <Form.Group controlId="dob">
                                                <Form.Label>Date of Birth</Form.Label>
                                                <Form.Control type="number" 
                                                          
                                                            value={this.state.age}
                                                            onChange={(e) => {
                                                                this.onInputChange("date_of_birth", e);
                                                              }} />
                                                      <Form.Control.Feedback type="invalid" >{this.state.ageErr}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col> */}
                                            <Col xm={6}>
                                                <Form.Group controlId="gender">
                                                    <Form.Label>{getText('Gender', 'Geslacht', this.props.isEnglish)}</Form.Label>
                                                    <Form.Control as="select" value={this.state.gender}
                                                        onChange={(e) => {
                                                            this.onInputChange("gender", e);
                                                        }}>
                                                        <option value='Male'>{getText('Male', 'Mannetje', this.props.isEnglish)}</option>
                                                        <option value='Female'>{getText('Female', 'Vrouw', this.props.isEnglish)}</option>
                                                        <option value='Other'>{getText('Other', 'Andere', this.props.isEnglish)}</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col xs={12}>
                                        <Form.Group controlId="email">
                                            <Form.Label>{getText('Email', 'E-mail', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.emailErr} type="email"
                                              //  placeholder=""
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.onInputChange("email", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Form.Row>

                                <Form.Row>
                                    <Col xs={6}>
                                        <Form.Group controlId="address">
                                            <Form.Label>{getText('Address', 'Adres', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.addressErr} type="text"
                                                // style={{height:'80px'}}
                                                //placeholder=""
                                                value={this.state.address}
                                                onChange={(e) => {
                                                    this.onInputChange("address", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group controlId="cname">
                                            <Form.Label>{getText('Company Name', 'Bedrijfsnaam', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.companyErr} type="text"
                                                //placeholder="Enter Company"
                                                value={this.state.company_name}
                                                onChange={(e) => {
                                                    this.onInputChange("company_name", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col md={2} sm={12}>
                                        <Form.Group >
                                            <Form.Label>{getText('Location', 'Plaats', this.props.isEnglish)}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={10} sm={12}>
                                        <Form.Group >
                                            <Multiselect
                                              style={{searchBox: { border: `1px solid ${this.state.locErr ?`#c4183c`:`#cccccc`}`,
                                              boxShadow:this.state.locErr? `0 5px 11.5px rgba(196,24,60,.1)`:``, } }}
                                                options={this.state.locationOptions} // Options to display in the dropdown
                                                selectedValues={this.state.locationSelectedValue} // Preselected value to persist in dropdown
                                                onSelect={this.onLocationSelect} // Function will trigger on select event
                                                onRemove={this.onLocationRemove} // Function will trigger on remove event
                                                groupBy="cat"
                                                displayValue="key"
                                                showCheckbox={true} // Property name to display in the dropdown options
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col md={4} xs={5}>
                                        <Form.Group controlId="driving">
                                            <Form.Label>{getText('Driving School No.', 'Rijschoolnummer', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.dlicenseErr} type="text"
                                              //  placeholder="Enter Driving License"
                                                value={this.state.driving_license}
                                                onChange={(e) => {
                                                    this.onInputChange("driving_license", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <Form.Group controlId="lesson">
                                            <Form.Label>{getText('Price per Lesson', 'Prijs per les', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.priceErr} type="number"
                                                //placeholder="Enter price/lesson"
                                                value={this.state.price_per_hour}
                                                onChange={(e) => {
                                                    this.onInputChange("price_per_hour", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} xs={3}>
                                        <Form.Group controlId="licenseplate">
                                            <Form.Label>{getText('License Plate', 'Nummerplaat', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.licensePlateErr} type="text"
                                               // placeholder="Enter License plate"
                                                value={this.state.licenseplate}
                                                onChange={(e) => {
                                                    this.onInputChange("licenseplate", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col md={2} sm={12}>
                                        <Form.Group >
                                            <Form.Label>{getText('Languages Spoken', 'Talen gesproken', this.props.isEnglish)}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={10} sm={12}>
                                        <Form.Group >
                                            <Multiselect
                                                style={{searchBox: { border: `1px solid ${this.state.LangErr?`#FF0000`:`#cccccc`}` } }}
                                                options={this.state.languages} // Options to display in the dropdown
                                                selectedValues={this.state.selectedLang} // Preselected value to persist in dropdown
                                                onSelect={this.onLanguageSelect} // Function will trigger on select event
                                                onRemove={this.onLanguageRemove} // Function will trigger on remove event
                                                //groupBy="cat"
                                                displayValue="key"
                                                showCheckbox={true} // Property name to display in the dropdown options
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col xs={6}>
                                        <Form.Group controlId="about">
                                            <Form.Label>{getText('About You', 'Over jou', this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.aboutErr} as="textarea"
                                                rows={2}
                                                value={this.state.about}
                                                onChange={(e) => {
                                                    this.onInputChange("about", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group controlId="hobbies">
                                            <Form.Label>{getText('Hobbies And Interests', "Hobby's en interesses", this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.hobbyErr} as="textarea"
                                                rows={2}
                                                value={this.state.hobbies_interests}
                                                onChange={(e) => {
                                                    this.onInputChange("hobbies_interests", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col xs={6}>
                                        <Form.Group controlId="teach">
                                            <Form.Label>{getText('How Do You Teach', "Hoe geef je les?", this.props.isEnglish)}</Form.Label>
                                            <Form.Control required isInvalid={this.state.teachErr} as="textarea"
                                                rows={2}
                                                value={this.state.how_do_you_teach}
                                                onChange={(e) => {
                                                    this.onInputChange("how_do_you_teach", e);
                                                }} />
                                            <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>{getText('Slot Duration', "Slotduur", this.props.isEnglish)}</Form.Label>
                                            <Form.Row>
                                                <Col>
                                                    <Form.Control required isInvalid={this.state.HourErr} type="number" value={this.state.slotHour} onChange={(e) => this.onInputChange("slotHour", e)} />
                                                    <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                                </Col>:
                                            <Col>
                                                    <Form.Control required isInvalid={this.state.minErr} type="number" value={this.state.slotMinute} onChange={(e) => this.onInputChange("slotMinute", e)} />
                                                    <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                                                </Col>
                                            </Form.Row>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>



                                <Button variant="secondary" style={{ marginRight: 2 }} onClick={this.handleEditClose}>
                                {getText('Close', 'Dichtbij', this.props.isEnglish)}
                            </Button>
                                <Button variant="primary" type='submit' >
                                {getText('Save Changes', 'Wijzigingen opslaan', this.props.isEnglish)}
                            </Button>
                            </Form>
                            <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            enableMultiContainer 
                            containerId={'EditProfile'}
                            hideProgressBar={false}
                            newestOnTop={true}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            />
                        </div>
                    </div>
                </Modal.Body>
                
            </Modal>);
    }
}
const mapStateToProp = (state) => {
    return {
        profileData: state.EditProfileReducer.profileData,
        open: state.EditProfileReducer.editStatus,
        isEnglish: state.authReducer.isEnglish,
    }
}
export default connect(mapStateToProp, { EditProfileStatus, getProfileData })(EditProfileModal);