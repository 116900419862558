import axios from 'axios';
import { BASE_URL } from '../../config';

export default class AdminStudentMiddleware {
    constructor() {
    };

    static getAdminStudentData = async () => {
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        return await axios.get(`${BASE_URL}/admin/student`);
    };

    static getAllStudents = async () => {
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        return await axios.get(`${BASE_URL}/admin/management/get-student`);
    };
    static getStudentData = async (instaName = null) => {
        let token = localStorage.getItem('token-click-drive');
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        return await axios.post(`${BASE_URL}/admin/student/view-lessons-and-progress-individual`, { name: instaName });
    };

}